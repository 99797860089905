import axios from 'axios';

const getAllChildren = async (token) => {
  try {
    const getChildrensRes = await axios.post('/childrens/index', null, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token
      }
    });
    return getChildrensRes;
  } catch (error) {}
};

export default getAllChildren;

import RoomsTable from "./RoomsTable"
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from "antd";
import { PlusOutlined } from '@ant-design/icons';

const RoomsPage = ()=>{

    let history = useHistory();

    return(
        <>

             <Button 
                onClick={() => {
                  history.push("/rooms/create")
                }}
                className="add-serv-btn"
                type="primary"
                icon={<PlusOutlined />}>
                <span className="btn-text">اضافة غرفة</span>
              </Button>

         <RoomsTable  />
        </>
       
    )
}
export default RoomsPage
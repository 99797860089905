import { EditOutlined, SendOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button, Form } from 'antd';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { useEffect, useState } from 'react';
import { Store } from 'react-notifications-component';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import getAllCountarys from '../../apis/countary-api/getAllcountaries';
import CreateParentApi from '../../apis/parents-api/createParentApi';
import DatePickerBox from '../../common/DatePickerBox/DatePickerBox';
import SelectSearchBox from '../../common/SelectSearchBox/SelectSearchBox';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import UploadQuestionFile from '../../common/file-input/UploadQuestionFile';
import UesrContext from '../../contexts/user-context/UserProvider';
import GoogleMaps from '../GoogleMaps/GoogleMaps';
import getTargetParentApi from '../../apis/parents-api/getTargetParentApi';
const defaultValues = {
  phone_code: '',
  image: null,
  date_of_birth: null,
  email: '',
  gender: '',
  address: '',
  nationality: '',
  phone: '',
  // parent: "",
  national_id: '',
  name: '',
  password: '',
  // is_disease: ""
  map_loc: null
};
const UpdateParentPage = () => {
  const { user } = useContext(UesrContext);
  const [dataParentId, setDataParentId] = useState([]);
  const [ParentTargetData, setParentTargetData] = useState(null);
  const [allCountarys, setAllCountarys] = useState(null);
  const [phoneCodeError, setPhoneCodeError] = useState('');
  const [nationalityOptions, setNationalityOptions] = useState([]);
  const [phoneCodeOptions, setPhoneCodeOptions] = useState([]);
  // console.log(ParentTargetData)

  let param = useParams();
  // console.log(user);
  let history = useHistory();
  let schema = Yup.object().shape({
    phone_code: Yup.string().required(' من فضلك أدخل الوصف مطلوب'),
    image: Yup.mixed().required('من فضلك أدخل المطلوب'),
    date_of_birth: Yup.mixed().required('اختارالتاريخ '),
    email: Yup.string().required('البريد الألكتروني').email('من فضلك ادخل بريد الكترونى صحيح'),
    gender: Yup.string().required('من فضلك أدخل المطلوب'),
    name: Yup.string().required('من فضلك أدخل المطلوب'),
    national_id: Yup.string().min(10, 'least 10 characters').required('من فضلك أدخل المطلوب'),
    address: Yup.string().optional('من فضلك أدخل المطلوب'),
    nationality: Yup.string().required('من فضلك أدخل المطلوب'),
    phone: Yup.string().min(10, 'least 10 characters').required(' من فضلك أدخل الموضوع مطلوب'),
    map_loc: Yup.mixed().required(' من فضلك أدخل الوصف مطلوب'),
    password: Yup.string()
      .required('يجب ادخال الرقم السري')
      .test('len', 'الرقم السري يجب ألا يقل عن 8 أرقام', (val) => val?.length >= 8)

    // phone: Yup.string().optional('من فضلك أدخل المطلوب'),
    // parent: Yup.string().required('من فضلك أدخل المطلوب'),
    // is_disease: Yup.string().required('من فضلك أدخل المطلوب'),
  });

  const {
    control,
    // getValues,
    setValue,
    // setError,
    // clearErrors,
    handleSubmit,
    register,
    watch,
    unregister,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });
  const [urls, setUrls] = useState([]);
  // console.log(watch())

  useEffect(() => {
    const getCountarys = async () => {
      let nationalityList = [];
      let phoneCodeList = [];

      try {
        const getCountarysRes = await getAllCountarys(user?.token);

        if (getCountarysRes?.status === 200 && getCountarysRes?.data) {
          setAllCountarys(getCountarysRes?.data?.countries);
          getCountarysRes?.data?.countries.map((country) => {
            nationalityList.push({ name: country?.nationality, id: country?.nationality });
            phoneCodeList.push({ name: country?.code, id: country?.code });
          });

          setNationalityOptions(nationalityList);
          setPhoneCodeOptions(phoneCodeList);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getCountarys();
  }, []);

  const onSubmit = async (data) => {
    // console.log(data)

    try {
      let res;
      res = await CreateParentApi(`/parents/update/${param?.id}`, { ...data }, user?.token);
      // console.log(res)

      // console.log('create Selles response: ', res);
      if (res?.status !== 200) {
        Store.addNotification({
          title: 'حدث خطء اثناء التعديل',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (res?.status === 200 && res?.data?.status_code === 200) {
        reset({ ...defaultValues });
        Store.addNotification({
          title: 'تمت العملية بنجاح',
          message: 'تم التعديل ',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
        history.push('/parents');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [form] = Form.useForm();

  useEffect(() => {
    if (ParentTargetData !== null) {
      setValue('name', ParentTargetData?.name);
      setValue('address', ParentTargetData?.address);
      setValue('image', ParentTargetData?.image_url);
      setValue('email', ParentTargetData?.email);
      setValue('phone', ParentTargetData?.phone);
      setValue('date_of_birth', ParentTargetData?.date_of_birth);
      setValue('gender', ParentTargetData?.gender);
      setValue('nationality', ParentTargetData?.nationality);
      setValue('phone_code', String(ParentTargetData?.phone_code));
      setValue('national_id', ParentTargetData?.national_id);
      setValue('map_loc', { lat: ParentTargetData?.lat, long: ParentTargetData?.long });
    }
  }, [ParentTargetData]);
  // let changeTargetValues = (el, vl) => {
  //   setValue(el, vl);

  // }
  // console.log(errors)

  useEffect(() => {
    const handleGetTargetParent = async () => {
      try {
        const getTargetParentRes = await getTargetParentApi(param?.id, user?.token);

        if (getTargetParentRes.status === 200 && getTargetParentRes?.data?.parent_details) {
          setParentTargetData(getTargetParentRes?.data?.parent_details);
        }
      } catch (err) {
        setParentTargetData(null);
      }
    };
    handleGetTargetParent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param?.id]);
  return (
    <Form
      className="add-Selles-form flex justify-center items-center"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}>
      <div className="form-body my-[40px] w-full shadow-[rgba(100,_100,_111,_0.2)_0px_7px_29px_0px] max-w-[800px] py-[40px] px-[30px]">
        <div className="form-field-wrapper">
          <AntdTextField
            name="name"
            type="text"
            placeholder="الاسم..."
            errorMsg={errors?.name?.message}
            validateStatus={errors?.name ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="national_id"
            type="text"
            placeholder="الهوية الوطنية..."
            errorMsg={errors?.national_id?.message}
            validateStatus={errors?.national_id ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        {/* <div className="form-field-wrapper">
          <AntdTextField
            name="nationality"
            type="text"
            placeholder="الجنسية..."
            errorMsg={errors?.nationality?.message}
            validateStatus={errors?.nationality ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div> */}

        <div className=" form-field-wrapper">
          <SelectSearchBox
            name="nationality"
            errorMsg={errors?.nationality?.message}
            validateStatus={errors?.nationality ? 'error' : ''}
            defaultValue=""
            control={control}
            setValue=""
            dataSearch={nationalityOptions}
            placeholder="الجنسية..."
            label={
              <p className="select-option-label">
                <EditOutlined />
                الجنسية
              </p>
            }
            value={watch}
          />
        </div>

        <div className="form-field-wrapper">
          <AntdTextField
            name="address"
            type="text"
            placeholder="العنوان..."
            errorMsg={errors?.address?.message}
            validateStatus={errors?.address ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>

        <div className="form-field-wrapper">
          <AntdTextField
            name="email"
            type="text"
            placeholder="البريد الألكتروني..."
            errorMsg={errors?.email?.message}
            validateStatus={errors?.email ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        {/*   <div className="form-field-wrapper">
          <AntdTextField
            name="phone_code"
            type="text"
            placeholder="رمز الهاتف..."
            errorMsg={errors?.phone_code?.message}
            validateStatus={errors?.phone_code ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div> */}

        <div className=" form-field-wrapper">
          <SelectSearchBox
            name="phone_code"
            errorMsg={errors?.phone_code?.message}
            validateStatus={errors?.phone_code ? 'error' : ''}
            defaultValue=""
            control={control}
            setValue=""
            dataSearch={phoneCodeOptions}
            placeholder="رمز الهاتف..."
            label={
              <p className="select-option-label">
                <EditOutlined />
                رمز الهاتف
              </p>
            }
            value={watch}
          />
        </div>

        <div className="form-field-wrapper">
          <AntdTextField
            name="phone"
            type="text"
            placeholder="الهاتف..."
            errorMsg={errors?.phone?.message}
            validateStatus={errors?.phone ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        {/* {!selectedTechnicalSupport?.client?.id && (
          <AntdSelectOption
            name="client_id"
            errorMsg={errors?.client_id?.message}
            validateStatus={errors?.client_id ? 'error' : ''}
            control={control}
            setValue={setValue}
            disabled={selectedTechnicalSupport?.client?.id && true}
            options={
              customerSelectData?.length &&
              customerSelectData?.map((el) => ({
                title: el?.name,
                value: el?.ref_id
              }))
            }
            label={
              <p className="select-option-label">
                <EditOutlined />
                العميل
              </p>
            }
            formClassName="add-Selles-form"
          />
        )} */}
        <AntdSelectOption
          name="gender"
          errorMsg={errors?.gender?.message}
          validateStatus={errors?.gender ? 'error' : ''}
          control={control}
          setValue={setValue}
          options={[
            { title: 'male', value: 'male' },
            { title: 'female', value: 'female' }
          ]}
          label={
            <p className="select-option-label">
              <EditOutlined />
              النوع
            </p>
          }
          formClassName="add-Selles-form"
        />
        {/* <div className=" form-field-wrapper py-[10px]">
          <SelectSearchBox
            name="parent"
            errorMsg={errors?.parent?.message}
            validateStatus={errors?.parent ? 'error' : ''}
            defaultValue=""
            placeholder="اختر الوالد..."

            control={control}
            setValue={setValue}
            dataSearch={dataParentId}
            value={watch}
            label={
              <p className="select-option-label">
                <EditOutlined />
                اختر الوالد
              </p>
            }

          />
        </div> */}
        {/* <AntdSelectOption
          name="is_disease"
          errorMsg={errors?.is_disease?.message}
          validateStatus={errors?.is_disease ? 'error' : ''}
          control={control}
          setValue={setValue}
          options={[
            { title: 'نعم', value: '1' },
            { title: 'لا', value: '0' },
          ]}
          label={
            <p className="select-option-label">
              <EditOutlined />
              هل يوجد مرض
            </p>
          }
          formClassName="add-Selles-form"
        /> */}
        <div className=" form-field-wrapper py-[10px]">
          <DatePickerBox
            name="date_of_birth"
            errorMsg={errors?.date_of_birth?.message}
            validateStatus={errors?.date_of_birth ? 'error' : ''}
            defaultValue=""
            control={control}
            setValue={setValue}
            watch={watch}
            label={
              <p className="select-option-label">
                <EditOutlined />
                تاريخ الميلاد
              </p>
            }
          />
        </div>

        <div className="form-field-wrapper">
          <AntdTextField
            name="password"
            type="password"
            placeholder="الرقم السري..."
            label="الرقم السري : "
            errorMsg={errors?.password?.message}
            validateStatus={errors?.password ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>

        <GoogleMaps
          type={'update'}
          watch={watch}
          setValue={setValue}
          letTarget={ParentTargetData?.lat}
          lngTarget={ParentTargetData?.long}
          name={'map_loc'}
          errorMsg={errors?.map_loc ? errors?.map_loc?.message : ''}
        />

        <div className="form-field-wrapper">
          <UploadQuestionFile
            // accept="image/png, image/jpg, image/jpeg, image/gif"
            maxFiles={1}
            name="image"
            setValue={setValue}
            watch={watch}
            imageTarget={Array.isArray(watch('image')) ? null : watch('image')}
            register={register}
            unregister={unregister}
            multiple={false}
            label={
              <>
                <EditOutlined />
                الصوره
              </>
            }
            urls={urls}
            errorMsg={errors?.image ? errors?.image?.message : ''}
          />
        </div>

        {/* <div className="form-field-wrapper">
          {!selectedServId && (
            <AntdTextField
              name="image"
              type="file"
              placeholder="موضوع..."
              errorMsg={errors?.image?.message}
              validateStatus={errors?.image ? 'error' : ''}
              prefix={<EditOutlined />}
              control={control}
            />
          )}
        </div> */}

        <Button
          className="submit-btn mx-auto mt-[30px]  !h-[45px] !w-[100px]   !flex justify-center items-center"
          htmlType="submit"
          type="primary"
          icon={<SendOutlined />}
          loading={isSubmitting}>
          تعديل
        </Button>
      </div>
    </Form>
  );
};

export default UpdateParentPage;

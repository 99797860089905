import axios from 'axios';

const getAllSitters = async (token) => {
  try {
    const getSittersRes = await axios.post(
      '/setters/index',
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'applications/json',
          Authorization: 'Bearer ' + token
        }
      }
    );

    return getSittersRes;
  } catch (error) {
    return error?.response;
  }
};

export default getAllSitters;

import React from 'react';
import ProfileForm from './ProfileForm';
import './ProfilePage.scss';

function ProfilePage() {
  return (
    <div className="profile-page w-full max-w-[100%]">
      <ProfileForm />
    </div>
  );
}

export default ProfilePage;

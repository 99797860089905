import { Table } from 'antd';
import { Pagination } from 'react-laravel-paginex';
import React, { useContext, useEffect, useState } from 'react';
import getAllUsers from '../../apis/users-api/getAllUsers';
import UesrContext from '../../contexts/user-context/UserProvider';
import FacilityColumns from './facilityColumns';
import FacilityContext from '../../contexts/facility_context/FacilityProvider';
import getAllFacilities from '../../apis/facility_api/getAllFacilitiesApi';

const FacilityTable = ({ filterValue }) => {
  const [allUsers, setAllUsers] = useState();
  const { user } = useContext(UesrContext);
const {
  facilityFetchCount,
  setFacilityFetchCount,
  loadingFacility,
  setLoadingFacility,
  allFacility,
  setAllFacility,
  facilityDetails,
  setFacilityDetails,
  targetFacility,
  setTargetFacility
} =  useContext(FacilityContext)

  useEffect(() => {

    const getFacilities = async () => {
        try {
          setLoadingFacility(true)
          const getFacilitiesRes = await getAllFacilities(user?.token);

          if (getFacilitiesRes?.status === 200) {
            setLoadingFacility(false)
            setAllFacility(getFacilitiesRes?.data?.facilities);
                console.log(getFacilitiesRes?.data?.facilities);
            }
            
        } catch (error) {
          console.log('error', error);
        }
      }
   

      getFacilities();

  }, [facilityFetchCount]);

  return (
    <div>
      <Table
        pagination={{pageSize:5}}
        className="Orders-table"
        dataSource={allFacility}
        loading={loadingFacility}
        columns={FacilityColumns(
          user,
          facilityFetchCount,
          setFacilityFetchCount,
          loadingFacility,
          setLoadingFacility,
          allFacility,
          setAllFacility,
          facilityDetails,
          setFacilityDetails,
          targetFacility,
          setTargetFacility
        )}
        scroll={{ x: 1200 }}
      />
    </div>
  );
};

export default FacilityTable;

import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => {},
  loadingAreas: false,
  setLoadingAreas: (load) => {},
  allAreas: [],
  setAllAreas: (Areas) => {},
  AreaModalOpened: false,
  setAreaModalOpened: (v) => {},
  selectedAreaId: '',
  setSelectedAreaId: (id) => {},
  selectedArea: null,
  setSelectedArea: (AreaKey) => {}
};

const AreasContext = createContext(INITIAL_VALUES);

export const AreasProvider = ({ children }) => {
  const [allAreas, setAllAreas] = useState(INITIAL_VALUES.allAreas);
  const [loadingAreas, setLoadingAreas] = useState(INITIAL_VALUES.loadingAreas);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [AreaModalOpened, setAreaModalOpened] = useState(INITIAL_VALUES.AreaModalOpened);
  const [selectedAreaId, setSelectedAreaId] = useState(INITIAL_VALUES.selectedAreaId);
  const [selectedArea, setSelectedArea] = useState(INITIAL_VALUES.selectedArea);

  return (
    <AreasContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        loadingAreas,
        setLoadingAreas,
        allAreas,
        setAllAreas,
        AreaModalOpened,
        setAreaModalOpened,
        selectedAreaId,
        setSelectedAreaId,
        selectedArea,
        setSelectedArea
      }}>
      {children}
    </AreasContext.Provider>
  );
};

export default AreasContext;

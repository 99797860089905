import axios from 'axios';

const registerSitterApi = async (values,token) => {
  const { address, admin_name,city_id,country_id,lat,lng,phone,type,username,nursery_name } = values;
  try {
    const formData = new FormData();
    formData.append('address',address );
    formData.append('admin_name',admin_name );
    formData.append('city_id', city_id);
    formData.append('country_id',country_id );
    formData.append('lat',lat );
    formData.append('lng',lng );
    formData.append('phone', phone);
    formData.append('type',type );
    formData.append('username',username );
    nursery_name && formData.append('nursery_name',nursery_name );
    const response = await axios.post(`Sitter/create`, formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    
    if (response.status === 200 && response.data.status === 1) {
      return response;
    }
  } catch (e) {
    
  }
};

export default registerSitterApi;


import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => {},
  loadingSettingsData: false,
  setLoadingSettingsData: (load) => {},
  allSettingsData: [],
  setAllSettingsData: (v) => {},
  SettingsModalOpened: false,
  setSettingsModalOpened: (v) => {},
  selectedSettingsId: '',
  setSelectedSettingsId: (id) => {},
  selectedSettings: null,
  setSelectedSettings: (servKey) => {}
};

const SettingsContext = createContext(INITIAL_VALUES);

export const SettingsProvider = ({ children }) => {
  const [allSettingsData, setAllSettingsData] = useState(INITIAL_VALUES.allSettingsData);
  const [loadingSettingsData, setLoadingSettingsData] = useState(
    INITIAL_VALUES.loadingSettingsData
  );
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [SettingsModalOpened, setSettingsModalOpened] = useState(
    INITIAL_VALUES.SettingsModalOpened
  );
  const [selectedSettingsId, setSelectedSettingsId] = useState(INITIAL_VALUES.selectedSettingsId);
  const [selectedSettings, setSelectedSettings] = useState(INITIAL_VALUES.selectedSettings);

  return (
    <SettingsContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        loadingSettingsData,
        setLoadingSettingsData,
        allSettingsData,
        setAllSettingsData,
        SettingsModalOpened,
        setSettingsModalOpened,
        selectedSettingsId,
        setSelectedSettingsId,
        selectedSettings,
        setSelectedSettings
      }}>
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContext;

import axios from 'axios';

const getAllAreas = async (country_id, token) => {
  try {
    const getAreasRes = await axios.get('/Area/all?city_id=' + country_id, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token
      }
    });
    return getAreasRes;
  } catch (error) {
    
  }
};

export default getAllAreas;

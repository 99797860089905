import { Button, Popconfirm, Popover, Tooltip } from 'antd';
import {
  DeleteOutlined,
  EyeTwoTone,
  EditOutlined,
  InfoOutlined,
  CheckSquareOutlined,
  CloseSquareOutlined
} from '@ant-design/icons';

import { Store } from 'react-notifications-component';
import deleteSitterApi from '../../apis/sitters-api/deleteSitter';
import changeStatusSitterApi from '../../apis/sitters-api/changeStatusSitterApi';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const PopOverAnswers = ({ content, title }) => (
  <Popover title={title} content={content} style={{ maxWidth: '280px' }}>
    <Button size="large" type="dashed" shape="circle" icon={<EyeTwoTone />} />
  </Popover>
);

const SittersColumns = (
  token,
  setFetchCount,
  setLoadingSitters,
  setSitterModalOpened,
  setSelectedServId
) => {
  let history = useHistory();
  const handleEditSitter = (key) => {
    setSitterModalOpened(true);
    setSelectedServId(key);

    //
  };

  const handleChangeStatusRequest = async (id, status) => {
    try {
      setLoadingSitters(true);
      const res = await changeStatusSitterApi(id, status, token);
      if (res.status === 200) {
        setFetchCount((prevState) => prevState + 1);
        setLoadingSitters(false);
        Store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        setLoadingSitters(false);
        Store.addNotification({
          title: 'حدث خطأ ',
          message: res?.data?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      setLoadingSitters(false);
      Store.addNotification({
        title: 'حدث خطأ ',
        message: 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };
  const handleDeleteRequest = async (key) => {
    console.log(key);
    try {
      setLoadingSitters(true);
      const res = await deleteSitterApi(key, token);
      if (res.status === 200) {
        setFetchCount((prevState) => prevState + 1);
        setLoadingSitters(false);
        Store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم الحذف بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        setLoadingSitters(false);
        Store.addNotification({
          title: 'حدث خطأ ',
          message: res?.data?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      setLoadingSitters(false);
      Store.addNotification({
        title: 'حدث خطأ ',
        message: 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };

  return [
    {
      title: '#',
      dataIndex: 'id',
      width: 62
    },
    {
      title: 'الأسم',
      dataIndex: 'name',
      width: 80,
      render: (_, record) => {
        return <div>{record?.user?.name}</div>;
      }
    },
    {
      title: ' رقم الهاتف',
      dataIndex: 'phone',
      width: 80,
      render: (_, record) => {
        return <div>{record?.user?.phone}</div>;
      }
    },
    {
      title: 'الأجر / ساعة',
      dataIndex: 'hour_price',
      width: 80,
      render: (_, record) => {
        return <div>{record?.hour_price}</div>;
      }
    },
    {
      title: 'الجنسية',
      dataIndex: 'nationality',
      width: 50,
      render: (_, record) => {
        return <div>{record?.user?.nationality}</div>;
      }
    },
    {
      title: 'صورة المربيه',
      dataIndex: 'image_url',
      width: 110,
      render: (_, record) => {
        return (
          <div className="w-[100px] h-[50px] mx-auto">
            <img src={record?.user?.image_url} alt="sitter img" className="w-full h-full" />
          </div>
        );
      }
    },
    {
      title: 'تفاصيل المربيه',
      dataIndex: 'edit',
      width: 30,
      render: (_, record) => {
        return (
          <Tooltip title="تفاصيل المربيه ">
            <Button
              className="edit-btn"
              onClick={() => history.push(`/sitters/view/${record.id}`)}
              size="large"
              type="dashed"
              shape="circle"
              icon={<InfoOutlined />}
            />
          </Tooltip>
        );
      }
    },
    {
      title: 'الحالة',
      dataIndex: 'status',
      width: 30,
      render: (_, record) => {
        if (record?.receive_order === 1) {
          return 'مقبوله';
        } else {
          return 'مرفوضه';
        }
      }
    },
    {
      title: 'تغيير الحاله',
      dataIndex: 'status',
      width: 30,
      render: (_, record) => {
        if (record?.receive_order === 1) {
          return (
            <Popconfirm
              title={
                <div style={{ maxWidth: '200px' }}>
                  <p>هل انت متأكد من رفض المربيه</p>
                </div>
              }
              okText="نعم"
              cancelText="لا"
              onConfirm={() => handleChangeStatusRequest(record?.id, 0)}>
              <Tooltip title="رفض المربيه؟">
                <Button
                  className="delete-btn"
                  size="large"
                  type="dashed"
                  shape="circle"
                  icon={<CloseSquareOutlined />}
                />
              </Tooltip>
            </Popconfirm>
          );
        } else {
          return (
            <Popconfirm
              title={
                <div style={{ maxWidth: '200px' }}>
                  <p>هل انت متأكد من قبول المربيه</p>
                </div>
              }
              okText="نعم"
              cancelText="لا"
              onConfirm={() => handleChangeStatusRequest(record?.id, 1)}>
              <Tooltip title="قبول المربيه؟">
                <Button
                  className="delete-btn"
                  size="large"
                  type="dashed"
                  shape="circle"
                  icon={<CheckSquareOutlined />}
                />
              </Tooltip>
            </Popconfirm>
          );
        }
      }
    },
    {
      title: 'تعديل ',
      dataIndex: 'update',
      width: 30,
      render: (_, record) => {
        return (
          <Tooltip title="تعديل بيانات المربية">
            <Button
              className="edit-btn"
              onClick={() => history.push(`/sitters/update/${record?.id}`)}
              size="large"
              type="dashed"
              shape="circle"
              icon={<EditOutlined />}
            />
          </Tooltip>
        );
      }
    },
    {
      title: 'حذف المربيه',
      dataIndex: 'delete',
      width: 30,
      render: (_, record) => {
        return (
          <Popconfirm
            title={
              <div style={{ maxWidth: '200px' }}>
                <p>هل انت متأكد من حذف المربيه</p>
                {/* <p>حذف الخدمة سيؤدى الى حذف جميع المشاريع والاسئلة الخاصة بالخدمة</p> */}
              </div>
            }
            okText="نعم"
            cancelText="لا"
            onConfirm={() => handleDeleteRequest(record.id)}>
            <Tooltip title="حذف المربيه؟">
              <Button
                className="delete-btn"
                size="large"
                type="dashed"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        );
      }
    }
  ];
};

export default SittersColumns;

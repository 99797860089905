import { useContext } from 'react';
import UesrContext from '../../contexts/user-context/UserProvider';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Descriptions, Spin } from 'antd';
import { useParams, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect, useState } from 'react';
import getSitter from '../../apis/sitters-api/getSitterApi';
import getRoom from '../../apis/rooms-api/getRoomApi';
import RoomContext from '../../contexts/room-context/roomsProvider';
import getRate from '../../apis/rates-api/getRateApi';
import RateContext from '../../contexts/rates-context/RatesProvider';
const RateDetails = () => {
  const { user } = useContext(UesrContext);
  const { loadingRate, setLoadingRate, rateDetails, setRateDetails } = useContext(RateContext);
  let history = useHistory();
  let param = useParams();

  useEffect(() => {
    console.log(param.id);
    const getTargetRate = async () => {
      setLoadingRate(true);
      try {
        const targetRateRes = await getRate(user?.token, param?.id);

        if (targetRateRes?.status === 200) {
          setLoadingRate(false);
          setRateDetails(targetRateRes?.data?.rate_details);
        }
      } catch (error) {
        throw error;
      }
    };

    getTargetRate();
  }, []);

  return (
    <>
      {loadingRate ? (
        <div className="mx-auto mt-[100px] w-[100px]">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className="flex flex-row justify-between">
            <h2 className="text-[20px] ">تفاصيل التقييم : </h2>
            <Button
              onClick={() => history.push('/rates')}
              icon={<CloseOutlined />}
              size="small"></Button>
          </div>

          <div className=" lg:w-[70%] mt-[30px]">
            <div>
              <Descriptions bordered column={1}>
                {rateDetails?.user?.name && (
                  <Descriptions.Item label="المربية">{rateDetails?.user?.name}</Descriptions.Item>
                )}
                {rateDetails?.num_of_stars && (
                  <Descriptions.Item label="عدد النجوم">
                    {rateDetails?.num_of_stars}
                  </Descriptions.Item>
                )}
                {rateDetails?.review && (
                  <Descriptions.Item label="التقييم">{rateDetails?.review}</Descriptions.Item>
                )}
                {rateDetails?.created_at && (
                  <Descriptions.Item label="تاريخ التقييم">
                    {rateDetails?.created_at.slice(0, 10)}
                  </Descriptions.Item>
                )}
              </Descriptions>
            </div>

            <div>
              <h2 className="text-[17px] text-black mt-[20px] mb-[15px] ">قيم بواسطة :</h2>
              <Descriptions bordered column={1}>
                {rateDetails?.rate_from?.name && (
                  <Descriptions.Item label="الأسم">
                    {rateDetails?.rate_from?.name}
                  </Descriptions.Item>
                )}
                {rateDetails?.rate_from?.phone && (
                  <Descriptions.Item label="رقم الهاتف">
                    {rateDetails?.rate_from?.phone}
                  </Descriptions.Item>
                )}
                {rateDetails?.rate_from?.email && (
                  <Descriptions.Item label="البريد الألكتروني">
                    {rateDetails?.rate_from?.email}
                  </Descriptions.Item>
                )}
                {rateDetails?.rate_from?.address && (
                  <Descriptions.Item label="العنوان">
                    {rateDetails?.rate_from?.address}
                  </Descriptions.Item>
                )}
              </Descriptions>
            </div>

            <div>
              <h2 className="text-[17px] text-black mt-[20px] mb-[15px] ">بيانات المربية : </h2>
              <Descriptions bordered column={1}>
                {rateDetails?.user?.name && (
                  <Descriptions.Item label=" أسم المربية">
                    {rateDetails?.user.name}
                  </Descriptions.Item>
                )}
                {rateDetails?.user?.phone && (
                  <Descriptions.Item label="رقم الهاتف">
                    {rateDetails?.user?.phone}
                  </Descriptions.Item>
                )}
                {rateDetails?.user?.email && (
                  <Descriptions.Item label="البريد الألكتروني">
                    {rateDetails?.user?.email}
                  </Descriptions.Item>
                )}
                {rateDetails?.user?.address && (
                  <Descriptions.Item label="العنوان">
                    {rateDetails?.user?.address}
                  </Descriptions.Item>
                )}
                {rateDetails?.user?.nationality && (
                  <Descriptions.Item label="الجنسية">
                    {rateDetails?.user?.nationality}
                  </Descriptions.Item>
                )}
                {rateDetails?.user?.lang && (
                  <Descriptions.Item label="اللغة">{rateDetails?.user?.lang}</Descriptions.Item>
                )}
              </Descriptions>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default RateDetails;

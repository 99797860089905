import { Button, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { Store } from 'react-notifications-component';
import deleteAreaApi from '../../apis/area-api/deleteAreaApi';
import CitysContext from '../../contexts/City-context/CityProvider';
import { useContext } from 'react';

const AreasColumns = (
  token,
  setFetchCount,
  setLoadingAreas,
  setAreaModalOpened,
  setSelectedAreaId,
  setShowAreaModalOpened,
  setSelectedCityId,
  setSelectedCountary
) => {

  const {
    
    selectedCityId
  } = useContext(CitysContext);

  const handleEditArea = (record) => {
    setAreaModalOpened(true);
    setSelectedAreaId(record.key);
    setSelectedCityId(record.city_id);
   
    // setSelectedCountary(record.country_id)
    
    // 
  };
  // const handleShowAreaCountary = (key) => {
  //   setShowAreaModalOpened(true);
  //   setSelectedAreaId(key);

  //   // 
  // };
  const handleDeleteRequest = async ({id}) => {
    
    try {
      setLoadingAreas(true);
      const res = await deleteAreaApi(id, token);
      if (res.status === 200 && res.data.status === 1) {
        setFetchCount((prevState) => prevState + 1);
        setLoadingAreas(false);
        Store.addNotification({
          title: 'العملية تمت بنجاح',
          message: res?.data?.message,
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        setLoadingAreas(false);
        Store.addNotification({
          title: 'حدث خطأ ',
          message: res?.data?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      
      setLoadingAreas(false);
      Store.addNotification({
        title: 'حدث خطأ ',
        message: 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };
  if(selectedCityId){
    return [
      {
        title: '#',
        dataIndex: 'id',
        width: 62
      },
  
      {
        title: 'الاسم العربي',
        dataIndex: 'name_ar',
        width: 192
      },
      {
        title: 'الاسم الانجليزي',
        dataIndex: 'name_en',
        width: 192
      },
      
    ];
  }
  else{
    return [
      {
        title: '#',
        dataIndex: 'id',
        width: 62
      },
  
      {
        title: 'الاسم العربي',
        dataIndex: 'name_ar',
        width: 192
      },
      {
        title: 'الاسم الانجليزي',
        dataIndex: 'name_en',
        width: 192
      },
      {
        title: 'تعديل المنطقه',
        dataIndex: 'edit',
        width: 92,
        render: (_, record) => {
          return (
            <Tooltip title="تعديل المنطقه">
              <Button
                className="edit-btn"
                onClick={(key) => handleEditArea(record)}
                size="large"
                type="dashed"
                shape="circle"
                icon={<EditOutlined />}
              />
            </Tooltip>
          );
        }
      },
      {
        title: 'حذف المنطقه',
        dataIndex: 'delete',
        width: 92,
        render: (_, record) => {
          return (
            <Popconfirm
              title={
                <div style={{ maxWidth: '200px' }}>
                  <p>هل انت متأكد من حذف المنطقه</p>
                  {/* <p>حذف المنطقه سيؤدى الى حذف جميع المشاريع والاسئلة الخاصة بالمنطقه</p> */}
                </div>
              }
              okText="نعم"
              cancelText="لا"
              onConfirm={(key) => handleDeleteRequest(record)}>
              <Tooltip title="حذف المنطقه">
                <Button
                  className="delete-btn"
                  size="large"
                  type="dashed"
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </Popconfirm>
          );
        }
      }
    ];
  }
 
};

export default AreasColumns;

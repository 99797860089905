import { Modal, Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Store } from 'react-notifications-component';
import getContact from '../../apis/contact-api/getContact';
import ContactUsContext from '../../contexts/contactUs-context/ContactUsProvider';
import UesrContext from '../../contexts/user-context/UserProvider';
import ContactUsColumns from './contactUsColums.js';

const ContactUsPage = () => {
  const [contact, setcontact] = useState([]);
  const [count, setcount] = useState([]);
  const [loading, setloading] = useState(false);

  const { user } = useContext(UesrContext);
  const { selectedContactUs,setContactUsModalOpened,ContactUsModalOpened } = useContext(ContactUsContext);
  
  useEffect(() => {
    const getContactCall = async () => {
      try {
        setloading(true);
        const fetchedData = await getContact(user?.token);
        
        if (!fetchedData) {
          setloading(false);
        } else {
          setloading(false);

          setcontact(fetchedData);
        }
      } catch (error) {
        setloading(false);
        Store.addNotification({
          title: 'حدث خطأ ',
          message: 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
        
      }
    };
    getContactCall();
  }, [user?.token, count]);
 
  return (
    <div>
      <Table
        columns={ContactUsColumns(count, setcount, user?.token)}
        dataSource={contact}
        loading={loading}
        scroll={{ x: 1200 }}
      />
     <Modal   footer={false}
              title="  تفاصيل الرسالة " className="add-Order-modal"
              width="90%"
              style={{ maxWidth: '900px' }}
              
              open={ContactUsModalOpened}
              onOk={() => setContactUsModalOpened(false)}
              onCancel={() => setContactUsModalOpened(false)}
            >
             
              <p>{selectedContactUs?.message}</p>
             
            </Modal>
    </div>
  );
};

export default ContactUsPage;

import axios from 'axios';


const createDriverApi = async (url, values, token) => {
  // console.log(values)
  const { phone, email, image, date_of_birth, gender, parent_id, nationality, name, phone_code, map_loc } = values;
  const formData = new FormData();

  if (Array.isArray(image)) {
    formData.append("image", image[0]);
  }
  formData.append("name", name);
  formData.append("email", email);
  formData.append("phone", phone);
  formData.append("date_of_birth", date_of_birth);
  formData.append("gender", gender);
  formData.append("nationality", nationality);
  formData.append("phone_code", phone_code);
  formData.append("parent_id", parent_id);
  try {
    // console.log(image[0])

    const createSerivceRes = await axios.post(url, formData, {
      headers: {
        Accept: 'application/json',

        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      }
    });
    console.log(createSerivceRes)

    return createSerivceRes;
  } catch (error) {
    console.log(error?.response)
    return error?.response;

  }
};

export default createDriverApi;


import { useContext } from 'react';
import UesrContext from '../../contexts/user-context/UserProvider';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Descriptions, Spin } from 'antd';
import { useParams, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect, useState } from 'react';
import getSitter from '../../apis/sitters-api/getSitterApi';
import getRoom from '../../apis/rooms-api/getRoomApi';
import RoomContext from '../../contexts/room-context/roomsProvider';

const RoomDetails = () => {
  const { user } = useContext(UesrContext);
  const { roomDetails, setRoomDetails, loadingRooms, setLoadingRooms } = useContext(RoomContext);
  let history = useHistory();
  let param = useParams();

  useEffect(() => {
    const getTargetRoom = async () => {
      setLoadingRooms(true);
      try {
        const targetRoomRes = await getRoom(user?.token, param?.id);

        if (targetRoomRes?.status === 200) {
          setLoadingRooms(false);
          setRoomDetails(targetRoomRes?.data?.room_details);
        }
      } catch (error) {
        throw error;
      }
    };

    getTargetRoom();
  }, []);

  return (
    <>
      {loadingRooms ? (
        <div className="mx-auto mt-[100px] w-[100px]">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className="flex flex-row justify-between">
            <h2 className="text-[20px] ">بيانات الغرفة : </h2>
            <Button
              onClick={() => history.push('/rooms')}
              icon={<CloseOutlined />}
              size="small"></Button>
          </div>

          <div className=" lg:w-[70%] mt-[30px]">
            <div>
              <Descriptions bordered column={1}>
                {roomDetails?.name && (
                  <Descriptions.Item label="أسم الغرفة">{roomDetails?.name}</Descriptions.Item>
                )}
                {roomDetails?.setter?.user?.name && (
                  <Descriptions.Item label="المربية">
                    {roomDetails?.setter?.user?.name}
                  </Descriptions.Item>
                )}
                {roomDetails?.facility?.name && (
                  <Descriptions.Item label="المنشأة">
                    {roomDetails?.facility?.name}
                  </Descriptions.Item>
                )}
                {roomDetails?.created_at && (
                  <Descriptions.Item label="تاريخ الانشاء">
                    {roomDetails?.created_at.slice(0, 10)}
                  </Descriptions.Item>
                )}
              </Descriptions>
            </div>

            {/*  <div>
          <Descriptions>
            {targetSitter?.image_url && (
              <Descriptions.Item>
                <img src={targetSitter?.image_url} alt="user image" className="w-full" />
              </Descriptions.Item>
            )}
          </Descriptions>
        </div> */}
          </div>
        </>
      )}
    </>
  );
};

export default RoomDetails;

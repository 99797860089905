import './UpdateOrderPage';

/* eslint-disable react-hooks/exhaustive-deps */
import { EditOutlined, SendOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
// import MyDropZone from '../../common/dorp-zone/MyDropZone';
// import MyDropZonePreview from '../../common/dorp-zone';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
// import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { Button, Form } from 'antd';

import { useEffect, useState } from 'react';
import { Store } from 'react-notifications-component';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import getAllSetterId from '../../apis/certificates-api/getAllSetterID';
import getDriversID from '../../apis/drivers-api/getDriversID';
import createOrdersApi from '../../apis/orders-api/createOrderApi';
import getTargetOrdersApi from '../../apis/orders-api/getTargetOrderApi';
import getParentsID from '../../apis/parents-api/getParentsID';
import DatePickerBox from '../../common/DatePickerBox/DatePickerBox';
import SelectSearchBox from '../../common/SelectSearchBox/SelectSearchBox';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import UesrContext from '../../contexts/user-context/UserProvider';
import GoogleMaps from '../GoogleMaps/GoogleMaps';
// import UploadQuestionFile from '../blog-page/UploadQuestionFile';

const defaultValues = {
  // phone_code: "",
  // image: null,
  // email: "",
  // gender: "",
  // address: "",
  // nationality: "",
  // phone: "",
  date: null,

  discount: '',
  driver_id: '',
  parent_id: '',
  setter_id: '',
  days: '',
  time: '',
  hours: '',
  map_loc: null

  // national_id: "",
  // name: "",
  // is_disease: ""
};

const UpdateOrderPage = () => {
  const { user } = useContext(UesrContext);
  const [dataParentId, setDataParentId] = useState([]);
  const [dataSetterId, setDataSetterId] = useState([]);
  const [dataDriverId, setDataDriverId] = useState([]);
  const [OrderTargetData, setOrderTargetData] = useState(null);
  let param = useParams();

  // console.log(user);
  let history = useHistory();
  let schema = Yup.object().shape({
    // phone_code: Yup.string().required(' من فضلك أدخل الوصف مطلوب'),
    // image: Yup.mixed().required('من فضلك أدخل المطلوب'),
    // email: Yup.string().required('البريد الألكتروني').email('من فضلك ادخل بريد الكترونى صحيح'),
    // gender: Yup.string().required('من فضلك أدخل المطلوب'),
    // name: Yup.string().required('من فضلك أدخل المطلوب'),
    // national_id: Yup.string().min(10, "least 10 characters").required('من فضلك أدخل المطلوب'),
    // address: Yup.string().optional('من فضلك أدخل المطلوب'),
    // nationality: Yup.string().required('من فضلك أدخل المطلوب'),
    // phone: Yup.string().min(10, "least 10 characters").required(' من فضلك أدخل الموضوع مطلوب'),
    // phone: Yup.string().optional('من فضلك أدخل المطلوب'),

    days: Yup.string().required('من فضلك أدخل المطلوب'),
    time: Yup.string().required('من فضلك أدخل المطلوب'),
    hours: Yup.string().required('من فضلك أدخل المطلوب'),

    date: Yup.mixed().required('اختارالتاريخ '),

    map_loc: Yup.mixed().required(' من فضلك أدخل الوصف مطلوب'),

    discount: Yup.string().required('من فضلك أدخل المطلوب'),
    driver_id: Yup.string().required('من فضلك أدخل المطلوب'),
    parent_id: Yup.string().required('من فضلك أدخل المطلوب'),
    setter_id: Yup.string().required('من فضلك أدخل المطلوب')

    // is_disease: Yup.string().required('من فضلك أدخل المطلوب'),
  });

  const {
    control,
    // getValues,
    setValue,
    // setError,
    // clearErrors,
    handleSubmit,
    register,
    watch,
    unregister,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });
  const [urls, setUrls] = useState([]);

  const onSubmit = async (data) => {
    // console.log(data)
    try {
      let res;
      res = await createOrdersApi(`/orders/update/${param?.id}`, { ...data }, user?.token);
      console.log(res?.status);

      // console.log('create Selles response: ', res);
      if (res?.status !== 200) {
        Store.addNotification({
          title: 'حدث خطء اثناء الأضافه',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (res?.status === 200 && res?.data?.status_code === 200) {
        reset({ ...defaultValues });
        Store.addNotification({
          title: 'تمت العملية بنجاح',
          message: 'تم الأضافه ',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
        history.push('/orders');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [form] = Form.useForm();
  useEffect(() => {
    if (OrderTargetData !== null) {
      setValue('date', OrderTargetData?.date);
      setValue('discount', OrderTargetData?.discount);
      setValue('days', OrderTargetData?.days);
      setValue('time', OrderTargetData?.time);
      setValue('hours', OrderTargetData?.hours);
      setValue('parent_id', String(OrderTargetData?.parent_id));
      setValue('driver_id', String(OrderTargetData?.driver_id));
      setValue('setter_id', String(OrderTargetData?.setter_id));

      setValue('map_loc', { lat: OrderTargetData?.lat, long: OrderTargetData?.long });
    }
  }, [OrderTargetData]);
  // console.log(errors)
  useEffect(() => {
    const handleGetParents = async () => {
      try {
        const getParentsRes = await getParentsID(user?.token);
        // console.log(getParentsRes.data.parents);

        if (getParentsRes.status === 200 && getParentsRes?.data?.parents) {
          setDataParentId(getParentsRes?.data?.parents);
        }
      } catch (error) {}
    };
    handleGetParents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log(watch());

  // ======getSetterRes=========
  useEffect(() => {
    const handleGetSetter = async () => {
      try {
        const getSetterRes = await getAllSetterId(user?.token);
        // console.log(getSetterRes.data.setter_ids);

        if (getSetterRes.status === 200 && getSetterRes?.data?.setters) {
          setDataSetterId(getSetterRes?.data?.setters);
        }
      } catch (error) {}
    };
    handleGetSetter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleGetDriver = async () => {
      try {
        const getDriverRes = await getDriversID(user?.token);
        // console.log(getDriverRes.data.setter_ids);

        if (getDriverRes.status === 200 && getDriverRes?.data?.drivers) {
          setDataDriverId(getDriverRes?.data?.drivers);
        }
      } catch (error) {}
    };
    handleGetDriver();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ================
  useEffect(() => {
    const handleGetTargetOrder = async () => {
      try {
        const getTargetOrderRes = await getTargetOrdersApi(param?.id, user?.token);

        if (getTargetOrderRes.status === 200 && getTargetOrderRes?.data?.order_details) {
          setOrderTargetData(getTargetOrderRes?.data?.order_details);
        }
      } catch (err) {
        setOrderTargetData(null);
      }
    };
    handleGetTargetOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param?.id]);
  return (
    <Form
      className="add-Selles-form flex justify-center items-center"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}>
      <div className="form-body my-[40px] w-full shadow-[rgba(100,_100,_111,_0.2)_0px_7px_29px_0px] max-w-[800px] py-[40px] px-[30px]">
        {/* <div className="form-field-wrapper">
          <AntdTextField
            name="name"
            type="text"
            placeholder="الاسم..."
            errorMsg={errors?.name?.message}
            validateStatus={errors?.name ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">

          <AntdTextField
            name="national_id"
            type="text"
            placeholder="الهوية الوطنية..."
            errorMsg={errors?.national_id?.message}
            validateStatus={errors?.national_id ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="nationality"
            type="text"
            placeholder="الجنسية..."
            errorMsg={errors?.nationality?.message}
            validateStatus={errors?.nationality ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div> */}

        {/* <div className="form-field-wrapper">
          <AntdTextField
            name="address"
            type="text"
            placeholder="العنوان..."
            errorMsg={errors?.address?.message}
            validateStatus={errors?.address ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div> */}
        {/*
        <div className="form-field-wrapper">
          <AntdTextField
            name="email"
            type="text"
            placeholder="البريد الألكتروني..."
            errorMsg={errors?.email?.message}
            validateStatus={errors?.email ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div> */}
        {/* <div className="form-field-wrapper">
          <AntdTextField
            name="phone_code"
            type="text"
            placeholder="رمز الهاتف..."
            errorMsg={errors?.phone_code?.message}
            validateStatus={errors?.phone_code ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div> */}
        {/* <div className="form-field-wrapper">
          <AntdTextField
            name="phone"
            type="text"
            placeholder="الهاتف..."
            errorMsg={errors?.phone?.message}
            validateStatus={errors?.phone ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div> */}
        {/* {!selectedTechnicalSupport?.client?.id && (
          <AntdSelectOption
            name="client_id"
            errorMsg={errors?.client_id?.message}
            validateStatus={errors?.client_id ? 'error' : ''}
            control={control}
            setValue={setValue}
            disabled={selectedTechnicalSupport?.client?.id && true}
            options={
              customerSelectData?.length &&
              customerSelectData?.map((el) => ({
                title: el?.name,
                value: el?.ref_id
              }))
            }
            label={
              <p className="select-option-label">
                <EditOutlined />
                العميل
              </p>
            }
            formClassName="add-Selles-form"
          />
        )} */}
        {/* <AntdSelectOption
          name="gender"
          errorMsg={errors?.gender?.message}
          validateStatus={errors?.gender ? 'error' : ''}
          control={control}
          setValue={setValue}
          options={[
            { title: 'male', value: 'male' },
            { title: 'female', value: 'female' },
          ]}
          label={
            <p className="select-option-label">
              <EditOutlined />
              النوع
            </p>
          }
          formClassName="add-Selles-form"
        /> */}
        <div className="form-field-wrapper">
          <AntdTextField
            name="days"
            type="text"
            placeholder="الأيام..."
            errorMsg={errors?.days?.message}
            validateStatus={errors?.days ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="hours"
            type="text"
            placeholder="الساعات..."
            errorMsg={errors?.hours?.message}
            validateStatus={errors?.hours ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="time"
            type="text"
            placeholder="الوقت..."
            errorMsg={errors?.time?.message}
            validateStatus={errors?.time ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="discount"
            type="text"
            placeholder="التخفيض..."
            errorMsg={errors?.discount?.message}
            validateStatus={errors?.discount ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className=" form-field-wrapper py-[4px]">
          <SelectSearchBox
            name="driver_id"
            errorMsg={errors?.driver_id?.message}
            validateStatus={errors?.driver_id ? 'error' : ''}
            defaultValue=""
            placeholder="اختر السائق..."
            control={control}
            setValue={setValue}
            dataSearch={dataDriverId}
            value={watch}
            label={
              <p className="select-option-label">
                <EditOutlined />
                اختر السائق
              </p>
            }
          />
        </div>
        <div className=" form-field-wrapper py-[4px]">
          <SelectSearchBox
            name="setter_id"
            errorMsg={errors?.setter_id?.message}
            validateStatus={errors?.setter_id ? 'error' : ''}
            defaultValue=""
            placeholder="اختر المربيه..."
            control={control}
            setValue={setValue}
            dataSearch={dataSetterId}
            value={watch}
            label={
              <p className="select-option-label">
                <EditOutlined />
                اختر المربيه
              </p>
            }
          />
        </div>
        <div className=" form-field-wrapper py-[4px]">
          <SelectSearchBox
            name="parent_id"
            errorMsg={errors?.parent_id?.message}
            validateStatus={errors?.parent_id ? 'error' : ''}
            defaultValue=""
            placeholder="اختر الوالد..."
            control={control}
            setValue={setValue}
            dataSearch={dataParentId}
            value={watch}
            label={
              <p className="select-option-label">
                <EditOutlined />
                اختر الوالد
              </p>
            }
          />
        </div>
        {/* <AntdSelectOption
          name="is_disease"
          errorMsg={errors?.is_disease?.message}
          validateStatus={errors?.is_disease ? 'error' : ''}
          control={control}
          setValue={setValue}
          options={[
            { title: 'نعم', value: '1' },
            { title: 'لا', value: '0' },
          ]}
          label={
            <p className="select-option-label">
              <EditOutlined />
              هل يوجد مرض
            </p>
          }
          formClassName="add-Selles-form"
        /> */}
        <div className=" form-field-wrapper py-[4px]">
          <DatePickerBox
            name="date"
            errorMsg={errors?.date?.message}
            validateStatus={errors?.date ? 'error' : ''}
            defaultValue=""
            control={control}
            setValue={setValue}
            watch={watch}
            label={
              <p className="select-option-label">
                <EditOutlined />
                من تاريخ
              </p>
            }
          />
        </div>

        <GoogleMaps
          type={'update'}
          watch={watch}
          setValue={setValue}
          letTarget={OrderTargetData?.lat}
          lngTarget={OrderTargetData?.long}
          name={'map_loc'}
          errorMsg={errors?.map_loc ? errors?.map_loc?.message : ''}
        />

        {/* <div className="form-field-wrapper">
          <UploadQuestionFile
            // accept="image/png, image/jpg, image/jpeg, image/gif"
            maxFiles={1}
            name="image"
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
            multiple={false}
            label={
              <>
                <EditOutlined />
                الصوره
              </>
            }
            urls={urls}
            errorMsg={errors?.image ? errors?.image?.message : ''}
          />
        </div> */}

        {/* <div className="form-field-wrapper">
          {!selectedServId && (
            <AntdTextField
              name="image"
              type="file"
              placeholder="موضوع..."
              errorMsg={errors?.image?.message}
              validateStatus={errors?.image ? 'error' : ''}
              prefix={<EditOutlined />}
              control={control}
            />
          )}
        </div> */}

        <Button
          className="submit-btn mx-auto mt-[30px]  !h-[45px] !w-[100px]   !flex justify-center items-center"
          htmlType="submit"
          type="primary"
          icon={<SendOutlined />}
          loading={isSubmitting}>
          اضافه
        </Button>
      </div>
    </Form>
  );
};

export default UpdateOrderPage;

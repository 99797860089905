import { Popconfirm, Tooltip, Button } from 'antd';
import { DeleteOutlined, EditOutlined, InfoOutlined } from '@ant-design/icons';
import deleteUser from '../../apis/users-api/deleteUser';
import { Store } from 'react-notifications-component';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const UsersColumns = (
  user,
  setUserFetchCount,
  setLoadingUser,
  userDetails,
  setUserDetails,
  showDetails,
  setShowDetails,
  viewEditUser,
  setViewEditUser
) => {
  let history = useHistory();

  const handleDeleteRequest = async (id) => {
    try {
      setLoadingUser(true);
      const deleteRes = await deleteUser(id, user?.token);
      if (deleteRes?.status === 200) {
        setUserFetchCount((prevState) => prevState + 1);
        setLoadingUser(false);
        Store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم حذف المستخدم بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        setLoadingUser(false);
        Store.addNotification({
          title: 'حدث خطأ ',
          message: deleteRes?.data?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
      console.log(deleteRes);
    } catch (error) {
      setLoadingUser(false);
      Store.addNotification({
        title: 'حدث خطأ ',
        message: 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };

  return [
    {
      title: '#',
      dataIndex: 'id',
      width: 62,
      render: (_, record) => {
        return <div>{record.id}</div>;
      }
    },
    {
      title: 'الأسم',
      dataIndex: 'name',
      width: 120,
      render: (_, record) => {
        return <div>{record.name}</div>;
      }
    },
    {
      title: ' رقم الهاتف',
      dataIndex: 'phone',
      width: 80,
      render: (_, record) => {
        return <div>{record.phone}</div>;
      }
    },
    {
      title: 'البريد الالكتروني',
      dataIndex: 'email',
      width: 110,
      render: (_, record) => {
        return <div>{record.email}</div>;
      }
    },
    {
      title: ' العنوان',
      dataIndex: 'address',
      width: 110,
      render: (_, record) => {
        return <div>{record.address}</div>;
      }
    },
    {
      title: 'صورة المستخدم',
      dataIndex: 'image_url',
      width: 110,
      render: (_, record) => {
        return (
          <div className="w-[100px] h-[50px] mx-auto">
            <img src={record.image_url} alt="user image" className="w-full h-full" />
          </div>
        );
      }
    },
    {
      title: 'التفاصيل ',
      dataIndex: 'details',
      width: 30,
      render: (_, record) => {
        return (
          <Tooltip title="التفاصيل ">
            <Button
              className="edit-btn PaymentLog-btn"
              onClick={() => history.push(`/users/view/${record?.id}`)}
              size="large"
              type="dashed"
              shape="circle"
              icon={<InfoOutlined />}
            />
          </Tooltip>
        );
      }
    },
    {
      title: 'تعديل ',
      dataIndex: 'edit',
      width: 30,
      render: (_, record) => {
        return (
          <Tooltip title="تعديل المستخدم">
            <Button
              className="edit-btn"
              onClick={() => history.push(`/users/update/${record?.id}`)}
              size="large"
              type="dashed"
              shape="circle"
              icon={<EditOutlined />}
            />
          </Tooltip>
        );
      }
    },
    {
      title: 'حذف',
      dataIndex: 'delete',
      width: 30,
      render: (_, record) => {
        return (
          <Popconfirm
            title={
              <div style={{ maxWidth: '200px' }}>
                <p>هل انت متأكد من حذف المستخدم</p>
              </div>
            }
            okText="نعم"
            cancelText="لا"
            onConfirm={(id) => handleDeleteRequest(record.id)}>
            <Tooltip title="حذف المستخدم؟">
              <Button
                className="delete-btn"
                size="large"
                type="dashed"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        );
      }
    }
  ];
};

export default UsersColumns;

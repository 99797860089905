import axios from 'axios';

const getAllAdmins = async (token) => {
  try {
    const getAdmins = await axios.post(
      '/admins/index',
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'applications/json',
          Authorization: 'Bearer ' + token
        }
      }
    );
    console.log(getAdmins);
    return getAdmins;
  } catch (error) {
    return error?.response;
  }
};

export default getAllAdmins;

import { Table } from 'antd';
import React, { useContext, useEffect } from 'react';
import getAllAdmins from '../../apis/admin-api/getAllAdmins';
import AdminContext from '../../contexts/admin-context/AdminProvider';
import UesrContext from '../../contexts/user-context/UserProvider';
import AdminColumns from './AdminColumns';
const AdminTable = ({ filterValue }) => {
  const { user } = useContext(UesrContext);
  const {
    fetchCount,
    setFetchCount,
    loadingAdmin,
    setLoadingAdmin,
    adminDetails,
    setAdminDetails,
    showAdminDetails,
    setShowAdminDetails,
    allAdmin,
    setAllAdmin
  } = useContext(AdminContext);

  useEffect(() => {
    const getAdmins = async () => {
      try {
        setLoadingAdmin(true);

        const getAdminsRes = await getAllAdmins(user?.token);

        if (getAdminsRes.status === 200) {
          setLoadingAdmin(false);
          setAllAdmin(getAdminsRes?.data);
          console.log(getAdminsRes?.data);
        }
      } catch (error) {
        console.log('error', error);
      }
    };

    getAdmins();
  }, [fetchCount]);

  return (
    <div>
      <Table
        pagination={{ pageSize: 5 }}
        className="Orders-table"
        dataSource={allAdmin?.users}
        loading={loadingAdmin}
        columns={AdminColumns(
          user,
          fetchCount,
          setFetchCount,
          loadingAdmin,
          setLoadingAdmin,
          adminDetails,
          setAdminDetails,
          showAdminDetails,
          setShowAdminDetails,
          allAdmin,
          setAllAdmin
        )}
        scroll={{ x: 1200 }}
      />
    </div>
  );
};

export default AdminTable;

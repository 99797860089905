import { Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import UesrContext from '../../contexts/user-context/UserProvider';
import RateContext from '../../contexts/rates-context/RatesProvider';
import getAllRates from '../../apis/rates-api/getAllRatesApi';
import RatesColumns from './RatesColumns';
const RatesTable = ({ filterValue }) => {
  const { user } = useContext(UesrContext);
  const { rateFetchCount, setRateFetchCount, loadingRate, setLoadingRate, allRates, setAllRates } =
    useContext(RateContext);

  useEffect(() => {
    const getRates = async () => {
      try {
        setLoadingRate(true);
        const getRatesRes = await getAllRates(user?.token);

        if (getRatesRes?.status === 200) {
          setLoadingRate(false);
          setAllRates(getRatesRes?.data?.rates?.data);
        }
      } catch (error) {
        console.log('error', error);
      }
    };

    getRates();
  }, []);

  const filterRates = () => {
    if (filterValue) {
      if (filterValue === 'all') {
        return allRates;
      } else {
        return allRates.filter((item) => item.user_id == filterValue);
      }
    }
  };

  return (
    <div>
      <Table
        pagination={{ pageSize: 5 }}
        dataSource={filterRates()}
        loading={loadingRate}
        columns={RatesColumns()}
        scroll={{ x: 1200 }}
      />
    </div>
  );
};

export default RatesTable;

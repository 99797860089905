import axios from 'axios';

const createChildrenApi = async (url, values, token) => {
  // console.log(values)
  const {
    note,
    email,
    image,
    date_of_birth,
    gender,
    disease,
    nationality,
    hobby,
    parent,
    name,
    is_disease
  } = values;
  console.log(disease == '');
  //
  try {
    const formData = new FormData();
    if (Array.isArray(image)) {
      formData.append('image', image[0]);
    }
    formData.append('name', name);
    formData.append('email', email);
    formData.append('hobby', hobby);
    formData.append('note', note);
    formData.append('date_of_birth', date_of_birth);
    formData.append('gender', gender);
    formData.append('nationality', nationality);
    formData.append('is_diseased', is_disease);
    formData.append('disease', disease !== '' ? disease : String(''));

    formData.append('parent_id', parent);

    const createSerivceRes = await axios.post(url, formData, {
      headers: {
        Accept: 'application/json',

        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      }
    });
    return createSerivceRes;

  } catch (error) {
    console.log(error?.response)
    return error?.response;
  }
};

export default createChildrenApi;

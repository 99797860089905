import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => { },
  loadingCertificates: false,
  setLoadingCertificates: (load) => { },
  allCertificates: [],
  setAllCertificates: (services) => { },
  CertificatesModalOpened: false,
  setCertificatesModalOpened: (v) => { },
  selectedCertificatesId: '',
  setSelectedCertificatesId: (id) => { },
  selectedCertificates: null,
  setSelectedCertificates: (servKey) => { },
  selectedFilter: '',
  setSelectedFilter: (v) => { }
};

const CertificatesContext = createContext(INITIAL_VALUES);

export const CertificatesProvider = ({ children }) => {
  const [selectedFilter, setSelectedFilter] = useState(INITIAL_VALUES.selectedFilter);
  const [allCertificates, setAllCertificates] = useState(INITIAL_VALUES.allCertificates);
  const [loadingCertificates, setLoadingCertificates] = useState(INITIAL_VALUES.loadingCertificates);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [CertificatesModalOpened, setCertificatesModalOpened] = useState(INITIAL_VALUES.CertificatesModalOpened);
  const [selectedCertificatesId, setSelectedCertificatesId] = useState(INITIAL_VALUES.selectedCertificatesId);
  const [selectedCertificates, setSelectedCertificates] = useState(INITIAL_VALUES.selectedCertificates);

  return (
    <CertificatesContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        loadingCertificates,
        setLoadingCertificates,
        selectedFilter,
        setSelectedFilter,
        allCertificates,
        setAllCertificates,
        CertificatesModalOpened,
        setCertificatesModalOpened,
        selectedCertificatesId,
        setSelectedCertificatesId,
        selectedCertificates,
        setSelectedCertificates
      }}>
      {children}
    </CertificatesContext.Provider>
  );
};

export default CertificatesContext;

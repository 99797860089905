import axios from 'axios';

const createOrdersApi = async (url, values, token) => {
  // console.log(values)
  const { date, discount, driver_id, parent_id, setter_id, days, time, hours, map_loc } = values;
  const formData = new FormData();

  formData.append('date', date);
  formData.append('discount', discount);
  formData.append('driver_id', driver_id);
  formData.append('setter_id', setter_id);
  formData.append('days', days);
  formData.append('time', time);
  formData.append('hours', hours);
  formData.append('parent_id', parent_id);
  formData.append('long', map_loc?.long);
  formData.append('lat', map_loc?.lat);
  try {
    // console.log(image[0])

    const createSerivceRes = await axios.post(url, formData, {
      headers: {
        Accept: 'application/json',

        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      }
    });
    // console.log(createSerivceRes);

    return createSerivceRes;
  } catch (error) {
    // console.log(error?.response);
    return error?.response;
  }
};

export default createOrdersApi;

import axios from 'axios';

const getUser = async (token, id) => {
  try {
    const getUserRes = await axios.get(`/users/show/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token
      }
    });
    console.log(getUserRes);
    return getUserRes;
  } catch (error) {
    return error?.response;
  }
};

export default getUser;

import { Table } from 'antd';

import React, { useContext, useEffect, useState } from 'react';
import getAllRooms from '../../apis/rooms-api/getAllRoomsApi';
import UesrContext from '../../contexts/user-context/UserProvider';
import RoomsColumns from './RoomsColumns';
import RoomContext from '../../contexts/room-context/roomsProvider';
const RoomsTable = ({ filterValue }) => {
  const { user } = useContext(UesrContext);
const {
    fetchCount,
    setFetchCount,
    loadingRooms,
    setLoadingRooms,
    allRooms,
    setAllRooms 
}= useContext(RoomContext)

  useEffect(() => {

    const getRooms = async () => {
        try {
            setLoadingRooms(true)
          const getRoomsRes = await getAllRooms(user?.token);

          if (getRoomsRes?.status === 200) {
            setLoadingRooms(false)
                setAllRooms(getRoomsRes?.data?.rooms);
                console.log(getRoomsRes?.data?.rooms?.data);
            }
            
        } catch (error) {
          console.log('error', error);
        }
      }
   

    getRooms();

  }, [fetchCount]);

  return (
    <div>
      <Table
        pagination={{pageSize:5}}
        dataSource={allRooms}
        loading={loadingRooms}
        columns={RoomsColumns(
          user,
          fetchCount,
          setFetchCount,
          loadingRooms,
          setLoadingRooms,
          allRooms,
          setAllRooms 
        )}
        scroll={{ x: 1200 }}
      />
    </div>
  );
};

export default RoomsTable;

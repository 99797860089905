
import { EditOutlined, SendOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button, Form } from 'antd';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { useEffect, useState } from 'react';
import { Store } from "react-notifications-component";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import CreateCertificatesApi from '../../apis/certificates-api/createCertificatesApi';
import getAllSetterId from '../../apis/certificates-api/getAllSetterID';
import SelectSearchBox from "../../common/SelectSearchBox/SelectSearchBox";
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import UploadQuestionFile from "../../common/file-input/UploadQuestionFile";
import UesrContext from '../../contexts/user-context/UserProvider';
import GetTargetCertificatesApi from '../../apis/certificates-api/getTargetCertificatesApi';

const defaultValues = {
  image: null,
  setter_id: "",
  name: "",
};

const UpdateCertificates = () => {
  const { user } = useContext(UesrContext);
  const [dataParentId, setDataParentId] = useState([]);
  const [SetterTargetData, setSetterTargetData] = useState(null);

  let history = useHistory();
  let param = useParams()

  let schema = Yup.object().shape({
    image: Yup.mixed().required('من فضلك أدخل المطلوب'),
    name: Yup.string().required('من فضلك أدخل المطلوب'),
    setter_id: Yup.string().required('من فضلك أدخل المطلوب'),


  });



  const {
    control,
    // getValues,
    setValue,
    // setError,
    // clearErrors,
    handleSubmit,
    register,
    watch,
    unregister,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });
  const [urls, setUrls] = useState([]);
  console.log()

  const onSubmit = async (data) => {
    // console.log(data)
    try {
      let res;
      res = await CreateCertificatesApi(`/certificates/update/${param?.id}`, { ...data }, user?.token,);
      // console.log(res)

      // console.log('create Selles response: ', res);
      if (res?.status !== 200) {
        Store.addNotification({
          title: 'حدث خطء اثناء الأضافه',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (res?.status === 200 && res?.data?.status_code === 200) {
        reset({ ...defaultValues });
        Store.addNotification({
          title: 'تمت العملية بنجاح',
          message: 'تم الأضافه ',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
        history.push("/certificates")
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [form] = Form.useForm();
  useEffect(() => {
    if (SetterTargetData !== null) {

      setValue('setter_id', String(SetterTargetData?.setter_id));
      setValue('name', SetterTargetData?.certificate_name);

      setValue('image', SetterTargetData?.image_url);
    }
  }, [SetterTargetData]);
  useEffect(() => {
    const handleGetTargetSetter = async () => {
      try {
        const getTargetSetterRes = await GetTargetCertificatesApi(param?.id, user?.token);

        if (getTargetSetterRes.status === 200 && getTargetSetterRes?.data?.certificate_details) {
          setSetterTargetData(getTargetSetterRes?.data?.certificate_details)
        }
      } catch (err) {
        setSetterTargetData(null)

      }
    };
    handleGetTargetSetter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param?.id]);
  useEffect(() => {
    const handleGetSetter = async () => {
      try {
        const getSetterRes = await getAllSetterId(user?.token);
        // console.log(getSetterRes.data.setter_ids);

        if (getSetterRes.status === 200 && getSetterRes?.data?.setters) {
          setDataParentId(getSetterRes?.data?.setters);

        }
      } catch (error) {

      }
    };
    handleGetSetter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Form
      className="add-Selles-form flex justify-center items-center"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}>
      <div
        className="form-body my-[40px] w-full shadow-[rgba(100,_100,_111,_0.2)_0px_7px_29px_0px] max-w-[800px] py-[40px] px-[30px]">
        <div className="form-field-wrapper">
          <AntdTextField
            name="name"
            type="text"
            placeholder="الاسم..."
            errorMsg={errors?.name?.message}
            validateStatus={errors?.name ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>





        <div className=" form-field-wrapper py-[10px]">
          <SelectSearchBox
            name="setter_id"
            errorMsg={errors?.setter_id?.message}
            validateStatus={errors?.setter_id ? 'error' : ''}
            defaultValue=""
            placeholder="اختر المربيه..."

            control={control}
            setValue={setValue}
            dataSearch={dataParentId}
            value={watch}
            label={
              <p className="select-option-label">
                <EditOutlined />
                اختر المربيه
              </p>
            }

          />
        </div>




        <div className="form-field-wrapper">
          <UploadQuestionFile
            // accept="image/png, image/jpg, image/jpeg, image/gif"
            maxFiles={1}
            name="image"
            setValue={setValue}
            watch={watch}
            imageTarget={Array.isArray(watch("image")) ? null : watch("image")}
            register={register}
            unregister={unregister}
            multiple={false}
            label={
              <>
                <EditOutlined />
                الصوره
              </>
            }
            urls={urls}
            errorMsg={errors?.image ? errors?.image?.message : ''}
          />
        </div>

        {/* <div className="form-field-wrapper">
          {!selectedServId && (
            <AntdTextField
              name="image"
              type="file"
              placeholder="موضوع..."
              errorMsg={errors?.image?.message}
              validateStatus={errors?.image ? 'error' : ''}
              prefix={<EditOutlined />}
              control={control}
            />
          )}
        </div> */}

        <Button
          className="submit-btn mx-auto mt-[30px]  !h-[45px] !w-[100px]   !flex justify-center items-center"
          htmlType="submit"
          type="primary"
          icon={<SendOutlined />}
          loading={isSubmitting}>
          اضافه
        </Button>
      </div>

    </Form >
  );
};

export default UpdateCertificates;

import React, { useContext, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import { Button, Form } from 'antd';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { EditOutlined, SendOutlined } from '@ant-design/icons';
import { Store } from 'react-notifications-component';
import * as Yup from 'yup';
import getAllCountarys from '../../apis/countary-api/getAllcountaries';
import UpdateProfileApi from '../../apis/profile-apis/updateProfile';
import DatePickerBox from '../../common/DatePickerBox/DatePickerBox';
import SelectSearchBox from '../../common/SelectSearchBox/SelectSearchBox';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import UploadQuestionFile from '../../common/file-input/UploadQuestionFile';
import UesrContext from '../../contexts/user-context/UserProvider';
import routerLinks from '../../components/app/routerLinks';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const defaultValues = {
  name: '',
  image: null,
  date_of_birth: null,
  email: '',
  gender: '',
  nationality: '',
  phone_code: '',
  phone: '',
  role: '',
  address: '',
  national_id: '',
  password: ''
};

const ProfileForm = () => {
  const { user, setCurrentUser, removeCurrentUser } = useContext(UesrContext);
  const [allCountarys, setAllCountarys] = useState(null);
  const [phoneCodeError, setPhoneCodeError] = useState('');
  const [phoneCodeOptions, setPhoneCodeOptions] = useState([]);
  const [nationalityOptions, setNationalityOptions] = useState([]);
  const schema = yup.object().shape({
    name: Yup.string().required('من فضلك أدخل الأسم'),
    image: Yup.mixed().required('من فضلك أدخل المطلوب'),
    date_of_birth: Yup.mixed().required('اختارالتاريخ '),
    email: Yup.string()
      .required(' من فضلك أدخل البريد الألكتروني')
      .email('من فضلك ادخل بريد الكترونى صحيح'),
    password: Yup.string().required('من فضلك ادخل الرقم السرى'),
    gender: Yup.string().required('من فضلك أدخل المطلوب'),
    nationality: Yup.string().required('من فضلك أدخل المطلوب'),
    phone_code: Yup.string().required('من فضلك أدخل الكود'),
    phone: Yup.string().required('من فضلك أدخل رقم الهاتف'),
    role: Yup.string().required('من فضلك أدخل المطلوب'),
    address: Yup.string().required('من فضلك أدخل العنوان'),
    national_id: Yup.string().required('من فضلك أدخل رقم الهوية')
  });
  const history = useHistory();

  useEffect(() => {
    if (user !== null) {
      // console.log(user);
      setValue('name', user?.name);
      setValue('image', user?.image_url);
      setValue('date_of_birth', user?.date_of_birth);
      setValue('email', user?.email);
      setValue('nationality', user?.nationality);
      setValue('gender', user?.gender);
      setValue('phone', user?.phone);
      setValue('address', user?.address);
      setValue('role', user?.role);
      setValue('national_id', user?.national_id);
      setValue('phone_code', user?.phone_code);
    }
  }, [user]);
  const [urls, setUrls] = useState([]);
  const {
    control,
    setValue,
    handleSubmit,
    register,
    watch,
    unregister,

    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });

  const [form] = Form.useForm();
  const onSubmit = async (data) => {
    try {
      const fetchedData = await UpdateProfileApi(data, user?.token);
      if (fetchedData?.status !== 200) {
        console.log(fetchedData);
        // password;
        Store.addNotification({
          title: 'حدث خطأ ',
          message: fetchedData?.data?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        Store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم تعديل البيانات بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
        removeCurrentUser();
        history.push(routerLinks.homePage);
      }
    } catch (error) {
      // console.log(error.response.data.errors);
      Store.addNotification({
        title: 'حدث خطأ ',
        message: error.response.data.errors || 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
      //
    }
  };

  useEffect(() => {
    const getCountarys = async () => {
      let nationalityList = [];
      let phoneCodeList = [];

      try {
        const getCountarysRes = await getAllCountarys(user?.token);

        if (getCountarysRes?.status === 200 && getCountarysRes?.data) {
          setAllCountarys(getCountarysRes?.data?.countries);
          getCountarysRes?.data?.countries.map((country) => {
            nationalityList.push({ name: country?.nationality, id: country?.nationality });
            phoneCodeList.push({ name: country?.code, id: country?.code });
          });

          setNationalityOptions(nationalityList);
          setPhoneCodeOptions(phoneCodeList);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getCountarys();
  }, []);
  return (
    <div className=" w-full">
      <Form
        className="add-Selles-form flex justify-center items-center "
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}>
        <div className="form-body add_form_body">
          <div className="form-field-wrapper">
            <AntdTextField
              name="name"
              type="text"
              placeholder="الاسم..."
              label="الأسم : "
              errorMsg={errors?.name?.message}
              validateStatus={errors?.name ? 'error' : ''}
              prefix={<EditOutlined />}
              control={control}
            />
          </div>

          <div className="form-field-wrapper">
            <AntdTextField
              name="phone"
              type="text"
              placeholder="الهاتف..."
              label="رقم الهاتف : "
              errorMsg={errors?.phone?.message}
              validateStatus={errors?.phone ? 'error' : ''}
              prefix={<EditOutlined />}
              control={control}
            />
          </div>

          <div className="form-field-wrapper">
            <AntdTextField
              name="email"
              type="text"
              placeholder="البريد الالكتروني ..."
              label=" البريد الالكتروني : "
              errorMsg={errors?.email?.message}
              validateStatus={errors?.email ? 'error' : ''}
              prefix={<EditOutlined />}
              control={control}
            />
          </div>

          <div className="form-field-wrapper">
            <AntdTextField
              name="address"
              type="text"
              placeholder="العنوان ..."
              label=" العنوان : "
              errorMsg={errors?.address?.message}
              validateStatus={errors?.address ? 'error' : ''}
              prefix={<EditOutlined />}
              control={control}
            />
          </div>

          <div className="form-field-wrapper">
            <AntdTextField
              name="national_id"
              type="text"
              placeholder="رقم الهوية ..."
              label=" رقم الهوية : "
              errorMsg={errors?.national_id?.message}
              validateStatus={errors?.national_id ? 'error' : ''}
              prefix={<EditOutlined />}
              control={control}
            />
          </div>

          {/* <div className="form-field-wrapper">
            <AntdTextField
              name="phone_code"
              type="text"
              placeholder="كود الهاتف  ..."
              label=" كود الهاتف  : "
              errorMsg={errors?.phone_code?.message}
              validateStatus={errors?.phone_code ? 'error' : ''}
              prefix={<EditOutlined />}
              control={control}
            />
          </div> */}

          <div className=" form-field-wrapper">
            <SelectSearchBox
              name="phone_code"
              errorMsg={
                errors?.phone_code?.message || (
                  <span className="text-red-600">{phoneCodeError}</span>
                )
              }
              validateStatus={errors?.phone_code ? 'error' : ''}
              defaultValue=""
              control={control}
              setValue=""
              dataSearch={phoneCodeOptions}
              placeholder="كود الهاتف  ..."
              label=" كود الهاتف  : "
              value={watch}
            />
          </div>

          <div className=" form-field-wrapper py-[10px]">
            <DatePickerBox
              name="date_of_birth"
              errorMsg={errors?.date_of_birth?.message}
              validateStatus={errors?.date_of_birth ? 'error' : ''}
              setValue={setValue}
              control={control}
              watch={watch}
              label={
                <p className="select-option-label">
                  <EditOutlined />
                  تاريخ الميلاد
                </p>
              }
            />
          </div>

          <div className="form-field-wrapper">
            <AntdSelectOption
              name="gender"
              errorMsg={errors?.gender?.message}
              validateStatus={errors?.gender ? 'error' : ''}
              control={control}
              options={[
                { title: 'male', value: 'male' },
                { title: 'female', value: 'female' }
              ]}
              label={
                <p className="select-option-label">
                  <EditOutlined />
                  النوع
                </p>
              }
              formClassName="add-Selles-form"
            />
          </div>
          <div className="form-field-wrapper">
            <AntdSelectOption
              name="role"
              errorMsg={errors?.role?.message}
              validateStatus={errors?.role ? 'error' : ''}
              control={control}
              options={[
                { title: 'admin', value: 'admin' },
                { title: 'user', value: 'user' }
              ]}
              label={
                <p className="select-option-label">
                  <EditOutlined />
                  الصلاحية
                </p>
              }
              formClassName="add-Selles-form"
            />
          </div>
          {/* <div className="form-field-wrapper">
            <AntdTextField
              name="role"
              type="text"
              placeholder="role..."
              label="Role  : "
              errorMsg={errors?.role?.message}
              validateStatus={errors?.role ? 'error' : ''}
              prefix={<EditOutlined />}
              control={control}
            />
          </div> */}

          {/*  <div className="form-field-wrapper">
            <AntdTextField
              name="nationality"
              type="text"
              placeholder="الجنسية..."
              label="الجنسية : "
              errorMsg={errors?.nationality?.message}
              validateStatus={errors?.nationality ? 'error' : ''}
              prefix={<EditOutlined />}
              control={control}
            />
          </div> */}

          <div className=" form-field-wrapper">
            <SelectSearchBox
              name="nationality"
              errorMsg={errors?.nationality?.message}
              validateStatus={errors?.nationality ? 'error' : ''}
              defaultValue=""
              control={control}
              setValue=""
              dataSearch={nationalityOptions}
              placeholder="الجنسية..."
              label="الجنسية : "
              value={watch}
            />
          </div>
          <div className="form-field-wrapper">
            <AntdTextField
              name="password"
              type="password"
              placeholder="الرقم السري..."
              label="الرقم السري : "
              errorMsg={errors?.password?.message}
              validateStatus={errors?.password ? 'error' : ''}
              prefix={<EditOutlined />}
              control={control}
            />
          </div>
          <div className="form-field-wrapper">
            <UploadQuestionFile
              // accept="image/png, image/jpg, image/jpeg, image/gif"
              maxFiles={1}
              name="image"
              setValue={setValue}
              watch={watch}
              imageTarget={Array.isArray(watch('image')) ? null : watch('image')}
              register={register}
              unregister={unregister}
              multiple={false}
              label={
                <>
                  <EditOutlined />
                  الصوره
                </>
              }
              urls={urls}
              errorMsg={errors?.image ? errors?.image?.message : ''}
            />
          </div>

          <Button
            className="submit-btn mx-auto mt-[30px]  !h-[45px] !w-[100px]   !flex justify-center items-center"
            htmlType="submit"
            type="primary"
            icon={<SendOutlined />}
            // loading={loadingAdmin}
          >
            اضافة
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ProfileForm;

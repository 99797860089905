import { Table } from 'antd';
import React, { useContext, useEffect } from 'react';
import getAllTicketsApi from '../../apis/tickets-api/getAllTicketsApi';
import TicketsContext from '../../contexts/tickets-context/TicketsProvider';
import UesrContext from '../../contexts/user-context/UserProvider';
import TicketsThreadsColumns from './TicketsThreadsColumns';
import getAllTicketsThreadsApi from '../../apis/tickets-api/getAllTicketsThreadsApi';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
const TicketsThreadsTable = () => {
  const { user } = useContext(UesrContext);
  let param = useParams();
  let history = useHistory();
  const {
    fetchCount,
    setFetchCount,
    loadingTickets,
    setLoadingTickets,
    allTicketsThreads,
    setTicketsThreads,
    setParentModalOpened,
    setSelectedParent
  } = useContext(TicketsContext);
  useEffect(() => {
    const getTicketsThreads = async () => {
      try {
        setLoadingTickets(true);
        const getTicketsRes = await getAllTicketsThreadsApi(param?.target_id, user?.token);
        // console.log(getTicketsRes);
        if (getTicketsRes.status === 200 && getTicketsRes?.data?.threads) {
          setTicketsThreads(getTicketsRes?.data?.threads);
          if (getTicketsRes?.data?.threads?.length <= 0) {
            history.push(`/tickets`);
          }
          // console.table(getTicketsRes?.data?.driver?.data)
          // console.log(getTicketsRes?.data?.driver?.data)
          setLoadingTickets(false);
        }
      } catch (error) {
        setLoadingTickets(false);
      }
    };
    getTicketsThreads();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCount]);
  // console.log(allTickets);
  return (
    <Table
      pagination={{ pageSize: 5 }}
      className="Tickets-table"
      dataSource={allTicketsThreads}
      columns={TicketsThreadsColumns(
        user?.token,
        setFetchCount,
        setLoadingTickets,
        setParentModalOpened,
        setSelectedParent
      )}
      loading={loadingTickets}
      scroll={{ x: 1200 }}
    />
  );
};

export default TicketsThreadsTable;

import { Input, Form, Button } from 'antd';
import { useForm } from 'react-hook-form';
import { SendOutlined, EditOutlined } from '@ant-design/icons';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import DatePickerBox from '../../common/DatePickerBox/DatePickerBox';
import UploadQuestionFile from '../../common/file-input/UploadQuestionFile';
import createAdmin from '../../apis/admin-api/CreateAdmin';
import { Store } from 'react-notifications-component';
import AdminContext from '../../contexts/admin-context/AdminProvider';
import { useContext } from 'react';
import UesrContext from '../../contexts/user-context/UserProvider';
import FacilityContext from '../../contexts/facility_context/FacilityProvider';
import { useEffect } from 'react';
import SittersContext from '../../contexts/sitters-context/SittersProvider';
import getAllSitters from '../../apis/sitters-api/getAllSitters';
import { useState } from 'react';
import createFacility from '../../apis/facility_api/createFacilityApi';
import getFacility from '../../apis/facility_api/getFacilityApi';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import getSittersID from '../../apis/sitters-api/getSittersId';
import getFacilitiessID from '../../apis/facility_api/getFacilitiesID';
import SelectSearchBox from '../../common/SelectSearchBox/SelectSearchBox';

const UpdateFacility = () => {
  let schema = Yup.object().shape({
    name: Yup.string().required('من فضلك أدخل الأسم'),
    tax_id: Yup.mixed().required('من فضلك أدخل صورة الرقم الضريبي'),
    rent_contract: Yup.mixed().required('من فضلك أدخل صورة عقد الايجار'),
    setter_id: Yup.string().required('من فضلك أختر المربية'),
    num_of_rooms: Yup.number().required('من فضلك أدخل عدد الغرف'),
    space: Yup.mixed().required('من فضلك أدخل المساحة')
  });

  const { user } = useContext(UesrContext);
  const { loadingFacility, setLoadingFacility } = useContext(FacilityContext);
  const [sittersOptions, setSittersOptions] = useState([]);
  const [targetFacility, setTargetFacility] = useState(null);
  let history = useHistory();
  const param = useParams();
  const defaultValues = {
    name: '',
    tax_id: '',
    rent_contract: '',
    setter_id: '',
    num_of_rooms: ''
  };
  const {
    control,
    setValue,
    handleSubmit,
    register,
    watch,
    unregister,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });

  const { form } = Form.useForm();

  useEffect(() => {
    const getTargetFacility = async () => {
      try {
        const targetFacilityRes = await getFacility(user?.token, param?.id);

        if (targetFacilityRes?.status === 200) {
          setTargetFacility(targetFacilityRes?.data?.facility_details);
          console.log(targetFacilityRes?.data?.facility_details);
        }
      } catch (error) {
        throw error;
      }
    };

    const getSitters = async () => {
      try {
        const getSittersRes = await getSittersID(user?.token);
        if (getSittersRes?.status === 200 && getSittersRes?.data) {
          setSittersOptions(getSittersRes?.data?.setters);
          console.log('object', getSittersRes?.data?.setters);
        }
      } catch (error) {}
    };
    getSitters();
    getTargetFacility();
  }, []);

  useEffect(() => {
    if (targetFacility !== null) {
      setValue('name', targetFacility?.name);
      setValue('tax_id', targetFacility?.tax_id_url);
      setValue('rent_contract', targetFacility?.rent_contract_url);
      setValue('num_of_rooms', targetFacility?.num_of_rooms);
      setValue('setter_id', String(targetFacility?.setter?.id));
      setValue('space', targetFacility?.space);
    }
  }, [targetFacility]);

  const onSubmit = async (data) => {
    try {
      setLoadingFacility(true);
      const updateFacilityRes = await createFacility(
        `/facility/update/${param?.id}`,
        data,
        user?.token
      );
      if (updateFacilityRes?.status !== 200) {
        console.log(updateFacilityRes?.data?.message);
        setLoadingFacility(false);
        Store.addNotification({
          title: 'حدث خطأ ',
          message: updateFacilityRes?.data?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (updateFacilityRes?.status === 200) {
        setLoadingFacility(false);

        Store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم تعديل بيانات المنشٍأة',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
        history.push('/facilities');
      }
    } catch (error) {
      setLoadingFacility(false);
      Store.addNotification({
        title: 'حدث خطأ ',
        message: error?.response?.data?.message || 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };

  return (
    <>
      <Form
        className="add-Selles-form flex justify-center items-center"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}>
        <div className="form-body add_form_body">
          <div className="form-field-wrapper">
            <AntdTextField
              name="name"
              type="text"
              placeholder="الاسم..."
              label="أسم المنشأة : "
              errorMsg={errors?.name?.message}
              validateStatus={errors?.name ? 'error' : ''}
              prefix={<EditOutlined />}
              control={control}
            />
          </div>

          <div className="form-field-wrapper">
            <AntdTextField
              name="space"
              type="text"
              placeholder="المساحة"
              label=" مساحة المنشأة : "
              errorMsg={errors?.space?.message}
              validateStatus={errors?.space ? 'error' : ''}
              prefix={<EditOutlined />}
              control={control}
            />
          </div>

          <div className="form-field-wrapper">
            <AntdTextField
              name="num_of_rooms"
              type="text"
              placeholder="عدد الغرف"
              label=" عدد الغرف : "
              errorMsg={errors?.num_of_rooms?.message}
              validateStatus={errors?.num_of_rooms ? 'error' : ''}
              prefix={<EditOutlined />}
              control={control}
            />
          </div>

          {/* <div className="form-field-wrapper">
            <AntdSelectOption
              name="setter_id"
              errorMsg={errors?.setter_id?.message}
              validateStatus={errors?.setter_id ? 'error' : ''}
              control={control}
              placeholder="اختر المربية"
              options={sittersOptions}
              label={
                <p className="select-option-label">
                  <EditOutlined />
                  المربية :
                </p>
              }
              formClassName="add-Selles-form"
            />
          </div> */}

          <div className=" form-field-wrapper ">
            <SelectSearchBox
              name="setter_id"
              errorMsg={errors?.setter_id?.message}
              validateStatus={errors?.setter_id ? 'error' : ''}
              defaultValue=""
              control={control}
              setValue={setValue}
              dataSearch={sittersOptions}
              placeholder="أختر المربية"
              value={watch}
              label=""
            />
          </div>

          <div className="form-field-wrapper">
            <div className="flex gap-[25px] flex-col">
              <UploadQuestionFile
                // accept="image/png, image/jpg, image/jpeg, image/gif"
                maxFiles={1}
                name="rent_contract"
                setValue={setValue}
                watch={watch}
                imageTarget={Array.isArray(watch('rent_contract')) ? null : watch('rent_contract')}
                register={register}
                unregister={unregister}
                multiple={false}
                label={
                  <>
                    <EditOutlined />
                    صورة عقد الايجار
                  </>
                }
                errorMsg={errors?.rent_contract ? errors?.imarent_contractge?.message : ''}
              />

              <UploadQuestionFile
                // accept="image/png, image/jpg, image/jpeg, image/gif"
                maxFiles={1}
                name="tax_id"
                setValue={setValue}
                watch={watch}
                imageTarget={Array.isArray(watch('tax_id')) ? null : watch('tax_id')}
                register={register}
                unregister={unregister}
                multiple={false}
                label={
                  <>
                    <EditOutlined />
                    صورة الرقم الضريبي
                  </>
                }
                errorMsg={errors?.tax_id ? errors?.tax_id?.message : ''}
              />
            </div>
          </div>

          <Button
            className="submit-btn mx-auto mt-[30px]  !h-[45px] !w-[100px]   !flex justify-center items-center"
            htmlType="submit"
            type="primary"
            icon={<SendOutlined />}
            loading={loadingFacility}>
            اضافة
          </Button>
        </div>
      </Form>
    </>
  );
};

export default UpdateFacility;

import axios from 'axios';

const getRoom = async (token, id) => {
  try {
    const getRoomRes = await axios.get(`/rooms/show/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token
      }
    });

    return getRoomRes;
  } catch (error) {
    return error?.response;
  }
};

export default getRoom;

import React, { createContext, useReducer ,useState} from 'react';
import { setUser, removeUser, setUserToStateAction } from './user.actions';
import userReducer from './user.reducer';
import Cookies from 'js-cookie';


const getUserFromSession = () => {
  if (sessionStorage.getItem('currentUser')) {
    return JSON.parse(sessionStorage.getItem('currentUser'));
  } else if (Cookies.get('currentUser')) {
    return JSON.parse(Cookies.get('currentUser'));
  }
  return null;
};

const INITIAL_STATE = {
  loggedIn: Cookies.get('currentUser') || sessionStorage.getItem('currentUser') ? true : false,
  user: getUserFromSession()
};

const contextInitialState = {
  ...INITIAL_STATE,
  userFetchCount: 0,
  setUserFetchCount: (count) => {},
  loadingUser: false,
  setLoadingUser: (load) => {},
  userDetails : null,
  setUserDetails: (user) =>{},
  showDetails:false,
  setShowDetails:()=>{},
  setUserToState: (user) => {},
  setCurrentUser: (user) => {},
  removeCurrentUser: () => {},
  viewEditUser:false,
  setViewEditUser:() => {}
};

const UesrContext = createContext(contextInitialState);

export const UserProvider = ({ children }) => {
  const [reducerState, dispatch] = useReducer(userReducer, INITIAL_STATE);
  const { user, loggedIn } = reducerState;
  const  [userDetails , setUserDetails] = useState(contextInitialState.userDetails)
  const [userFetchCount, setUserFetchCount] = useState(contextInitialState.userFetchCount);
  const [showDetails ,setShowDetails] = useState(contextInitialState.showDetails)
  const [loadingUser , setLoadingUser] = useState(contextInitialState.loadingUser)
  const [viewEditUser,setViewEditUser] = useState(contextInitialState.viewEditUser)
  const setCurrentUser = (cUser) => dispatch(setUser(cUser));
  const setUserToState = (u) => dispatch(setUserToStateAction(u));
  const removeCurrentUser = () => dispatch(removeUser());


  return (
    <UesrContext.Provider
      value={{
        loggedIn,
        user,
        userFetchCount,
        setUserFetchCount,
        setLoadingUser,
        loadingUser,
        setUserToState,
        setCurrentUser,
        userDetails,
        setUserDetails,
        removeCurrentUser,
        showDetails,
        setShowDetails,
        viewEditUser,
        setViewEditUser
      }}>
      {children}
    </UesrContext.Provider>
  );
};

export default UesrContext;

import axios from 'axios';

const getSettingsApi = async (token) => {
  try {
    const getSettings = await axios.get('/Setting/getSetting', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token
      }
    });
    return getSettings;
  } catch (error) {
    
  }
};

export default getSettingsApi;

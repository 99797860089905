import { useContext } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Descriptions, Spin } from 'antd';
import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import getAdmin from '../../apis/admin-api/getAdmin';
import UesrContext from '../../contexts/user-context/UserProvider';
import AdminContext from '../../contexts/admin-context/AdminProvider';

const AdminDetails = () => {
  const { user } = useContext(UesrContext);
  const param = useParams();
  const history = useHistory();

  const [targetAdmin, setTargetAdmin] = useState(null);
  const { loadingAdmin, setLoadingAdmin } = useContext(AdminContext);

  useEffect(() => {
    const getTargetAdmin = async () => {
      setLoadingAdmin(true);
      try {
        const targetAdminRes = await getAdmin(user?.token, param?.id);

        if (targetAdminRes?.status === 200 && targetAdminRes?.data?.admin) {
          setLoadingAdmin(false);
          setTargetAdmin(targetAdminRes?.data?.admin);
        }
      } catch (error) {
        throw error;
      }
    };

    getTargetAdmin();
  }, []);

  return (
    <>
      {loadingAdmin ? (
        <div className="mx-auto mt-[100px] w-[100px]">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className="flex flex-row justify-between">
            <h2 className="text-[20px] ">بيانات المسؤل : </h2>
            <Button
              onClick={() => history.push('/admins')}
              icon={<CloseOutlined />}
              size="small"></Button>
          </div>

          <div className="show_details">
            <div>
              <Descriptions bordered column={1}>
                {targetAdmin?.name && (
                  <Descriptions.Item label="الأسم">{targetAdmin?.name}</Descriptions.Item>
                )}
                {targetAdmin?.phone && (
                  <Descriptions.Item label="رقم الهاتف">{targetAdmin?.phone}</Descriptions.Item>
                )}
                {targetAdmin?.email && (
                  <Descriptions.Item label="البريد الالكتروني">
                    {targetAdmin?.email}
                  </Descriptions.Item>
                )}
                {targetAdmin?.address && (
                  <Descriptions.Item label=" العنوان">{targetAdmin?.address}</Descriptions.Item>
                )}
                {targetAdmin?.phone_code && (
                  <Descriptions.Item label="كود الهاتف">
                    {targetAdmin?.phone_code}
                  </Descriptions.Item>
                )}
                {targetAdmin?.national_id && (
                  <Descriptions.Item label="رقم الهوية">
                    {targetAdmin?.national_id}
                  </Descriptions.Item>
                )}
                {targetAdmin?.date_of_birth && (
                  <Descriptions.Item label="تاريخ الميلاد">
                    {targetAdmin?.date_of_birth}
                  </Descriptions.Item>
                )}
                {targetAdmin?.gender && (
                  <Descriptions.Item label="النوع">{targetAdmin?.gender}</Descriptions.Item>
                )}
                {targetAdmin?.role && (
                  <Descriptions.Item label="Role">{targetAdmin?.role}</Descriptions.Item>
                )}
                {targetAdmin?.nationality && (
                  <Descriptions.Item label="الجنسية">{targetAdmin?.nationality}</Descriptions.Item>
                )}
              </Descriptions>
            </div>

            <div>
              <Descriptions>
                {targetAdmin?.image_url && (
                  <Descriptions.Item>
                    <img src={targetAdmin?.image_url} alt="Admin image" className="w-full" />
                  </Descriptions.Item>
                )}
              </Descriptions>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AdminDetails;

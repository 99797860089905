import { DeleteOutlined, EditOutlined, InfoOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Tooltip } from 'antd';

import axios from 'axios';
import { useContext, useState } from 'react';
import { Store } from 'react-notifications-component';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import deleteOrdersApi from '../../apis/orders-api/deleteOrder';
import ChildrenContext from '../../contexts/children-context/ChildrenProvider';
import ParentsContext from '../../contexts/parents-context/ParentsProvider';
import UesrContext from '../../contexts/user-context/UserProvider';

const OrderColumns = (token, setFetchCount, setLoadingChildren, setSelectedServId) => {
  let history = useHistory();

  const { setChildrenModalOpened, setSelectedChildrenId, setSelectedChildren } =
    useContext(ChildrenContext);
  const { user } = useContext(UesrContext);

  const { setParentModalOpened, setSelectedParent, selectedParent } = useContext(ParentsContext);

  const { setViewChildrenModalOpened } = useContext(ChildrenContext);

  const handleEditChildren = (record) => {
    setChildrenModalOpened(true);
    setSelectedChildrenId(record.id);
    setSelectedChildren(record);
    //
  };
  const handleDeleteRequest = async (record) => {
    console.log(record.id);
    try {
      setLoadingChildren(true);
      const res = await deleteOrdersApi(record.id, token);
      if (res.status === 200 && res.data?.status_code === 200) {
        // console.log('.......', res);
        setFetchCount((prevState) => prevState + 1);
        setLoadingChildren(false);
        Store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم حذف الطلب بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        setLoadingChildren(false);
        Store.addNotification({
          title: 'حدث خطأ ',
          message: res?.data?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      setLoadingChildren(false);
      Store.addNotification({
        title: 'حدث خطأ ',
        message: 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };

  const handleViewRequest = (data) => {
    // setviewModalData(data)
    // setViewDetailsModal(true)
    setViewChildrenModalOpened(true);
    setSelectedChildren(data);
  };

  const getSingleParent = async (id) => {
    try {
      let res = await axios.get(`/Parent/single?id=${id}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'applications/json',
          Authorization: 'Bearer ' + user.token
        }
      });

      setSelectedParent(res.data.data);
    } catch (error) {}
  };

  const handleViewParentRequest = (data) => {
    setParentModalOpened(true);
    getSingleParent(data.parent_id);
  };

  const [ViewDetailsModal, setViewDetailsModal] = useState(false);
  const [viewModalData, setviewModalData] = useState(null);
  return [
    {
      title: '#',
      dataIndex: 'id',
      width: 62
    },
    {
      title: ' من تاريخ',
      dataIndex: 'date',
      width: 192
    },
    {
      title: 'الوقت',
      dataIndex: 'time',
      width: 192
    },
    {
      title: ' عدد الأيام',
      dataIndex: 'days',
      width: 192
    },
    {
      title: 'عدد الساعات',
      dataIndex: 'hours',
      width: 192
    },
    {
      title: 'نشاط الطلب',
      dataIndex: 'order_activity',
      width: 192
    },

    {
      title: 'الحالة',
      dataIndex: 'status',
      width: 192
    },
    // {
    //   title: 'صورة الطلب',
    //   dataIndex: 'image_url',
    //   width: 180,
    //   render: (_, record) => {
    //     return (
    //       <div className="service-img flex justify-center items-center ">
    //         <img src={record.image_url} alt={record.image} width="200" />
    //       </div>
    //     );
    //   }
    // },
    // {
    //   title: ' تاريخ الميلاد',
    //   dataIndex: 'date_of_birth',
    //   width: 180
    // },
    // {
    //   title: 'النوع',
    //   dataIndex: 'gender',
    //   width: 82
    // },
    // {
    //   title: 'الجنسية',
    //   dataIndex: 'nationality',
    //   width: 82
    // },
    {
      title: 'تفاصيل الطلب ',
      dataIndex: 'view',
      width: 92,
      render: (_, record) => {
        return (
          <>
            <Button
              size="large"
              type="dashed"
              shape="circle"
              icon={<InfoOutlined />}
              onClick={(key) => history.push(`/order/show/${record?.id}`)}></Button>
          </>
        );
      }
    },
    {
      title: 'تعديل الطلب',
      dataIndex: 'edit',
      width: 92,
      render: (_, record) => {
        return (
          <Tooltip title="تعديل الطلب">
            <Button
              className="edit-btn"
              onClick={(key) => history.push(`/order/update/${record?.id}`)}
              size="large"
              type="dashed"
              shape="circle"
              icon={<EditOutlined />}
            />
          </Tooltip>
        );
      }
    },
    // {
    //   title: 'الدوله',
    //   dataIndex: 'country',
    //   width: 180,
    //   render: (_, record) => {
    //     if (record.country) {
    //       return record.country.name;
    //     }
    //     return '';
    //   }
    // },
    // {
    //   title: 'النوع',
    //   dataIndex: 'gender',
    //   width: 82,
    //   render: (_, record) => {
    //     if (record.gender === 1) {
    //       return 'ذكر';
    //     } else {
    //       return 'مؤنث';
    //     }
    //   }
    // },

    // {
    //   title: 'تاريخ الميلاد',
    //   dataIndex: 'date_of_birth',
    //   width: 82
    // },
    // {
    //   title: 'تفاصيل الطلب ',
    //   dataIndex: 'view',
    //   width: 92,
    //   render: (_, record) => {
    //     return (
    //       <>
    //         <Button
    //           size="large"
    //           type="dashed"
    //           shape="circle"
    //           icon={<EyeOutlined />}
    //           onClick={() => handleViewRequest(record)}></Button>

    //         <Modal
    //           cancelButtonProps={{ style: { display: 'none' } }}
    //           okText="موافق"
    //           cancelText=""
    //           title="  تفاصيل الطلب "
    //           className="show_alldetails_modal"
    //           centered
    //           open={ViewDetailsModal}
    //           onOk={() => setViewDetailsModal(false)}
    //           onCancel={() => setViewDetailsModal(false)}>
    //           <table className="show_alldetails_table">
    //             <tbody>
    //               <tr>
    //                 <td>الاسم</td>
    //                 <td>{viewModalData?.username}</td>
    //               </tr>
    //               <tr>
    //                 <td>الصورة</td>
    //                 <td>
    //                   <img style={{ width: '150px' }} src={viewModalData?.image} />
    //                 </td>
    //               </tr>
    //               <tr>
    //                 <td>رقم الهاتف</td>
    //                 <td>{viewModalData?.phone}</td>
    //               </tr>
    //               <tr>
    //                 <td>البريد الالكتروني</td>
    //                 <td>{viewModalData?.email}</td>
    //               </tr>
    //               <tr>
    //                 <td>النوع</td>
    //                 <td>{viewModalData?.type === 1 ? 'ذكر' : 'انثي'}</td>
    //               </tr>
    //               <tr>
    //                 <td>الدولة</td>
    //                 <td>{viewModalData?.country.name}</td>
    //               </tr>
    //               <tr>
    //                 <td>تاريخ الميلاد</td>
    //                 <td>{viewModalData?.date_of_birth ? viewModalData.date_of_birth : 'لايوجد'}</td>
    //               </tr>
    //             </tbody>
    //           </table>
    //         </Modal>
    //       </>
    //     );
    //   }
    // },
    {
      title: 'حذف الطلب',
      dataIndex: 'delete',
      width: 92,
      render: (_, record) => {
        return (
          <Popconfirm
            title={
              <div style={{ maxWidth: '200px' }}>
                <p>هل انت متأكد من حذف الطلب</p>
                {/* <p>حذف الخدمة سيؤدى الى حذف جميع المشاريع والاسئلة الخاصة بالخدمة</p> */}
              </div>
            }
            okText="نعم"
            cancelText="لا"
            onConfirm={(key) => handleDeleteRequest(record)}>
            <Tooltip title="حذف الطلب؟">
              <Button
                className="delete-btn"
                size="large"
                type="dashed"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        );
      }
    }
  ];
};

export default OrderColumns;

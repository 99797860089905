import { useEffect, useContext, useState } from 'react';
import UsersTable from './usersTable';

const UsersPage = () => {

  return(
   <>

     <div className='overflow-x-auto'>
       <UsersTable />
     </div>
   
   </>
   )
};

export default UsersPage;

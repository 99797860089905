import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import TicketsThreadsTable from './TicketsThreadsTable';

const TicketThreadsPage = () => {
  let history = useHistory();
  let param = useParams();

  // const { DriverModalOpened, setDriverModalOpened, setSelectedServId, setSelectedDriver, selectedDriver } =
  //   useContext(OrderContext);

  return (
    <>
      <div>
        <Button
          onClick={() => {
            history.push(`/ticket/ticket_threads/${param?.target_id}/create`);
          }}
          className="add-serv-btn"
          type="primary"
          icon={<PlusOutlined />}>
          <span className="btn-text">اضافة </span>
        </Button>
        <TicketsThreadsTable />
      </div>
    </>
  );
};

export default TicketThreadsPage;

import React, { useContext, useEffect } from 'react';
import SupportsContext from '../../contexts/supports-context/SupportsProvider';
// import UesrContext from '../../contexts/user-context/UserProvider';

const SupportsModel = () => {
  // const { user } = useContext(UesrContext);
  const { selectedServId } = useContext(SupportsContext);
  useEffect(() => {
    
  }, [selectedServId]);

  const renderexperienceCertificatesTitle = () => {
    if (selectedServId?.experienceCertificates?.length > 0) {
      return <h2>شهادات الخبره</h2>;
    }
  };
  const renderexperienceCertificatesTable = () => {
    if (selectedServId?.experienceCertificates?.length > 0) {
      return selectedServId.experienceCertificates.map((item) => (
        <div key={item.id}>
          <img
            src={item.file}
            key={item.id}
            alt="Experience Certificates"
            height="200"
            className="image-data"
          />
        </div>
      ));
    }
  };

  const renderroomImagesTitle = () => {
    if (selectedServId?.roomImages?.length > 0) {
      return <h2>صور الغرفه</h2>;
    }
  };
  const renderroomImagesTable = () => {
    if (selectedServId?.roomImages?.length > 0) {
      return selectedServId.roomImages.map((item) => (
        <div key={item.id}>
          <img
            className="image-data"
            src={item.image}
            key={item.id}
            alt="Experience Certificates"
            height="200"
          />
        </div>
      ));
    }
  };

  const renderfilesTitle = () => {
    if (selectedServId?.files?.length > 0) {
      return <h2>الوثائق</h2>;
    }
  };
  const renderfilesTable = () => {
    if (selectedServId?.files?.length > 0) {
      return selectedServId.files.map((item) => (
        <div key={item.id}>
          <div>
            <h3>عقد الإيجار</h3>
            <img
              className="image-data"
              src={item.lease}
              key={item.id}
              alt="Experience Certificates"
              height="200"
            />
          </div>
          <div>
            <h3>صوره الاقامه </h3>
            <img
              className="image-data"
              src={item.residency}
              key={item.id}
              alt="Experience Certificates"
              height="200"
            />
          </div>
        </div>
      ));
    }
  };

  return (
    <div>
      <div>{renderexperienceCertificatesTitle()}</div>
      <div className="data-view">{renderexperienceCertificatesTable()}</div>
      <div>{renderroomImagesTitle()}</div>
      <div className="data-view">{renderroomImagesTable()}</div>
      <div>{renderfilesTitle()}</div>
      <div className="data-view">{renderfilesTable()}</div>
    </div>
  );
};

export default SupportsModel;

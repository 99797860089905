import axios from 'axios';

const changeStatusSitterApi = async (SitterId, status, token) => {
  try {
    const formData = new FormData();
    formData.append('receive_order', status);

    const response = await axios.post(`/setters/update/${SitterId}`, formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    return e?.response;
  }
};

export default changeStatusSitterApi;

import axios from 'axios';

const getAdmin = async (token, id) => {
  try {
    const getAdminRes = await axios.get(`/admins/show/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token
      }
    });
    console.log(getAdminRes);
    return getAdminRes;
  } catch (error) {
    return error?.response;
  }
};

export default getAdmin;

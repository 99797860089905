import { LockOutlined, LoginOutlined, PhoneOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form } from 'antd';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Store } from 'react-notifications-component';
import * as Yup from 'yup';
import { signinApi } from '../../apis/auth/signin';
import AntdCheckbox from '../../common/antd-form-components/AntdCheckbox';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import Logo from '../../common/logo/Logo';
import UesrContext from '../../contexts/user-context/UserProvider';
import './LoginForm.scss';

const schema = Yup.object().shape({
  // name: Yup.string().required('من فضلك ادخل اســمك')
  password: Yup.string().required('من فضلك ادخل الرقم السرى'),
  phone: Yup.string().required('من فضلك ادخل رقم التلفون')
});

const LoginForm = () => {
  const { i18n } = useTranslation();
  const { setCurrentUser, setUserToState, user } = useContext(UesrContext);
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      // name: '',
      password: '',
      phone: '',
      remember: true
    }
  });
  const onSubmit = async (data) => {
    try {
      const res = await signinApi(data, i18n.language);
      console.log(res.data);
      // if (res.status !== 200) {\
      // console.log(res.data.status_code);
      if (res.status === 200 && res.data.status_code !== 200 && res.data.status_code) {
        console.log(res.status);
        Store.addNotification({
          title: 'حدث خطأ اثناء الدخول',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate_animated', 'animate_fadeIn'],
          animationOut: ['animate_animated', 'animate_fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
      // else {
      //   // console.log('first');
      //   if (data.remember) setCurrentUser({ ...res.data.user });
      //   else setUserToState({ ...res.data.user });
      // }
      // }
      else if (res.data.status_code == 200 && res?.data?.user) {
        if (data.remember) {
          setCurrentUser({ ...res.data.user });
          Store.addNotification({
            title: 'تمت العملية بنجاح',
            message: 'تم تسجيل الدخول',
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 2000,
              showIcon: true,
              onScreen: true
            }
          });
        } else {
          setUserToState({ ...res.data.user });
          Store.addNotification({
            title: 'تمت العملية بنجاح',
            message: 'تم تسجيل الدخول',
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 2000,
              showIcon: true,
              onScreen: true
            }
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [form] = Form.useForm();
  return (
    <Form className="login-form" form={form} layout="vertical" onFinish={handleSubmit(onSubmit)}>
      <div className="form-header">
        <Logo className="form-logo" />
        <p>تسجيل الدخول</p>
      </div>
      <div className="form-body">
        {/* <AntdTextField
          name="name"
          type="text"
          placeholder={'الاســـــم...'}
          // label="الاســــم"
          errorMsg={errors?.name?.message}
          validateStatus={errors?.name ? 'error' : ''}
          prefix={<UserOutlined />}
          control={control}
        /> */}
        <AntdTextField
          name="phone"
          type="text"
          placeholder={'ادخل رقم التلفون...'}
          // label="الرقــم الســرى"
          errorMsg={errors?.phone?.message}
          validateStatus={errors?.phone ? 'error' : ''}
          prefix={<PhoneOutlined />}
          control={control}
        />
        <AntdTextField
          name="password"
          type="password"
          placeholder={'الرقــم الســرى...'}
          // label="الرقــم الســرى"
          errorMsg={errors?.password?.message}
          validateStatus={errors?.password ? 'error' : ''}
          prefix={<LockOutlined />}
          control={control}
        />

        <AntdCheckbox name="remember" label="تذكرنى" control={control} />

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          icon={<LoginOutlined />}
          loading={isSubmitting}>
          تسجيل الدخول
        </Button>
        {/* <Link to={routerLinks.DataEntryLoginPage}>تسجيل الدخول ك مدخل بيانات</Link> */}
      </div>
    </Form>
  );
};

export default LoginForm;

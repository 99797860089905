import { EditOutlined } from '@ant-design/icons';
import { DatePicker, Form, Space } from 'antd';
import moment from 'moment';
import React from 'react';
import { useController } from 'react-hook-form';


const DatePickerBox = (
  { name,
    label,
    validateStatus,
    defaultValue,
    setValue, control,
    errorMsg, watch }) => {
  // const handleDateValue = () => {
  //   if (selectedObject && selectedObject[selectedDateKeyName]) {
  //     if (dateState) {
  //       return moment(dateState, 'YYYY-MM-DD');
  //     }
  //     return moment(selectedObject[selectedDateKeyName], 'YYYY-MM-DD');
  //   } else if (dateState) {
  //     return moment(dateState, 'YYYY-MM-DD');
  //   }
  //   return null;
  // };
  const {
    // field: { ...inputProps }
    field
  } = useController({
    name,
    control
  });
  // watch(name)
  // console.log(watch(name))
  return (

    <>
      <Form.Item label={label} {...field} help={errorMsg} validateStatus={validateStatus} >

        <Space direction="vertical" className=' !w-full !h-full py-[5px]'>
          <DatePicker className=' !w-full !h-full  !py-[9px]'
            // name={name} control={control}
            defaultValue={defaultValue?.name}
            value={watch(name) !== null ? moment(watch(name)) : ""}
            onChange={(date, dateString) => {
              if (dateString !== "") {
                setValue(name, dateString);
              }
              else {
                setValue(name, null);
              }
            }}

          />

        </Space>

      </Form.Item>

    </>

  );
}

export default DatePickerBox;

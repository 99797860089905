import axios from 'axios';

const deleteRoom = (id, token) => {
  try {
    const deleteRes = axios.delete(`/rooms/delete/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token
      }
    });

    return deleteRes;
  } catch (error) {
    return error?.response;
  }
};

export default deleteRoom;

import axios from 'axios';

const UpdateProfileApi = async (values, token) => {
  const {
    name,
    image,
    date_of_birth,
    email,
    gender,
    nationality,
    phone_code,
    phone,
    role,
    address,
    password,
    national_id
  } = values;
  try {
    const formData = new FormData();
    if (Array.isArray(image)) {
      formData.append('image', image[0]);
      console.log(image[0]);
    }
    formData.append('name', name);
    formData.append('email', email);
    formData.append('date_of_birth', date_of_birth);
    formData.append('gender', gender);
    formData.append('nationality', nationality);
    formData.append('phone_code', phone_code);
    formData.append('phone', phone);
    formData.append('role', role);
    formData.append('address', address);
    formData.append('password', password);
    formData.append('national_id', national_id);

    const updateAdminRes = axios.post(`/admins/update_profile`, formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token
      }
    });

    console.log(updateAdminRes);

    return updateAdminRes;
  } catch (error) {
    console.log(error.message);

    return error.message;
  }
};

export default UpdateProfileApi;

const routerLinks = {
  ////////////////////////////
  loginPage: '/',
  homePage: '/',

  ticketThreadsPage: '/ticket/ticket_threads/:target_id',
  createTicketThreadsPage: '/ticket/ticket_threads/:target_id/create',
  // ticketsShow: '/ticket/show/:id',
  // ticketsCreate: '/ticket/create',
  // ticketsUpdate: '/ticket/update/:id',
  ticketsPage: '/tickets',
  ticketsShow: '/ticket/show/:id',
  ticketsCreate: '/ticket/create',
  ticketsUpdate: '/ticket/update/:id',
  ordersPage: '/orders',
  orderShow: '/order/show/:id',
  orderCreate: '/order/create',
  orderUpdate: '/order/update/:id',
  transactionsPage: '/transactions',
  transactionsShow: '/transactions/show/:id',
  transactionsCreate: '/transactions/create',
  transactionsUpdate: '/transactions/update/:id',
  certificatePage: '/certificates',

  certificateCreate: '/certificates/create',
  certificateShow: '/certificates/show/:id',
  certificateUpdate: '/certificates/update/:id',
  driverPage: '/driver',

  driverShow: '/driver/show/:id',
  driverCreate: '/driver/create',
  driverUpdate: '/driver/update/:id',
  projectsPage: '/projects',
  projectPage: '/projects/:projectId',
  CityPage: '/City',
  AreaPage: '/Area',
  CountaryPage: '/countary',
  createCountery: '/countary/create',
  updateCountery: '/countary/update/:id',
  ChildrenPage: '/Children',
  ChildrenShow: '/Children/show/:id',
  ChildrenCreate: '/Children/create',
  ChildrenUpdate: '/Children/update/:id',
  supportsPage: '/supports',
  sittersPage: '/sitters',
  sitterView: '/sitters/view/:id',
  sitterUpdate: '/sitters/update/:id',
  createSitter: '/sitters/create',
  parentPage: '/parents',
  ParentsCreate: '/parent/create',
  ParentShow: '/parent/show/:id',
  ParentsUpdate: '/parent/update/:id',
  SettingsPage: '/Settings',
  ProfilePage: '/Profile',
  ContactUsPage: '/ContactUs',

  DataEntryLoginPage: '/data-entry-login',
  addSitterDataEntry: '/add-sitter',
  usersPage: '/users',
  updateUser: '/users/update/:id',
  viewUser: '/users/view/:id',

  adminPage: '/admins',
  updateAdmin: '/admins/update/:id',
  viewAdmin: '/admins/view/:id',
  createAdmin: '/admins/create',

  roomsPage: '/rooms',
  createRoom: '/rooms/create',
  roomDetails: '/rooms/show/:id',
  updateRoom: '/rooms/update/:id',

  falilityPage: '/facilities',
  createFacility: '/facilities/create',
  facilityDetails: '/facilities/show/:id',
  facilityUpdate: '/facilities/update/:id',

  ratesPage: '/rates',
  rateDetails: '/rates/show/:id',
  ////////////////////////////////
  notFound: '/not-found'
};

export default routerLinks;

import axios from 'axios';

const createRoom = async (url, values, token) => {
  const { name, setter_id, facility_id } = values;
  try {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('setter_id', setter_id);
    formData.append('facility_id', facility_id);
    const createRoomRes = axios.post(url, formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token
      }
    });

    return createRoomRes;
  } catch (error) {
    return error?.response;
  }
};

export default createRoom;

import { Table } from 'antd';
import React, { useContext, useEffect } from 'react';
import getAllSupports from '../../apis/supports-api/getAllSupports';
import SupportsContext from '../../contexts/supports-context/SupportsProvider';
import UesrContext from '../../contexts/user-context/UserProvider';
import SupportsColumns from './SupportsColumns';

const SupportsTable = () => {
  const { user } = useContext(UesrContext);
  const {
    fetchCount,
    setFetchCount,
    loadingSupports,
    setLoadingSupports,
    allSupports,
    setAllSupports,
    setSupportModalOpened,
    setSelectedServId
  } = useContext(SupportsContext);

  useEffect(() => {
    const getSupports = async () => {
      try {
        setLoadingSupports(true);
        const getSupportsRes = await getAllSupports(user?.token);
        
        if (getSupportsRes.status === 200 && getSupportsRes?.data?.data) {
          setAllSupports(getSupportsRes.data.data);
          setLoadingSupports(false);
        }
      } catch (error) {
        setLoadingSupports(false);
        
      }
    };
    getSupports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCount]);
  return (
    <Table
      pagination={{ pageSize: 5 }}
      className="Orders-table"
      dataSource={allSupports}
      columns={SupportsColumns(
        user?.token,
        setFetchCount,
        setLoadingSupports,
        setSupportModalOpened,
        setSelectedServId
      )}
      loading={loadingSupports}
      scroll={{ x: 1200 }}
    />
  );
};

export default SupportsTable;

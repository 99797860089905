import axios from 'axios';

const createAdmin = async (url, values, token) => {
  const {
    name,
    image,
    date_of_birth,
    email,
    gender,
    nationality,
    phone_code,
    phone,
    role,
    address,
    national_id,
    password
  } = values;
  try {
    const formData = new FormData();
    if (Array.isArray(image)) {
      formData.append('image', image[0]);
    }
    formData.append('name', name);
    formData.append('email', email);
    formData.append('date_of_birth', date_of_birth);
    formData.append('gender', gender);
    formData.append('nationality', nationality);
    formData.append('phone_code', phone_code);
    formData.append('phone', phone);
    formData.append('role', role);
    formData.append('address', address);
    formData.append('national_id', national_id);
    formData.append('password', password);

    const createAdminRes = axios.post(url, formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token
      }
    });

    console.log(createAdminRes);

    return createAdminRes;
  } catch (error) {
    return error?.response;
  }
};

export default createAdmin;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useContext } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import { MainAppBarProvider } from "../../contexts/main-app-bar-context/MainAppBarProvider";
import Loading from '../../common/loading/Loading';
import Routes from './Routes';
import '../../i18n';
import AppLayout from './Layout';
import { useEffect } from 'react';
import axios from 'axios';
import { ConfigProvider } from 'antd';
import LoginPage from '../../pages/login-page/LoginPage';
import routerLinks from './routerLinks';
import NotFoundPage from '../../pages/not-found-page/NotFoundPage';
import UesrContext from '../../contexts/user-context/UserProvider';
import { ReactNotifications } from 'react-notifications-component';
import LoginPageDataEntry from '../../pages/login-page data entry/LoginPageDataEntry';
import AddSitter from '../../pages/add sitter/AddSitter';
import DataEntryUesrContext from '../../contexts/data-entry-user-context/DataEntryUserProvider';
import DataEntryLayout from './DataEntryLayout';
import { GoogleMapProvider } from '../../contexts/google-map-context/GoogleMapProvider';

// axios.defaults.baseURL = 'https://api.haleemh.sa/apiAdmin';
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

function App() {
  const { i18n } = useTranslation();

  const history = useHistory();

  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n.dir()]);

  const { loggedIn } = useContext(UesrContext);
  const { loggedInDataEntry } = useContext(DataEntryUesrContext);

  useEffect(() => {
    if (loggedInDataEntry) {
      history.push(routerLinks.addSitterDataEntry);
    }
  }, []);

  return (
    <div className={`app app-${i18n.dir()}`}>
      <Suspense fallback={<Loading />}>
        <ConfigProvider direction={i18n.dir()}>
          <ReactNotifications className={i18n.dir()} />
          <Switch>
            {!loggedInDataEntry ? (
              <Route exact path={routerLinks.DataEntryLoginPage}>
                <LoginPageDataEntry />
              </Route>
            ) : (
              <DataEntryLayout>
                <Switch>
                  <GoogleMapProvider>
                    <Route path={routerLinks.addSitterDataEntry} component={AddSitter} />
                  </GoogleMapProvider>
                  <Route path="*" component={NotFoundPage} />
                </Switch>
              </DataEntryLayout>
            )}
            {!loggedIn ? (
              <Route exact path={routerLinks.loginPage}>
                <LoginPage />
              </Route>
            ) : (
              <AppLayout>
                <Routes />
              </AppLayout>
            )}
            {/* <AppLayout>
              <Routes />
            </AppLayout> */}
            <Route path="*" component={NotFoundPage} />
          </Switch>
        </ConfigProvider>
      </Suspense>
    </div>
  );
}

export default App;

import React, { useContext, useEffect, useState } from 'react';
import CitysContext from '../../contexts/City-context/CityProvider';
import CityTable from './CityTable';
import { Modal } from 'antd';
import CitysModel from './CitysModel';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import './CityPage.scss';
import AreaPage from '../area-page/AreaPage';
import getAllCountarys from '../../apis/countary-api/getAllcountaries';
import UesrContext from '../../contexts/user-context/UserProvider';
import CountarysContext from '../../contexts/countary-context/CountaryProvider';
import AreasContext from '../../contexts/area-context/AreaProvider';
import AreasModel from '../area-page/AreasModel';
const CityPage = () => {
  const {
    CityModalOpened,
    setCityModalOpened,
    ShowAreaModalOpened,
    setShowAreaModalOpened,
    setSelectedCityId,
    setSelectedCity,
    selectedCityId
  } = useContext(CitysContext);
  const { user } = useContext(UesrContext);

  const { AreaModalOpened,selectedAreaId, setAreaModalOpened, setSelectedAreaId, setSelectedArea } =
  useContext(AreasContext);

  const { setAllCountarys } = useContext(CountarysContext);
  const [loadingCountarys, setLoadingCountarys] = useState(false);
  const [allCountries, setAllCountries] = useState();

  const getCountarys = async () => {
    try {
      setLoadingCountarys(true);
      const getCountarysRes = await getAllCountarys(user?.token);

      if (getCountarysRes.status === 200 && getCountarysRes?.data?.data) {
        setAllCountries(getCountarysRes.data.data);
        setAllCountarys(getCountarysRes.data.data)
        setLoadingCountarys(false);
      }
    } catch (error) {
      setLoadingCountarys(false);

    }
  };

  useEffect(() => {

    getCountarys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




  return (
    <div className="Citys-page">
      <div className="new-filter-wrap">
        <Button
          onClick={() => setCityModalOpened(true)}
          className="add-serv-btn"
          type="primary"
          icon={<PlusOutlined />}>
          <span className="btn-text">اضافة مدينه</span>
        </Button>

        {/* <CitysFilter /> */}
      </div>
      <CityTable  />
      {CityModalOpened && (
        <Modal
          className="add-City-modal"
          width="90%"
          style={{ maxWidth: '900px' }}
          title={setSelectedCityId ? 'اضـافة مدينه جديدة' : 'تعديل المدينه '}
          open={CityModalOpened}
          onOk={() => {
            setCityModalOpened(false);
            setSelectedCityId('');
            setSelectedCity(null);
          }}
          onCancel={() => {
            setCityModalOpened(false);
            setSelectedCityId('');
            setSelectedCity(null);
          }}
          footer={false}>
          <CitysModel allCountries={allCountries} />
        </Modal>
      )}

      {ShowAreaModalOpened && (
        <Modal
          className="add-Area-modal"
          width="90%"
          style={{ maxWidth: '900px' }}
          title={'المناطق'}
          open={ShowAreaModalOpened}
          onOk={() => {
            setShowAreaModalOpened(false);
            setSelectedCityId('');
            setSelectedCity(null);
          }}
          onCancel={() => {
            setShowAreaModalOpened(false);
            setSelectedCityId('');
            setSelectedCity(null);
          }}
          footer={false}>
          <AreaPage selectedCityId={selectedCityId} />
        </Modal>
      )}

      
    </div>
  );
};

export default CityPage;

import { Button, Popconfirm, Tooltip } from 'antd';
// import { DeleteOutlined, EditOutlined, LinkOutlined } from '@ant-design/icons';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { Store } from 'react-notifications-component';
import deleteCountaryApi from '../../apis/countary-api/deleteCountaryApi';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const CountarysColumns = (
  user,
  setFetchCount,
  setLoadingCountarys,
  setCountaryModalOpened,
  setShowCityModalOpened,
  setSelectedServId
) => {
  const history = useHistory();
  /*   const handleEditCountary = (key) => {
    setCountaryModalOpened(true);
    setSelectedServId(key);

    //
  }; */

  // const handleShowCityCountary = (key) => {
  //   setShowCityModalOpened(true);
  //   setSelectedServId(key);

  //   //
  // };
  const handleDeleteRequest = async (key) => {
    try {
      setLoadingCountarys(true);
      const res = await deleteCountaryApi(key, user?.token);
      if (res.status === 200) {
        setFetchCount((prevState) => prevState + 1);
        setLoadingCountarys(false);
        Store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم الحذف بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        setLoadingCountarys(false);
        Store.addNotification({
          title: 'حدث خطأ ',
          message: res?.data?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      setLoadingCountarys(false);
      Store.addNotification({
        title: 'حدث خطأ ',
        message: 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };
  return [
    {
      title: '#',
      dataIndex: 'key',
      width: 62
    },
    {
      title: 'الدولة',
      dataIndex: 'name',
      width: 110,
      render(_, record) {
        return record?.name;
      }
    },
    {
      title: 'الجنسية',
      dataIndex: 'nationality',
      width: 110,
      render(_, record) {
        return record?.nationality;
      }
    },
    {
      title: 'الكود',
      dataIndex: 'code',
      width: 110,
      render(_, record) {
        return record?.code;
      }
    },
    // {
    //   title: 'مدن البلد',
    //   dataIndex: 'city',
    //   width: 92,
    //   render: (_, record) => {
    //     return (
    //       // <RouterLink
    //       //   className="projects-link"
    //       //   to={{
    //       //     pathname: routerLinks.CityPage,
    //       //     state: { CountryId: record.key }
    //       //   }}>
    //       <Tooltip title="مدن الدوله">
    //         <Button
    //           className="projects-btn"
    //           size="large"
    //           type="dashed"
    //           shape="circle"
    //           onClick={(key) => handleShowCityCountary(record.id)}
    //           icon={<LinkOutlined />}
    //         />
    //       </Tooltip>
    //       // </RouterLink>
    //     );
    //   }
    // },
    {
      title: 'تعديل الدولة',
      dataIndex: 'edit',
      width: 92,
      render: (_, record) => {
        return (
          <Tooltip title="تعديل الدوله">
            <Button
              className="edit-btn"
              onClick={() => history.push(`/countary/update/${record.id}`)} //
              size="large"
              type="dashed"
              shape="circle"
              icon={<EditOutlined />}
            />
          </Tooltip>
        );
      }
    },
    {
      title: 'حذف الدوله',
      dataIndex: 'delete',
      width: 92,
      render: (_, record) => {
        return (
          <Popconfirm
            title={
              <div style={{ maxWidth: '200px' }}>
                <p>هل انت متأكد من حذف الدوله</p>
                {/* <p>حذف البلد سيؤدى الى حذف جميع المشاريع والاسئلة الخاصة بالبلد</p> */}
              </div>
            }
            okText="نعم"
            cancelText="لا"
            onConfirm={(key) => handleDeleteRequest(record.id)}>
            <Tooltip title="حذف الدوله">
              <Button
                className="delete-btn"
                size="large"
                type="dashed"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        );
      }
    }
  ];
};

export default CountarysColumns;

import { Modal, Select } from 'antd';
import React, { useState } from 'react';
import SittersModel from './SittersModel';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import SittersTable from './SittersTable';
import './SittersPage.scss';

const SittersPage = () => {
  const [filterValue, setFilterValue] = useState('all');

  const onFilterChange = (value) => {
    setFilterValue(value);
  };

  let history = useHistory();

  return (
    <div>
      <div className="flex flex-row justify-start items-center mb-[40px] gap-[20px]">
        <Button
          onClick={() => {
            history.push('/sitters/create');
          }}
          className="add-serv-btn m-0"
          type="primary"
          icon={<PlusOutlined />}>
          <span className="btn-text">اضافة مربيه</span>
        </Button>

        <div className="w-[140px] ">
          <Select
            defaultValue="all"
            optionFilterProp="children"
            onChange={onFilterChange}
            options={[
              {
                value: 'all',
                label: 'الكل'
              },
              {
                value: 'accepted',
                label: 'المقبولين'
              },
              {
                value: 'rejected',
                label: 'المرفوضين '
              }
              /* {
                value: 'under',
                label: 'تحت المراجعه ',
              }, */
            ]}
          />
        </div>
      </div>

      <div className="overflow-x-auto">
        <SittersTable filterValue={filterValue} />
      </div>

      {/*   {SitterModalOpened && (
        <Modal
          className="add-Sitter-modal"
          width="90%"
          style={{ maxWidth: '900px' }}
          title={setSelectedServId.type === 1 ? 'تفاصيل الحضانه' : 'تفاصيل الحاضنه'}
          open={SitterModalOpened}
          onOk={() => {
            setSitterModalOpened(false);
            setSelectedServId('');
            setSelectedSitter(null);
          }}
          onCancel={() => {
            setSitterModalOpened(false);
            setSelectedServId('');
            setSelectedSitter(null);
          }}
          footer={false}>
          <SittersModel />
        </Modal>
      )} */}
    </div>
  );
};

export default SittersPage;

import axios from 'axios';

const createArea = async (url, values, token) => {
  try {
    const { name_ar, name_en,city_id } = values;
    const pickedValues = {
      name_ar,
      name_en

      // image: Area_imgs ? Area_imgs[0] : null
    };
    const formData = new FormData();
    for (let key in pickedValues) {
      formData.append(key, pickedValues[key]);
    }
    formData.append('city_id', city_id);
    const createSerivceRes = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      }
    });
    return createSerivceRes;
  } catch (error) {
    
  }
};

export default createArea;

import axios from 'axios';

const getAllCountarys = async (token) => {
  try {
    const getCountarysRes = await axios.post(
      '/country/index',
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'applications/json',
          Authorization: 'Bearer ' + token
        }
      }
    );
    return getCountarysRes;
  } catch (error) {
    return error.massage;
  }
};

export default getAllCountarys;

import axios from 'axios';

const getAllSupports = async (token) => {
  try {
    const getSupportsRes = await axios.get('/Support/all', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token
      }
    });
    return getSupportsRes;
  } catch (error) {
    
  }
};

export default getAllSupports;

import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => {},
  loadingSitters: false,
  setLoadingSitters: (load) => {},
  allSitters: {},
  setAllSitters: (Sitters) => {},
  SitterModalOpened: false,
  setSitterModalOpened: (v) => {},
  selectedServId: '',
  setSelectedServId: (id) => {},
  selectedSitter: null,
  setSelectedSitter: (servKey) => {},
};

const SittersContext = createContext(INITIAL_VALUES);

export const SittersProvider = ({ children }) => {
  const [allSitters, setAllSitters] = useState(INITIAL_VALUES.allSitters);
  const [loadingSitters, setLoadingSitters] = useState(INITIAL_VALUES.loadingSitters);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [SitterModalOpened, setSitterModalOpened] = useState(INITIAL_VALUES.SitterModalOpened);
  const [selectedServId, setSelectedServId] = useState(INITIAL_VALUES.selectedServId);
  const [selectedSitter, setSelectedSitter] = useState(INITIAL_VALUES.selectedSitter);

  return (
    <SittersContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        loadingSitters,
        setLoadingSitters,
        allSitters,
        setAllSitters,
        SitterModalOpened,
        setSitterModalOpened,
        selectedServId,
        setSelectedServId,
        selectedSitter,
        setSelectedSitter
      }}>
      {children}
    </SittersContext.Provider>
  );
};

export default SittersContext;

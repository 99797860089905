import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => {},
  loadingChildren: false,
  setLoadingChildren: (load) => {},
  allChildren: [],
  setAllChildren: (services) => {},
  ChildrenModalOpened: false,
  setChildrenModalOpened: (v) => {},
  ViewChildrenModalOpened: false,
  setViewChildrenModalOpened: (v) => {},
  selectedChildrenId: '',
  setSelectedChildrenId: (id) => {},
  selectedChildren: null,
  setSelectedChildren: (servKey) => {},
  selectedFilter: '',
  setSelectedFilter: (v) => {}
};

const ChildrenContext = createContext(INITIAL_VALUES);

export const ChildrenProvider = ({ children }) => {
  const [selectedFilter, setSelectedFilter] = useState(INITIAL_VALUES.selectedFilter);
  const [allChildren, setAllChildren] = useState(INITIAL_VALUES.allChildren);
  const [loadingChildren, setLoadingChildren] = useState(INITIAL_VALUES.loadingChildren);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [ChildrenModalOpened, setChildrenModalOpened] = useState(INITIAL_VALUES.ChildrenModalOpened);
  const [ViewChildrenModalOpened, setViewChildrenModalOpened] = useState(INITIAL_VALUES.ChildrenModalOpened);
  const [selectedChildrenId, setSelectedChildrenId] = useState(INITIAL_VALUES.selectedChildrenId);
  const [selectedChildren, setSelectedChildren] = useState(INITIAL_VALUES.selectedChildren);

  return (
    <ChildrenContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        loadingChildren,
        setLoadingChildren,
        selectedFilter,
        setSelectedFilter,
        allChildren,
        setAllChildren,
        ChildrenModalOpened,
        setChildrenModalOpened,
        ViewChildrenModalOpened,
        setViewChildrenModalOpened,
        selectedChildrenId,
        setSelectedChildrenId,
        selectedChildren,
        setSelectedChildren
      }}>
      {children}
    </ChildrenContext.Provider>
  );
};

export default ChildrenContext;

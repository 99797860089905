/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import "./uploadQustionFile.scss";



const UploadQuestionFile = (props) => {
  const { name, label, watch, imageTarget, accept, errorMsg, register, unregister, setValue, multiple } = props;

  // const [myDroppedFiles, setMyDroppedFiles] = useState([]);
  const [urls, setUrls] = useState([]);
  // const onDrop = useCallback(
  //   (droppedFiles) => {
  //     setValue(name, droppedFiles, { shouldValidate: true });
  //     // setMyDroppedFiles(droppedFiles);

  //     // read file
  //     const reader = new FileReader();
  //     reader.addEventListener('load', () => {
  //       setUrls([reader.result]);
  //     });
  //     reader.readAsDataURL(droppedFiles[0]);
  //   },
  //   [name]
  // );

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    // onDrop,
    accept: accept,
    multiple
  });
  console.log(acceptedFiles)

  useEffect(() => {
    if (acceptedFiles?.length > 0) {
      setValue(name, acceptedFiles, { shouldValidate: true });

      // read file
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setUrls([reader.result]);
      });
      reader.readAsDataURL(acceptedFiles[0]);
    }
  }, [acceptedFiles, name]);


  useEffect(() => {
    register(name);
    return () => {
      unregister(name);
    };
  }, [register, unregister, name]);
  useEffect(() => {
    if (imageTarget) {
      setUrls([`${imageTarget}`]);
      // let convertImage = async (imageUrl) => {
      //   if (imageUrl) {
      //     let response = await fetch(imageUrl || "", {
      //       mode: "no-cors",
      //     });
      //     let blob = await response.blob();

      //     let file = new File([blob], "image.jpg", { type: "image/jpeg" });
      //     console.log(file)
      //     // setImageFile(file);
      //   }

      //   // =========
      // };
      // convertImage(imageTarget);

    }
    // else {

    //   setUrls([]);
    // }
  }, [imageTarget]);
  // useEffect(() => {
  //   if (watch(name) == null) {
  //     setUrls([]);
  //   }
  // }, [watch]);
  return (
    <div className="upload-files-wrapper">
      <label className="file-input-label block !mb-[14px]" htmlFor={name}>
        {label}
      </label>
      <div className="dropzone-itself" {...getRootProps()}>
        <input
          name={name}
          lbale={label}
          accept={accept}
          className="file-input-itself"
          id={name}
          {...getInputProps()}
        />
        <div className={'dashed-border' + (isDragActive ? 'gray-500-bg' : 'gray-200-bg')}>
          {!urls?.length && <p className="text-center my-2">اسحب صورة الخاصيه وضعها هنا ...</p>}
          {urls?.length > 0 && (
            <div className="imgs-preview-wrapper">
              {urls.map((url, index) => {
                return (
                  <div className="img-preview" key={index}>
                    <img src={`${url}`} alt="preview" />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {errorMsg && <p className="error-p text-[#ff4d4f]">{errorMsg}</p>}
    </div>
  );
};

export default UploadQuestionFile;

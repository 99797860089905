import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Select, Icon } from 'antd';
import { useTranslation } from 'react-i18next';
import { Store } from 'react-notifications-component';
import './AddSitterForm.scss';
import { useHistory } from 'react-router-dom';
import { Radio } from 'antd';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import axios from 'axios';
import Geocode from "react-geocode";
import DataEntryUesrContext from '../../contexts/data-entry-user-context/DataEntryUserProvider';
import getAllCountarys from '../../apis/countary-api/getAllcountaries';
import registerSitterApi from '../../apis/registerSitterApi';
import GoogleMapSitter from './GoogleMapSitter';
import GoogleMapContext from '../../contexts/google-map-context/GoogleMapProvider';
import {
  LoadingOutlined,
} from '@ant-design/icons';

Geocode.setApiKey(process.env.REACT_APP_MAP_KEY);
Geocode.setLanguage("ar");

const containerStyle = {
  width: '100%',
  height: '400px'
};

const schema = Yup.object().shape({
  nurseryname: Yup.string().required('من فضلك ادخل اسم الحضانة'),
  nurserylocation: Yup.string().required('من فضلك ادخل  موقع الحضانة'),
  nurserycity: Yup.string().required('من فضلك ادخل   المدينة'),
  nurserynumber: Yup.string().required('من فضلك ادخل   رقم الحضانة'),
  sittername: Yup.string().required('من فضلك ادخل اسم المربية'),
  sitterlocation: Yup.string().required('من فضلك ادخل  موقع المربية'),
  sittercity: Yup.string().required('من فضلك ادخل   المدينة'),
  sitternumber: Yup.string().required('من فضلك ادخل   رقم المربية'),
});

const AddsitterForm = () => {

  const [loadingCountarys, setLoadingCountarys] = useState(false);
  const [loading, setLoading] = useState(false);
  const [AllCountries, setAllCountries] = useState();
  const [allCities, setAllCities] = useState();
  const { userDataEntry } = useContext(DataEntryUesrContext);

  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const [fulllocationDetails, setfulllocationDetails] = useState("")
  const { selectedGoogleMap, loadingGoogleMap, lat , lng} = useContext(GoogleMapContext);

  
  const [map, setMap] = React.useState(null)
  const formRef = useRef();


  useEffect(() => {
    const getCountarys = async () => {
      try {
        setLoadingCountarys(true);
        const getCountarysRes = await getAllCountarys(userDataEntry?.token);

        if (getCountarysRes.status === 200 && getCountarysRes?.data?.data) {
          setAllCountries(getCountarysRes.data.data);
          setLoadingCountarys(false);
        }
      } catch (error) {
        setLoadingCountarys(false);

      }
    };
    getCountarys();
    getAllCitys(8, userDataEntry?.token)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllCitys = async (country_id, token) => {
    try {
      const getCitysRes = await axios.get('https://api.haleemh.sa/api/Location/cities?country_id=' + country_id, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'applications/json',
          Authorization: 'Bearer ' + token
        }
      });
      setAllCities(getCitysRes.data.data)
      return getCitysRes;
    } catch (error) {

    }
  }

  const showModal = () => {

    setIsModalOpen(true);

  };

  useEffect(() => {
    if (selectedGoogleMap) {
      formRef?.current?.setFieldsValue({
        address: selectedGoogleMap
      })
    }
  }, [selectedGoogleMap]);


  

  const [loadingCitys, setLoadingCitys] = useState(false);
  const [allCitys, setAllCitys] = useState("");


  let history = useHistory();
  const { i18n } = useTranslation();
  const [sitterTypeValue, setSitterTypeValue] = useState(1);

  const onChange = (e) => {
    setSitterTypeValue(e.target.value);

  };

  

  const { setCurrentUser, setUserToState } = useContext(DataEntryUesrContext);
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      sittername: '',
      sitterlocation: '',
      sittercity: '',
      sitternumber: '',
      nurseryname: '',
      nurserylocation: '',
      nurserycity: '',
      nurserynumber: '',
    }
  });

  const onCityChange = (value) => {

  };

  const onCountryChange = (value) => {
    getAllCitys(value, userDataEntry.token)

  };
  const onSearch = (value) => {

  };
  const onSubmit = async (data) => {
    let modifiedData = { ...data, type: sitterTypeValue, lat: lat, lng: lng, phone: +data.phone, city_id: data.city, admin_name: data.supervisorname }
    delete modifiedData['city'];
    delete modifiedData['supervisorname'];


    try {
      setLoading(true)
      const res = await registerSitterApi(modifiedData, userDataEntry.token);

      if (res.status === 200 && res.data.status === 0) {
        setLoading(false)
        Store.addNotification({
          title: 'حدث خطأ اثناء الدخول',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (res.status === 200 && res.data.status === 1) {
        setLoading(false)
        form.resetFields()
        Store.addNotification({
          title: 'تمت الاضافة بنجاح',
          message: 'تمت الاضافة بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      setLoading(false)

    }
  };

  const [form] = Form.useForm();
  return (



    <div className='add-sitter-form-parent' >
      <Radio.Group style={{ marginBottom: "15px" }} onChange={onChange} value={sitterTypeValue}>
        <Radio value={1}>مربية</Radio>
        <Radio value={2}>حضانة</Radio>
      </Radio.Group>
      {
        sitterTypeValue === 2 ? (<Form className="add-sitter-form" ref={formRef} form={form} layout="vertical" onFinish={onSubmit}>
          <div className="form-body">
            <Form.Item
              name="username"
              rules={[{ required: true, message: 'من فضلك ادخل  اسم المربية' }]}>
              <Input value={fulllocationDetails} prefix={<PlusOutlined />} placeholder={'  اسم المربية...'} />
            </Form.Item>
            <Form.Item
              name="nursery_name"
              rules={[{ required: true, message: 'من فضلك ادخل  اسم الحضانة' }]}>
              <Input value={fulllocationDetails} prefix={<PlusOutlined />} placeholder={'  اسم الحضانة...'} />
            </Form.Item>

            <Form.Item
              name="address"
              rules={[{ required: true, message: 'من فضلك ادخل  موقع الحضانة' }]}
            >
              <Input onClick={() => showModal()} prefix={<PlusOutlined />} placeholder={'  موقع الحضانة...'} />
            </Form.Item>
            <GoogleMapSitter />
            
            <Form.Item name="country_id" rules={[{ required: true, message: 'من فضلك ادخل  الدولة ' }]}>
              <Select
                showSearch
                placeholder="اختر الدولة..."
                optionFilterProp="children"
                onChange={onCountryChange}
                onSearch={onSearch}

              >
                {AllCountries && AllCountries.map(item => (
                  <Select.Option value={item.id} key={item.id}>{item.name_ar}</Select.Option>
                ))}

              </Select>
            </Form.Item>
            <Form.Item name="city" rules={[{ required: true, message: 'من فضلك ادخل  المدينة ' }]}>
              <Select
                showSearch
                placeholder="اختر المدينة..."
                optionFilterProp="children"
                onChange={onCityChange}
                onSearch={onSearch}

              >
                {allCities && allCities.map(item => (

                  <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="phone"
              rules={[{ required: true, message: 'من فضلك ادخل  رقم الحضانة' }]}
            >
              <Input value={fulllocationDetails} prefix={<PlusOutlined />} placeholder={'  رقم الحضانة...'} type="number" />
            </Form.Item>
            <Form.Item
              name="supervisorname"
              rules={[{ required: true, message: 'من فضلك ادخل   اسم المشرف' }]}
            >
              <Input value={fulllocationDetails} prefix={<PlusOutlined />} placeholder={'  اسم المشرف ...'} type="text" />
            </Form.Item>

            <Button
              className="submit-btn"
              htmlType="submit"
              type="primary"
              icon={<PlusOutlined />}
              loading={loading}>
              اضافة
            </Button>

          </div>
        </Form>) : (<Form className="add-sitter-form" ref={formRef} form={form} layout="vertical" onFinish={onSubmit}>
          <div className="form-body">
            <Form.Item
              name="username"
              rules={[{ required: true, message: 'من فضلك ادخل  اسم المربية' }]}>
              <Input value={fulllocationDetails} prefix={<PlusOutlined />} placeholder={'  اسم المربية...'} />
            </Form.Item>
            <Form.Item
              name="address"
              rules={[{ required: true, message: 'من فضلك ادخل  موقع المربية' }]}
            >
              <Input value={fulllocationDetails} prefix={<PlusOutlined />} placeholder={'  موقع المربية...'} />
            </Form.Item>
            <GoogleMapSitter />
           
            <Form.Item name="country_id" rules={[{ required: true, message: 'من فضلك ادخل  الدولة ' }]}>
              <Select
                showSearch
                placeholder="اختر الدولة..."
                optionFilterProp="children"
                onChange={onCountryChange}
                onSearch={onSearch}

              >
                {AllCountries && AllCountries.map(item => (
                  <Select.Option value={item.id} key={item.id}>{item.name_ar}</Select.Option>
                ))}

              </Select>
            </Form.Item>
            <Form.Item name="city" rules={[{ required: true, message: 'من فضلك ادخل  المدينة ' }]}>
              <Select
                showSearch
                placeholder="اختر المدينة..."
                optionFilterProp="children"
                onChange={onCityChange}
                onSearch={onSearch}

              >
                {allCities && allCities.map(item => (

                  <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="phone"
              rules={[{ required: true, message: 'من فضلك ادخل  رقم المربية' }]}
            >
              <Input value={fulllocationDetails} prefix={<PlusOutlined />} placeholder={'  رقم المربية...'} type="number" />
            </Form.Item>
            <Form.Item
              name="supervisorname"
              rules={[{ required: true, message: 'من فضلك ادخل   اسم المشرف' }]}
            >
              <Input value={fulllocationDetails} prefix={<PlusOutlined />} placeholder={'  اسم المشرف ...'} type="text" />
            </Form.Item>
            <Button disabled={loading}
              className="submit-btn"
              htmlType="submit"
              type="primary"
              icon={<PlusOutlined />}
              loading={loading}>
                
              اضافة
            </Button>

          </div>
        </Form>)
      }


      {/* <Modal title="اضافة الموقع" className='antdmodalmap' open={isModalOpen} okText="موافق"
        cancelButtonProps={{ style: { display: 'none' } }} onOk={handleOk} onCancel={handleCancel} >
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
            onUnmount={onUnmount}
            onClick={onMapClicked}
          >
            <Marker position={center} />
            <></>
          </GoogleMap>
        ) : <></>}
      </Modal> */}
    </div >
  );
}

export default AddsitterForm
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import DriversTable from './DriversTable';

const DriversPage = () => {
  let history = useHistory();

  // const { DriverModalOpened, setDriverModalOpened, setSelectedServId, setSelectedDriver, selectedDriver } =
  //   useContext(DriversContext);



  return (
    <>
      <div>
        <Button
          onClick={() => {
            history.push("/driver/create")
          }}
          className="add-serv-btn"
          type="primary"
          icon={<PlusOutlined />}>
          <span className="btn-text">اضافة سائق</span>

        </Button>
        <DriversTable />

      </div>

    </>
  );
};

export default DriversPage;

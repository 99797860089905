
import AdminTable from "./AdminTable";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from "antd";
import { PlusOutlined } from '@ant-design/icons';


const AdminPage = ()=>{
  
  let history = useHistory();


    return(
        <>
            <Button 
                onClick={() => {
                  history.push("/admins/create")
                }}
                className="add-serv-btn"
                type="primary"
                icon={<PlusOutlined />}>
                <span className="btn-text">اضافة مسؤل</span>
              </Button>


          <div className='overflow-x-auto'>
                <AdminTable />
          </div>      
        </>
      
    
   )
}
export default AdminPage;
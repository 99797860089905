import { Table } from 'antd';
import React, { useContext, useEffect } from 'react';
import getAllParents from '../../apis/parents-api/getAllParents';
import UesrContext from '../../contexts/user-context/UserProvider';
import DriversColumns from './DriversColumns';
import DriversContext from '../../contexts/drivers-context/DriversProvider';
import getAllDriversApi from '../../apis/drivers-api/getAllDriversApi';
const DriversTable = () => {
  const { user } = useContext(UesrContext);
  const {
    fetchCount,
    setFetchCount,
    loadingDriver,
    setLoadingDriver,
    allDrivers, setAllDrivers,
    setParentModalOpened,
    setSelectedParent,

  } = useContext(DriversContext);

  useEffect(() => {
    const getDrivers = async () => {
      try {
        setLoadingDriver(true);
        const getDriversRes = await getAllDriversApi(user?.token);
        if (getDriversRes.status === 200 && getDriversRes?.data?.driver) {
          setAllDrivers(getDriversRes?.data?.driver);
          // console.table(getDriversRes?.data?.driver?.data)
          // console.log(getDriversRes?.data?.driver?.data)
          setLoadingDriver(false);
        }
      } catch (error) {
        setLoadingDriver(false);
      }
    };
    getDrivers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCount]);
  // console.log(allDrivers);
  return (
    <Table
      pagination={{ pageSize: 5 }}
      className="Orders-table"
      dataSource={allDrivers}
      columns={DriversColumns(
        user?.token,
        setFetchCount,
        setLoadingDriver,
        setParentModalOpened,
        setSelectedParent
      )}
      loading={loadingDriver}
      scroll={{ x: 1200 }}
    />
  );
};

export default DriversTable;

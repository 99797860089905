import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import FacilityTable from './facilityTable';

const FacilityPage = () => {
  const history = useHistory();

  return (
    <>
      <Button
        onClick={() => {
          history.push('/facilities/create');
        }}
        className="add-serv-btn"
        type="primary"
        icon={<PlusOutlined />}>
        <span className="btn-text">اضافة منشأة</span>
      </Button>

      <FacilityTable />
    </>
  );
};
export default FacilityPage;

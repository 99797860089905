import axios from 'axios';

const createCity = async (url, values, token) => {
  
  try {
    const { name_ar, name_en,country_id } = values;
    const pickedValues = {
      name_ar,
      name_en

      // image: City_imgs ? City_imgs[0] : null
    };
    const formData = new FormData();
    for (let key in pickedValues) {
      formData.append(key, pickedValues[key]);
    }
    formData.append('country_id', country_id);
    const createSerivceRes = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      }
    });
    return createSerivceRes;
  } catch (error) {
    
  }
};

export default createCity;

import axios from 'axios';

const createFacility = async (url, values, token) => {
  const { name, space, setter_id, tax_id, rent_contract, num_of_rooms } = values;
  try {
    const formData = new FormData();
    if (Array.isArray(tax_id)) {
      formData.append('tax_id', tax_id[0]);
    }
    if (Array.isArray(rent_contract)) {
      formData.append('rent_contract', rent_contract[0]);
    }
    formData.append('name', name);
    formData.append('space', space);
    formData.append('setter_id', setter_id);
    formData.append('num_of_rooms', num_of_rooms);

    const createFacilityRes = axios.post(url, formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token
      }
    });

    console.log(createFacilityRes);

    return createFacilityRes;
  } catch (error) {
    return error?.response;
  }
};

export default createFacility;

import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

import { CitysProvider } from '../../contexts/City-context/CityProvider';
import { AdminProvider } from '../../contexts/admin-context/AdminProvider';
import { AreasProvider } from '../../contexts/area-context/AreaProvider';
import { ChildrenProvider } from '../../contexts/children-context/ChildrenProvider';
import { ContactUsProvider } from '../../contexts/contactUs-context/ContactUsProvider';
import { UserProvider } from '../../contexts/user-context/UserProvider';
import AdminPage from '../../pages/admin-page/adminPage';
import UsersPage from '../../pages/users-page/usersPage';

import AdminDetails from '../../pages/admin-page/AdminDetails';
import CreateAdmin from '../../pages/admin-page/CreateAdmin';
import UpdateAdmin from '../../pages/admin-page/updateAdmin';
import SitterDetails from '../../pages/sitters-page/sitterDetails';
import EditUser from '../../pages/users-page/EditUser';
import UserDetails from '../../pages/users-page/UserDetails';

import { CountarysProvider } from '../../contexts/countary-context/CountaryProvider';
import { DriversProvider } from '../../contexts/drivers-context/DriversProvider';
import { OrdersProvider } from '../../contexts/orders-context/OrdersProvider';
import { ParentsProvider } from '../../contexts/parents-context/ParentsProvider';
import { SettingsProvider } from '../../contexts/settings-context/SettingsProvider';
import { SittersProvider } from '../../contexts/sitters-context/SittersProvider';
import { SupportsProvider } from '../../contexts/supports-context/SupportsProvider';
import AreaPage from '../../pages/area-page/AreaPage';
import ChildrenPage from '../../pages/children-page/ChildrenPage';
import CityPage from '../../pages/city-page/CityPage';
import ContactUsPage from '../../pages/contactus-page/ContactUsPage';
import CountaryPage from '../../pages/countary-page/CountaryPage';
import CreateChildrenPage from '../../pages/createChildren-page/createChildrenPage';
import CreateParentPage from '../../pages/createParent-page/CreateParentPage';
import DriversPage from '../../pages/drivers-page/DriversPage';
import NotFoundPage from '../../pages/not-found-page/NotFoundPage';
import ParentsPage from '../../pages/parents-page/ParentsPage';
import ProfilePage from '../../pages/profile-page/ProfilePage';
import SettingsPage from '../../pages/settings-page/SettingsPage';
import ShowChildrenPage from '../../pages/showChildren-Page/ShowChildrenPage';
import ShowParentsPage from '../../pages/showParents-Page/ShowParentsPage';
import CreateSitter from '../../pages/sitters-page/CreateSitter';
import SittersPage from '../../pages/sitters-page/SittersPage';
import UpdateSitter from '../../pages/sitters-page/UpdateSitter';
import SupportsPage from '../../pages/supports-page/SupportsPage';
import UpdateChildrenPage from '../../pages/updateChildren-page/UpdateChildrenPage';
import UpdateParentPage from '../../pages/updateParent-Page/updateParentPage';

import { CertificatesProvider } from '../../contexts/certificates-context/CertificatesProvider';
import { RoomProvider } from '../../contexts/room-context/roomsProvider';
import CertificatesPage from '../../pages/certificates-page/CertificatesPage';
import CreateCertificates from '../../pages/createCertificates/createCertificates';
import CreateDriverPage from '../../pages/createDriver-page/createDriverPage';
import CreateRoom from '../../pages/rooms-page/CreateRoom';
import RoomDetails from '../../pages/rooms-page/RoomDetails';
import RoomsPage from '../../pages/rooms-page/RoomsPage';
import UpdateRoom from '../../pages/rooms-page/updateRoom';

import { FacilityProvider } from '../../contexts/facility_context/FacilityProvider';
import CreateFacility from '../../pages/facilities_page/createFacility';
import FacilityDetails from '../../pages/facilities_page/facilityDetails';
import FacilityPage from '../../pages/facilities_page/facilityPage';
import UpdateFacility from '../../pages/facilities_page/updateFacility';

import { TransactionsProvider } from '../../contexts/transactions-context/TransactionsProvider';
import CreateTransactions from '../../pages/createTransactions/createTransactions';
import ShowCertificatesPage from '../../pages/showCertificates-Page/ShowCertificatesPage';
import ShowDriversPage from '../../pages/showDrivers-Page/ShowDriversPage';
import ShowTransactionsPage from '../../pages/showtransactions-Page/showtransactionsPage';
import TransactionsPage from '../../pages/transactions-page/TransactionsPage';
import UpdateCertificates from '../../pages/updateCertificates/UpdateCertificates';
import UpdateDriverPage from '../../pages/updateDriver-page/UpdateDriverPage';
import UpdateTransactions from '../../pages/updateTransactions/UpdateTransactions';
import routerLinks from './routerLinks';

import { RateProvider } from '../../contexts/rates-context/RatesProvider';
import RateDetails from '../../pages/rates-page/RateDetails';
import RatesPage from '../../pages/rates-page/RatesPage';

import OrderPage from '../../pages/order-page/OrderPage';
import ShowOrderPage from '../../pages/showOrder-Page/ShowOrderPage';

import CreateCountery from '../../pages/countary-page/CreateCountery';
import UpdateCountry from '../../pages/countary-page/updateCountery';

import { TicketsProvider } from '../../contexts/tickets-context/TicketsProvider';
import CreateOrderPage from '../../pages/createOrder-page/CreateOrderPage';
import CreateTickets from '../../pages/createTickets/CreateTickets';
import ShowTicketPage from '../../pages/showTicktes-Page/ShowTicketPage';
import TicketsPage from '../../pages/tickets-page/TicketsPage';
import TicketThreadsPage from '../../pages/ticketsThreads-page/TicketsThreadsPage';
import UpdateOrderPage from '../../pages/updateOrder-page/UpdateOrderPage';
import UpdateTickets from '../../pages/updateTickets/UpdateTickets';
import CreateTicketsThreads from '../../pages/createTicketsThreads/CreateTicketsThreads';

const HomePage = lazy(() => import('../../pages/home-page/HomePage'));

const Routes = () => {
  return (
    <Switch>
      <Route exact path={routerLinks.homePage} component={HomePage} />

      <Route exact path={routerLinks.SettingsPage}>
        <SettingsProvider>
          <SettingsPage />
        </SettingsProvider>
      </Route>

      <Route exact path={routerLinks.transactionsPage}>
        <>
          <TransactionsProvider>
            <TransactionsPage />
          </TransactionsProvider>
        </>
      </Route>
      <Route exact path={routerLinks.transactionsShow}>
        <>
          <TransactionsProvider>
            <ShowTransactionsPage />
          </TransactionsProvider>
        </>
      </Route>
      <Route exact path={routerLinks.transactionsCreate}>
        <>
          <TransactionsProvider>
            <CreateTransactions />
          </TransactionsProvider>
        </>
      </Route>
      <Route exact path={routerLinks.transactionsUpdate}>
        <>
          <TransactionsProvider>
            <UpdateTransactions />
          </TransactionsProvider>
        </>
      </Route>
      <Route exact path={routerLinks.certificatePage}>
        <>
          <CertificatesProvider>
            <CertificatesPage />
          </CertificatesProvider>
        </>
      </Route>
      <Route exact path={routerLinks.certificateShow}>
        <>
          <CertificatesProvider>
            <ShowCertificatesPage />
          </CertificatesProvider>
        </>
      </Route>
      <Route exact path={routerLinks.certificateCreate}>
        <>
          <CertificatesProvider>
            <CreateCertificates />
          </CertificatesProvider>
        </>
      </Route>
      <Route exact path={routerLinks.certificateUpdate}>
        <>
          <CertificatesProvider>
            <UpdateCertificates />
          </CertificatesProvider>
        </>
      </Route>
      {/* <Route exact path={routerLinks.ordersPage}>
        <OrdersProvider>
          <OrdersPage />
        </OrdersProvider>
      </Route> */}
      <Route exact path={routerLinks.ticketThreadsPage}>
        <>
          <TicketsProvider>
            <TicketThreadsPage />
          </TicketsProvider>
        </>
      </Route>
      <Route exact path={routerLinks.createTicketThreadsPage}>
        <>
          <TicketsProvider>
            <CreateTicketsThreads />
          </TicketsProvider>
        </>
      </Route>
      <Route exact path={routerLinks.ticketsPage}>
        <>
          <TicketsProvider>
            <TicketsPage />
          </TicketsProvider>
        </>
      </Route>
      <Route exact path={routerLinks.ticketsShow}>
        <>
          <TicketsProvider>
            <ShowTicketPage />
          </TicketsProvider>
        </>
      </Route>
      <Route exact path={routerLinks.ordersPage}>
        <>
          <OrdersProvider>
            <OrderPage />
          </OrdersProvider>
        </>
      </Route>
      <Route exact path={routerLinks.orderShow}>
        <>
          <OrdersProvider>
            <ShowOrderPage />
          </OrdersProvider>
        </>
      </Route>
      <Route exact path={routerLinks.ticketsCreate}>
        <>
          <OrdersProvider>
            <CreateTickets />
          </OrdersProvider>
        </>
      </Route>
      <Route exact path={routerLinks.ticketsUpdate}>
        <>
          <OrdersProvider>
            <UpdateTickets />
          </OrdersProvider>
        </>
      </Route>
      <Route exact path={routerLinks.orderCreate}>
        <>
          <OrdersProvider>
            <CreateOrderPage />
          </OrdersProvider>
        </>
      </Route>
      <Route exact path={routerLinks.orderUpdate}>
        <>
          <OrdersProvider>
            <UpdateOrderPage />
          </OrdersProvider>
        </>
      </Route>
      <Route exact path={routerLinks.driverPage}>
        <>
          <DriversProvider>
            <DriversPage />
          </DriversProvider>
        </>
      </Route>
      <Route exact path={routerLinks.driverShow}>
        <>
          <DriversProvider>
            <ShowDriversPage />
          </DriversProvider>
        </>
      </Route>
      <Route exact path={routerLinks.driverCreate}>
        <>
          <DriversProvider>
            <CreateDriverPage />
          </DriversProvider>
        </>
      </Route>
      <Route exact path={routerLinks.driverUpdate}>
        <>
          <DriversProvider>
            <UpdateDriverPage />
          </DriversProvider>
        </>
      </Route>
      <Route exact path={routerLinks.parentPage}>
        <ParentsProvider>
          <ParentsPage />
        </ParentsProvider>
      </Route>
      <Route exact path={routerLinks.ParentShow}>
        <ParentsProvider>
          <ShowParentsPage />
        </ParentsProvider>
      </Route>
      <Route exact path={routerLinks.ParentsCreate}>
        <ParentsProvider>
          <CreateParentPage />
        </ParentsProvider>
      </Route>
      <Route exact path={routerLinks.ParentsUpdate}>
        <ParentsProvider>
          <UpdateParentPage />
        </ParentsProvider>
      </Route>
      <Route exact path={routerLinks.ChildrenPage}>
        <ParentsProvider>
          <ChildrenProvider>
            <ChildrenPage />
          </ChildrenProvider>
        </ParentsProvider>
      </Route>
      <Route exact path={routerLinks.ChildrenShow}>
        <ParentsProvider>
          <ChildrenProvider>
            <ShowChildrenPage />
          </ChildrenProvider>
        </ParentsProvider>
      </Route>
      <Route exact path={routerLinks.ChildrenCreate}>
        <ParentsProvider>
          <ChildrenProvider>
            <CreateChildrenPage />
          </ChildrenProvider>
        </ParentsProvider>
      </Route>
      <Route exact path={routerLinks.ChildrenUpdate}>
        <ParentsProvider>
          <ChildrenProvider>
            <UpdateChildrenPage />
          </ChildrenProvider>
        </ParentsProvider>
      </Route>

      <Route exact path={routerLinks.sittersPage}>
        <SittersProvider>
          <SittersPage />
        </SittersProvider>
      </Route>

      <Route exact path={routerLinks.sitterView}>
        <SittersProvider>
          <SitterDetails />
        </SittersProvider>
      </Route>

      <Route exact path={routerLinks.sitterUpdate}>
        <SittersProvider>
          <UpdateSitter />
        </SittersProvider>
      </Route>

      <Route exact path={routerLinks.createSitter}>
        <SittersProvider>
          <CreateSitter />
        </SittersProvider>
      </Route>

      <Route exact path={routerLinks.roomsPage}>
        <RoomProvider>
          <RoomsPage />
        </RoomProvider>
      </Route>

      <Route exact path={routerLinks.createRoom}>
        <RoomProvider>
          <CreateRoom />
        </RoomProvider>
      </Route>

      <Route exact path={routerLinks.roomDetails}>
        <RoomProvider>
          <RoomDetails />
        </RoomProvider>
      </Route>

      <Route exact path={routerLinks.updateRoom}>
        <RoomProvider>
          <UpdateRoom />
        </RoomProvider>
      </Route>

      <Route exact path={routerLinks.falilityPage}>
        <FacilityProvider>
          <FacilityPage />
        </FacilityProvider>
      </Route>

      <Route exact path={routerLinks.facilityDetails}>
        <FacilityProvider>
          <FacilityDetails />
        </FacilityProvider>
      </Route>

      <Route exact path={routerLinks.createFacility}>
        <FacilityProvider>
          <CreateFacility />
        </FacilityProvider>
      </Route>

      <Route exact path={routerLinks.facilityUpdate}>
        <FacilityProvider>
          <UpdateFacility />
        </FacilityProvider>
      </Route>

      <Route exact path={routerLinks.ratesPage}>
        <RateProvider>
          <RatesPage />
        </RateProvider>
      </Route>

      <Route exact path={routerLinks.rateDetails}>
        <RateProvider>
          <RateDetails />
        </RateProvider>
      </Route>

      <Route exact path={routerLinks.ProfilePage} component={ProfilePage} />

      <Route exact path={routerLinks.usersPage}>
        <UserProvider>
          <UsersPage />
        </UserProvider>
      </Route>

      <Route exact path={routerLinks.updateUser}>
        <UserProvider>
          <EditUser />
        </UserProvider>
      </Route>

      <Route exact path={routerLinks.viewUser}>
        <UserProvider>
          <UserDetails />
        </UserProvider>
      </Route>

      <Route exact path={routerLinks.adminPage}>
        <AdminProvider>
          <AdminPage />
        </AdminProvider>
      </Route>

      <Route exact path={routerLinks.viewAdmin}>
        <AdminProvider>
          <AdminDetails />
        </AdminProvider>
      </Route>

      <Route exact path={routerLinks.createAdmin}>
        <AdminProvider>
          <CreateAdmin />
        </AdminProvider>
      </Route>

      <Route exact path={routerLinks.updateAdmin}>
        <AdminProvider>
          <UpdateAdmin />
        </AdminProvider>
      </Route>

      <Route exact path={routerLinks.CountaryPage}>
        <CountarysProvider>
          <CitysProvider>
            <AreasProvider>
              <CountaryPage />
            </AreasProvider>
          </CitysProvider>
        </CountarysProvider>
      </Route>

      <Route exact path={routerLinks.createCountery}>
        <CountarysProvider>
          <CitysProvider>
            <AreasProvider>
              <CreateCountery />
            </AreasProvider>
          </CitysProvider>
        </CountarysProvider>
      </Route>

      <Route exact path={routerLinks.updateCountery}>
        <CountarysProvider>
          <CitysProvider>
            <AreasProvider>
              <UpdateCountry />
            </AreasProvider>
          </CitysProvider>
        </CountarysProvider>
      </Route>

      <Route exact path={routerLinks.CityPage}>
        <CountarysProvider>
          <CitysProvider>
            <AreasProvider>
              <CityPage />
            </AreasProvider>
          </CitysProvider>
        </CountarysProvider>
      </Route>

      <Route exact path={routerLinks.AreaPage}>
        <AreasProvider>
          <CountarysProvider>
            <AreaPage />
          </CountarysProvider>
        </AreasProvider>
      </Route>

      <Route exact path={routerLinks.supportsPage}>
        <SupportsProvider>
          <SupportsPage />
        </SupportsProvider>
      </Route>

      <Route exact path={routerLinks.ContactUsPage}>
        <ContactUsProvider>
          <ContactUsPage />
        </ContactUsProvider>
      </Route>

      {/* not found page */}
      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
};

export default Routes;

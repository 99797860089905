import axios from 'axios';

const deleteCityApi = async (CityId, token) => {
  try {
    const response = await axios.post(`City/delete/${CityId}`, null, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    if (response.status === 200 && response.data.status === 1) {
      return response;
    }
  } catch (e) {
    
  }
};

export default deleteCityApi;

import { EditOutlined, SendOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button, Form } from 'antd';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { useState } from 'react';
import { Store } from 'react-notifications-component';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import createTicketsApi from '../../apis/tickets-api/createTicketsApi';
import AntdTextarea from '../../common/antd-form-components/AntdTextarea';
import UesrContext from '../../contexts/user-context/UserProvider';
import createTicketsThreadsApi from '../../apis/tickets-api/createTicketsThreadsApi';

const defaultValues = {
  message: ''
};

const CreateTicketsThreads = () => {
  const { user } = useContext(UesrContext);
  const [dataUsersId, setDataUsersId] = useState([]);
  const [dataSetterId, setDataSetterId] = useState([]);

  // console.log(user);
  let history = useHistory();
  let param = useParams();

  let schema = Yup.object().shape({
    message: Yup.string().optional(' من فضلك أدخل الوصف مطلوب')
  });

  const {
    control,
    // getValues,
    setValue,
    // setError,
    // clearErrors,
    handleSubmit,
    register,
    watch,
    unregister,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });
  const [urls, setUrls] = useState([]);
  console.log();
  // console.log(watch())

  const onSubmit = async (data) => {
    // console.log(data)
    try {
      let res;
      res = await createTicketsThreadsApi(
        '/tickets/make_ticket_thread',
        { ...data, ticket_id: param?.target_id },
        user?.token
      );
      // console.log(res)

      // console.log('create Selles response: ', res);
      if (res?.status !== 200) {
        Store.addNotification({
          title: 'حدث خطء اثناء الأضافه',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (res?.status === 200 && res?.data?.status_code === 200) {
        reset({ ...defaultValues });
        Store.addNotification({
          title: 'تمت العملية بنجاح',
          message: 'تم الأضافه ',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
        history.push(`/ticket/ticket_threads/${param?.target_id}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [form] = Form.useForm();

  // useEffect(() => {
  //   const handleGetUsers = async () => {
  //     try {
  //       const getUsersRes = await GetAllUserID(user?.token);
  //       // console.log(getUsersRes.data.user_ids);

  //       if (getUsersRes.status === 200 && getUsersRes?.data?.users) {
  //         setDataUsersId(getUsersRes?.data?.users);
  //       }
  //     } catch (error) {}
  //   };
  //   handleGetUsers();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // ======getSetterRes=========
  // useEffect(() => {
  //   const handleGetSetter = async () => {
  //     try {
  //       const getSetterRes = await getAllSetterId(user?.token);
  //       // console.log(getSetterRes.data.setter_ids);

  //       if (getSetterRes.status === 200 && getSetterRes?.data?.setters) {
  //         setDataSetterId(getSetterRes?.data?.setters);
  //       }
  //     } catch (error) {}
  //   };
  //   handleGetSetter();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <Form
      className="add-Selles-form flex justify-center items-center"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}>
      <div className="form-body my-[40px] w-full shadow-[rgba(100,_100,_111,_0.2)_0px_7px_29px_0px] max-w-[800px] py-[40px] px-[30px]">
        {/* <div className="form-field-wrapper">
          <AntdTextarea
            name="description"
            type="text"
            placeholder="الوصف..."
            errorMsg={errors?.description?.message}
            validateStatus={errors?.description ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div> */}
        <div className="form-field-wrapper">
          <AntdTextarea
            name="message"
            type="text"
            placeholder="الرسالة..."
            errorMsg={errors?.message?.message}
            validateStatus={errors?.message ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        {/* <div className=" form-field-wrapper py-[4px]">
          <AntdSelectOption
            name="status"
            errorMsg={errors?.status?.message}
            validateStatus={errors?.status ? 'error' : ''}
            control={control}
            setValue={setValue}
            options={[
              { title: 'open ', value: 'open ' },
              { title: 'close', value: 'close' }
            ]}
            label={
              <p className="select-option-label">
                <EditOutlined />
                الحالة
              </p>
            }
            formClassName="add-Selles-form"
          />
        </div>

        <div className=" form-field-wrapper py-[4px]">
          <SelectSearchBox
            name="user_id"
            errorMsg={errors?.user_id?.message}
            validateStatus={errors?.user_id ? 'error' : ''}
            defaultValue=""
            placeholder="اختر المستخدم..."
            control={control}
            setValue={setValue}
            dataSearch={dataUsersId}
            value={watch}
            label={
              <p className="select-option-label">
                <EditOutlined />
                اختر المستخدم
              </p>
            }
          />
        </div>
        <div className=" form-field-wrapper py-[4px]">
          <SelectSearchBox
            name="setter_id"
            errorMsg={errors?.setter_id?.message}
            validateStatus={errors?.setter_id ? 'error' : ''}
            defaultValue=""
            placeholder="اختر المربيه..."
            control={control}
            setValue={setValue}
            dataSearch={dataSetterId}
            value={watch}
            label={
              <p className="select-option-label">
                <EditOutlined />
                اختر المربيه
              </p>
            }
          />
        </div> */}
        {/* <div className="form-field-wrapper">
          {!selectedServId && (
            <AntdTextField
              name="image"
              type="file"
              placeholder="موضوع..."
              errorMsg={errors?.image?.message}
              validateStatus={errors?.image ? 'error' : ''}
              prefix={<EditOutlined />}
              control={control}
            />
          )}
        </div> */}

        <Button
          className="submit-btn mx-auto mt-[30px]  !h-[45px] !w-[100px]   !flex justify-center items-center"
          htmlType="submit"
          type="primary"
          icon={<SendOutlined />}
          loading={isSubmitting}>
          اضافه
        </Button>
      </div>
    </Form>
  );
};

export default CreateTicketsThreads;

import { Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import getAllUsers from '../../apis/users-api/getAllUsers';
import UesrContext from '../../contexts/user-context/UserProvider';
import UsersColumns from './usersColumns';
const UsersTable = ({ filterValue }) => {
  const [allUsers, setAllUsers] = useState();
  const {
    user,
    setUserFetchCount,
    userFetchCount,
    setLoadingUser,
    loadingUser,
    userDetails,
    setUserDetails,
    showDetails,
    setShowDetails,
    viewEditUser,
    setViewEditUser
  } = useContext(UesrContext);

  useEffect(() => {
    const getUsers = async () => {
      try {
        setLoadingUser(true);
        const getUsersRes = await getAllUsers(user?.token);
        // console.log(getUsersRes)

        // if (getUsersRes?.status === 200) {
        //   setLoadingUser(false)
        //   setAllUsers(getUsersRes?.data);
        //   console.log(getUsersRes?.data);
        // }
        if (getUsersRes.status === 200 && getUsersRes?.data?.users) {
          setAllUsers(getUsersRes?.data?.users);
          // console.log(getUsersRes?.data?.users)

          setLoadingUser(false);
        }
      } catch (error) {
        console.log('error', error);
        setLoadingUser(false);
      }
    };

    getUsers();
  }, [userFetchCount]);

  return (
    <div>
      <Table
        pagination={{ pageSize: 5 }}
        className="Orders-table"
        dataSource={allUsers}
        loading={loadingUser}
        columns={UsersColumns(
          user,
          setUserFetchCount,
          setLoadingUser,
          userDetails,
          setUserDetails,
          showDetails,
          setShowDetails,
          viewEditUser,
          setViewEditUser
        )}
        scroll={{ x: 1200 }}
      />
    </div>
  );
};

export default UsersTable;

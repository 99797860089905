import axios from 'axios';

const updateSitter = async (id, values, token) => {
  const {
    name,
    image,
    date_of_birth,
    email,
    gender,
    nationality,
    phone_code,
    phone,
    role,
    address,
    national_id,
    map_loc,
    hour_price,
    Professional_life,
    password
  } = values;
  try {
    const formData = new FormData();
    if (Array.isArray(image)) {
      formData.append('image', image[0]);
    }
    formData.append('name', name);
    formData.append('email', email);
    formData.append('date_of_birth', date_of_birth);
    formData.append('gender', gender);
    formData.append('nationality', nationality);
    formData.append('phone_code', phone_code);
    formData.append('phone', phone);
    formData.append('role', role);
    formData.append('address', address);
    formData.append('national_id', national_id);
    formData.append('long', map_loc?.long);
    formData.append('lat', map_loc?.lat);
    formData.append('hour_price', hour_price);
    formData.append('Professional_life', Professional_life);
    formData.append('password', password);

    const updateSitter = axios.post(`/setters/update/${id}`, formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token
      }
    });

    console.log(updateSitter);

    return updateSitter;
  } catch (error) {
    return error?.response;
  }
};

export default updateSitter;

import { useContext } from 'react';
import UesrContext from '../../contexts/user-context/UserProvider';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Descriptions, Spin } from 'antd';
import { useParams, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect, useState } from 'react';
import getUser from '../../apis/users-api/getUser';

const UserDetails = () => {
  /*   const { showDetails, userDetails ,setShowDetails } = useContext(UesrContext);
    console.log(userDetails) */

  const { user, setLoadingUser, loadingUser } = useContext(UesrContext);
  let history = useHistory();
  let param = useParams();
  const [targetUser, setTargetUser] = useState(null);

  useEffect(() => {
    const getTargetUser = async () => {
      setLoadingUser(true);
      try {
        const targetUserRes = await getUser(user?.token, param?.id);

        if (targetUserRes?.status === 200) {
          setLoadingUser(false);
          setTargetUser(targetUserRes?.data?.user);
        }
      } catch (error) {
        throw error;
      }
    };

    getTargetUser();
  }, []);

  return (
    <>
      {loadingUser ? (
        <div className="mx-auto mt-[100px] w-[100px]">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className="flex flex-row justify-between">
            <h2 className="text-[20px] ">بيانات المستخدم : </h2>
            <Button
              onClick={() => history.push('/users')}
              icon={<CloseOutlined />}
              size="small"></Button>
          </div>

          <div className="show_details">
            <div>
              <Descriptions bordered column={1}>
                {targetUser?.name && (
                  <Descriptions.Item label="الأسم">{targetUser?.name}</Descriptions.Item>
                )}
                {targetUser?.phone && (
                  <Descriptions.Item label="رقم الهاتف">{targetUser?.phone}</Descriptions.Item>
                )}
                {targetUser?.email && (
                  <Descriptions.Item label="البريد الالكتروني">
                    {targetUser?.email}
                  </Descriptions.Item>
                )}
                {targetUser?.address && (
                  <Descriptions.Item label="العنوان">{targetUser?.address}</Descriptions.Item>
                )}
                {targetUser?.phone_code && (
                  <Descriptions.Item label="كود الهاتف">{targetUser?.phone_code}</Descriptions.Item>
                )}
                {targetUser?.national_id && (
                  <Descriptions.Item label="رقم الهوية">
                    {targetUser?.national_id}
                  </Descriptions.Item>
                )}
                {targetUser?.date_of_birth && (
                  <Descriptions.Item label="تاريخ الميلاد">
                    {targetUser?.date_of_birth}
                  </Descriptions.Item>
                )}
                {targetUser?.gender && (
                  <Descriptions.Item label="النوع">{targetUser?.gender}</Descriptions.Item>
                )}
                {targetUser?.role && (
                  <Descriptions.Item label="Role">{targetUser?.role}</Descriptions.Item>
                )}
                {targetUser?.nationality && (
                  <Descriptions.Item label="الجنسية">{targetUser?.nationality}</Descriptions.Item>
                )}
              </Descriptions>
            </div>

            <div>
              <Descriptions>
                {targetUser?.image_url && (
                  <Descriptions.Item>
                    <img src={targetUser?.image_url} alt="user image" className="w-full" />
                  </Descriptions.Item>
                )}
              </Descriptions>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UserDetails;

import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { EditOutlined, SendOutlined } from '@ant-design/icons';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { Button, Form } from 'antd';
import { Store } from 'react-notifications-component';
import UesrContext from '../../contexts/user-context/UserProvider';
import CountarysContext from '../../contexts/countary-context/CountaryProvider';
import UploadCountaryFile from './UploadCountaryFile';
import createCountary from '../../apis/countary-api/createCountary';
import './CountryPage.scss';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import updateCountary from '../../apis/countary-api/updateCountryApi';
import getCountry from '../../apis/countary-api/getCountryApi';
import routerLinks from '../../components/app/routerLinks';

const UpdateCountry = () => {
  const { user } = useContext(UesrContext);
  const history = useHistory();

  const param = useParams();
  const {
    setFetchCount,
    selectedCountary,
    setSelectedCountary,
    loadingCountarys,
    setLoadingCountarys
  } = useContext(CountarysContext);

  const schema = Yup.object().shape({
    name: Yup.string().required('من فضلك ادخل البلد باللغة الانجليزية'),
    code: Yup.string()
      .required(' فضلك ادخل مفتاح الدوله')
      .matches(/^[0-9]+$/, 'لابد ان يحتوي علي ارقام فقط'),
    nationality: Yup.string().required('من فضلك ادخل الجنسية')
  });

  const defaultValues = {
    name: '',
    code: null,
    nationality: ''
  };
  const {
    control,
    getValues,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    register,
    watch,
    unregister,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });

  useEffect(() => {
    const getTargetCountry = async () => {
      try {
        const getTargetCountryRes = await getCountry(param?.id, user?.token);

        if (getTargetCountryRes?.status === 200) {
          setSelectedCountary(getTargetCountryRes?.data?.country_details);
        }
      } catch (error) {
        throw error;
      }
    };

    getTargetCountry();
  }, []);

  useEffect(() => {
    if (selectedCountary) {
      setValue('name', selectedCountary?.name);
      setValue('code', selectedCountary?.code);
      setValue('nationality', selectedCountary?.nationality);
    }
  }, [selectedCountary]);

  const onSubmit = async (data) => {
    setLoadingCountarys(true);
    try {
      const updateCountaryRes = await updateCountary(param?.id, data, user?.token);

      if (updateCountaryRes?.status === 200) {
        setLoadingCountarys(false);
        Store.addNotification({
          title: 'تمت العملية بنجاح',
          message: 'تمت تحديث البيانات بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
        history.push(routerLinks.CountaryPage);
      } else {
        setLoadingCountarys(false);

        Store.addNotification({
          title: 'حدث خطأ اثناء انشاء البلد',
          message: updateCountaryRes?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      setLoadingCountarys(false);
      Store.addNotification({
        title: 'حدث خطأ اثناء انشاء البلد',
        message: 'حدث خطأ اثناء انشاء البلد',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };

  const [form] = Form.useForm();

  return (
    <Form
      className="add-Countary-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}>
      <div className="form-body add_form_body mx-auto">
        <div className="form-field-wrapper">
          <AntdTextField
            name="name"
            type="text"
            label="اســم البلد باللغة الانجليزية"
            placeholder="اســم البلد باللغة الانجليزية..."
            errorMsg={errors?.name?.message}
            validateStatus={errors?.name ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
            setValue={setValue}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="code"
            type="text"
            label="مفتاح الدوله"
            placeholder="مفتاح الدوله..."
            errorMsg={errors?.code?.message}
            validateStatus={errors?.code ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
            setValue={setValue}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="nationality"
            type="text"
            label="الجنسية"
            placeholder="الجنسية..."
            errorMsg={errors?.nationality?.message}
            validateStatus={errors?.nationality ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
            setValue={setValue}
          />
        </div>
        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          icon={<SendOutlined />}
          loading={loadingCountarys}>
          تعديل البلد
        </Button>
      </div>
    </Form>
  );
};

export default UpdateCountry;

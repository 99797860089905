import { Descriptions } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ShowDetailApi from '../../apis/ShowDetailApi';
import UesrContext from '../../contexts/user-context/UserProvider';

const ShowTicketPage = () => {
  const { user } = useContext(UesrContext);
  const [dataShowTarget, setDataShowTarget] = useState(null);
  let param = useParams();
  useEffect(() => {
    const handleDataShow = async () => {
      try {
        const getTargetShowRes = await ShowDetailApi(`tickets/show/${param?.id}`, user?.token);
        console.log(getTargetShowRes);

        if (getTargetShowRes.status === 200 && getTargetShowRes?.data?.ticket_details) {
          setDataShowTarget(getTargetShowRes?.data?.ticket_details);
        }
      } catch (err) {
        setDataShowTarget(null);
      }
    };
    handleDataShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param?.id]);

  return (
    <>
      <div className="flex flex-row justify-between">
        <h2 className="text-[20px] ">كل التفاصيل : </h2>
        {/* <Button
          onClick={() => setShowDetails(false)}
          icon={<CloseOutlined />}
          size='small'
        >
        </Button> */}
      </div>

      <div className="flex justify-center  max-xl:items-center items-start max-xl:flex-col gap-[30px]  mt-[30px] ">
        <div className=" flex-1 w-full">
          <Descriptions bordered className=" w-full" column={1}>
            {dataShowTarget?.name && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="الأسم">
                {dataShowTarget?.name}
              </Descriptions.Item>
            )}
            {dataShowTarget?.phone && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="رقم الهاتف">
                {dataShowTarget?.phone}
              </Descriptions.Item>
            )}
            {dataShowTarget?.email && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="البريد الالكتروني">
                {dataShowTarget?.email}
              </Descriptions.Item>
            )}
            {dataShowTarget?.nationality && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="الجنسية">
                {dataShowTarget?.nationality}
              </Descriptions.Item>
            )}
            {dataShowTarget?.hobby && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="الهواية">
                {dataShowTarget?.hobby}
              </Descriptions.Item>
            )}
            {dataShowTarget?.date_of_birth && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="تاريخ الميلاد">
                {dataShowTarget?.date_of_birth}
              </Descriptions.Item>
            )}
            {dataShowTarget?.disease && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="المرض">
                {dataShowTarget?.disease}
              </Descriptions.Item>
            )}
            {dataShowTarget?.gender && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="النوع">
                {dataShowTarget?.gender}
              </Descriptions.Item>
            )}
            {dataShowTarget?.created_at && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="تاريخ الانشاء">
                {dataShowTarget?.created_at}
              </Descriptions.Item>
            )}
            {dataShowTarget?.problem && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="المشكلة">
                {dataShowTarget?.problem}
              </Descriptions.Item>
            )}
            {dataShowTarget?.problem && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="المشكلة">
                {dataShowTarget?.problem}
              </Descriptions.Item>
            )}
            {dataShowTarget?.description && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="الوصف">
                {dataShowTarget?.description}
              </Descriptions.Item>
            )}
            {dataShowTarget?.age && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="العمر">
                {dataShowTarget?.age}
              </Descriptions.Item>
            )}
            {dataShowTarget?.phone_code && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="رمز الهاتف">
                {dataShowTarget?.phone_code}
              </Descriptions.Item>
            )}
            {dataShowTarget?.address && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="العنوان">
                {dataShowTarget?.address}
              </Descriptions.Item>
            )}
            {dataShowTarget?.role && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="الصلاحيه">
                {dataShowTarget?.role}
              </Descriptions.Item>
            )}
            {/* {dataShowTarget?.role && (
              <Descriptions.Item className='!text-[17px] !font-[600]' label='الصلاحيه'>
                {dataShowTarget?.role}
              </Descriptions.Item>
            )} */}
          </Descriptions>
        </div>

        <div className=" flex-1 max-w-[700px]">
          <Descriptions>
            {dataShowTarget?.image_url && (
              <Descriptions.Item>
                <img src={dataShowTarget?.image_url} alt="user image" className="w-full" />
              </Descriptions.Item>
            )}
          </Descriptions>
        </div>
      </div>
    </>
  );
};

export default ShowTicketPage;

import React, { useContext, useEffect, useState } from 'react';
import CitysContext from '../../contexts/City-context/CityProvider';
import UesrContext from '../../contexts/user-context/UserProvider';
import { Table } from 'antd';
import './CityPage.scss';
import getAllCitys from '../../apis/city-api/getAllCity';
import CitysColumns from './cityColumns';
import CountarysContext from '../../contexts/countary-context/CountaryProvider';

const CityTable = () => {
  const { user } = useContext(UesrContext);
  const { selectedServId , setSelectedCountary } = useContext(CountarysContext);

  const {
    fetchCount,
    setFetchCount,
    loadingCitys,
    setLoadingCitys,
    allCitys,
    setAllCitys,
    setCityModalOpened,
    setShowAreaModalOpened,
    setSelectedCityId
  } = useContext(CitysContext);

  const [CityDataSource, setCityDataSource] = useState([]);

  useEffect(() => {
    
    const getCitys = async () => {
      try {
        setLoadingCitys(true);
        const getCitysRes = await getAllCitys(selectedServId, user?.token);
        
        if (getCitysRes.status === 200 && getCitysRes?.data?.data) {
          setAllCitys(getCitysRes.data.data);
          setLoadingCitys(false);
        }
      } catch (error) {
        setLoadingCitys(false);
        
      }
    };
    getCitys();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCount, selectedServId]);
  useEffect(() => {
    let isMounted = true;

    

    if (allCitys) {
      const mappedCity = allCitys.map((ques) => ({
        ...ques,
        key: ques.id
      }));

      if (isMounted) {
        setCityDataSource(mappedCity);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [allCitys, allCitys?.length, fetchCount, selectedServId]);
  
  return (
    <Table
      pagination={{ pageSize: 5 }}
      className="Orders-table"
      dataSource={CityDataSource}
      columns={CitysColumns(
        user?.token,
        setFetchCount,
        setLoadingCitys,
        setCityModalOpened,
        setSelectedCityId,
        setShowAreaModalOpened,
        setSelectedCountary
      )}
      loading={loadingCitys}
      scroll={{ x: 1200 }}
    />
  );
};

export default CityTable;

import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => {},
  loadingGoogleMap: false,
  setLoadingGoogleMap: (load) => {},
  allGoogleMap: [],
  setAllGoogleMap: (services) => {},
  answerModalOpened: false,
  setGoogleMapModalOpened: (v) => {},
  selectedGoogleMapId: '',
  setSelectedAsnswerId: (id) => {},
  selectedGoogleMap: null,
  setSelecteGoogleMap: (servKey) => {},
  lat: null,
  setLat: (servKey) => {},
  lng: null,
  setLng: (servKey) => {},
  
};

const GoogleMapContext = createContext(INITIAL_VALUES);

export const GoogleMapProvider = ({ children }) => {
  const [allGoogleMap, setAllGoogleMap] = useState(INITIAL_VALUES.allGoogleMap);
  const [loadingGoogleMap, setLoadingGoogleMap] = useState(INITIAL_VALUES.loadingGoogleMap);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [answerModalOpened, setGoogleMapModalOpened] = useState(INITIAL_VALUES.answerModalOpened);
  const [selectedGoogleMapId, setSelectedAsnswerId] = useState(INITIAL_VALUES.selectedGoogleMapId);
  const [selectedGoogleMap, setSelecteGoogleMap] = useState(INITIAL_VALUES.selectedGoogleMap);
  const [lat, setLat] = useState(INITIAL_VALUES.selectedGoogleMap);
  const [lng, setLng] = useState(INITIAL_VALUES.selectedGoogleMap);

  return (
    <GoogleMapContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        loadingGoogleMap,
        setLoadingGoogleMap,
        allGoogleMap,
        setAllGoogleMap,
        answerModalOpened,
        setGoogleMapModalOpened,
        selectedGoogleMapId,
        setSelectedAsnswerId,
        selectedGoogleMap,
        setSelecteGoogleMap,
        lat,
        setLat,
        lng,
        setLng
      }}>
      {children}
    </GoogleMapContext.Provider>
  );
};

export default GoogleMapContext;

import React, { useContext, useEffect, useState } from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import Geocode from "react-geocode";
import GoogleMapContext from '../../contexts/google-map-context/GoogleMapProvider';
import './googleMapsitter.scss'


Geocode.setApiKey(process.env.REACT_APP_MAP_KEY);
Geocode.setLanguage("ar");



const containerStyle = {
    width: '100%',
    height: '300px'
};

const GoogleMapSitter = () => {

    const {  setSelecteGoogleMap,setLoadingGoogleMap ,setLat,setLng,lat,lng} = useContext(GoogleMapContext);

    const [latt, setLatt] = useState(lat);
    const [lngg, setlngg] = useState(lng);
    const [map, setMap] = useState(null)



    const center = {
        lat: latt,
        lng: lngg
    };

    const onMapClicked = (props, map, e) => {

        setLatt(props.latLng.lat());
        setlngg(props.latLng.lng());
        setLat(props.latLng.lat());
        setLng(props.latLng.lng());

        Geocode.fromLatLng(props.latLng.lat(), props.latLng.lng()).then(

            (response) => {

                let address = response.results[3].formatted_address;

                if (address.length > 3) {
                    address = address.split('،')
                    address.splice(address.length - 2, 2)
                    let textAddress = address.join(',')
                    
                    setSelecteGoogleMap(textAddress)

                }
                else {
                    let textAddress = address.join(',')
                    
                    setSelecteGoogleMap(textAddress)
                }


            },
            (error) => {
            }
        );

        

    }

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_MAP_KEY
    })

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])


    useEffect(() => {
        navigator.geolocation.getCurrentPosition(function (position) {

            setLatt(position.coords.latitude);
            setLat(position.coords.latitude);
            
            setlngg(position.coords.longitude);
            setLng(position.coords.longitude);

            Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
                (response) => {

                    let address = response.results[3].formatted_address;

                    if (address.length > 3) {
                        address = address.split('،')
                        address.splice(address.length - 2, 2)
                        let textAddress = address.join(',')

                        setSelecteGoogleMap(textAddress)

                    }
                    else {
                        let textAddress = address.join(',')
                        setSelecteGoogleMap(textAddress)

                    }


                },
                (error) => {
                }
            );

        });


        // formRef?.current?.setFieldsValue({
        //   address: fulllocationDetails
        // })


    }, [])


    useEffect(() => {
      

            setLoadingGoogleMap(isLoaded)
        
    }, [isLoaded]);

    return (
        <>
            {isLoaded ? (<GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={10}
                onUnmount={onUnmount}
                onClick={onMapClicked}
                mapContainerClassName="google_map_sitter"
            >
                <Marker position={center} />
                <></>
            </GoogleMap>) : ""}
        </>

    )
}

export default GoogleMapSitter
import React, { useContext, useEffect, useState } from 'react';
import AreasContext from '../../contexts/area-context/AreaProvider';
import AreaTable from './AreaTable';
import { Modal } from 'antd';
import AreasModel from './AreasModel';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import './AreaPage.scss';
import CountarysContext from '../../contexts/countary-context/CountaryProvider';
import getAllCountarys from '../../apis/countary-api/getAllcountaries';
import UesrContext from '../../contexts/user-context/UserProvider';
import CitysContext from '../../contexts/City-context/CityProvider';
import {  useHistory } from 'react-router-dom';
import routerLinks from '../../components/app/routerLinks';
const AreaPage = () => {

  const { AreaModalOpened, selectedAreaId, setAreaModalOpened, setSelectedAreaId, setSelectedArea } =
    useContext(AreasContext);
  const { setAllCountarys } = useContext(CountarysContext);

  const { user } = useContext(UesrContext);

  const history = useHistory()

  const {
    setShowAreaModalOpened,
    selectedCityId
  } = useContext(CitysContext);

  const [allCountries, setAllCountries] = useState();


  const getCountarys = async () => {
    try {
      const getCountarysRes = await getAllCountarys(user?.token);

      if (getCountarysRes.status === 200 && getCountarysRes?.data?.data) {
        setAllCountries(getCountarysRes.data.data);
        setAllCountarys(getCountarysRes.data.data)

      }
    } catch (error) {


    }
  };

  useEffect(() => {

    getCountarys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    getCountarys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCityId]);

  return (
    <div className="Areas-page">
      <div className="new-filter-wrap">
        <Button
          onClick={() => {
           
            // setShowAreaModalOpened(false)
            setAreaModalOpened(true);
            if(selectedCityId){
              history.push(routerLinks.AreaPage)
            }
            
          }}
          className="add-serv-btn"
          type="primary"
          icon={<PlusOutlined />}>
          <span className="btn-text">
             اضافة منطقة
          
          </span>
        </Button>

        {/* <AreasFilter /> */}
      </div>
      <AreaTable />
      {AreaModalOpened && (
        <Modal
          className="add-Area-modal"
          width="90%"
          style={{ maxWidth: '900px' }}
          title={selectedAreaId ? 'تعديل المنطقة ' : 'اضافة منطقة جديدة'}
          open={AreaModalOpened}
          onOk={() => {
            setAreaModalOpened(false);
            setSelectedAreaId('');
            setSelectedArea(null);
          }}
          onCancel={() => {
            setAreaModalOpened(false);
            setSelectedAreaId('');
            setSelectedArea(null);
          }}
          footer={false}>
          <AreasModel allCountries={allCountries} />
        </Modal>
      )}
    </div>
  );
};

export default AreaPage;

import { Descriptions } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ShowDetailApi from '../../apis/ShowDetailApi';
import UesrContext from '../../contexts/user-context/UserProvider';
import GoogleMaps from '../GoogleMaps/GoogleMaps';

const ShowOrderPage = () => {
  const { user } = useContext(UesrContext);
  const [dataShowTarget, setDataShowTarget] = useState(null);
  let param = useParams();
  useEffect(() => {
    const handleDataShow = async () => {
      try {
        const getTargetShowRes = await ShowDetailApi(`orders/show/${param?.id}`, user?.token);
        // console.log(getTargetShowRes)

        if (getTargetShowRes.status === 200 && getTargetShowRes?.data?.order_details) {
          setDataShowTarget(getTargetShowRes?.data?.order_details);
        }
      } catch (err) {
        setDataShowTarget(null);
      }
    };
    handleDataShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param?.id]);

  return (
    <div className=" h-full ">
      <div className="flex flex-row justify-between">
        <h2 className="text-[20px] "> تفاصيل الطلب : </h2>
        {/* <Button
          onClick={() => setShowDetails(false)}
          icon={<CloseOutlined />}
          size='small'
        >
        </Button> */}
      </div>
      <div className="flex justify-center  max-xl:items-center items-start max-xl:flex-col gap-[30px]  mt-[30px] ">
        <div className=" flex-1 w-full">
          <Descriptions bordered className=" w-full" column={1}>
            {dataShowTarget?.date && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label=" من تاريخ">
                {dataShowTarget?.date}
              </Descriptions.Item>
            )}
            {dataShowTarget?.time && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="الوقت">
                {dataShowTarget?.time}
              </Descriptions.Item>
            )}
            {dataShowTarget?.days && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label=" عدد الأيام">
                {dataShowTarget?.days}
              </Descriptions.Item>
            )}
            {dataShowTarget?.hours && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="عدد الساعات">
                {dataShowTarget?.hours}
              </Descriptions.Item>
            )}
            {dataShowTarget?.order_activity && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="نشاط الطلب">
                {dataShowTarget?.order_activity}
              </Descriptions.Item>
            )}
            {dataShowTarget?.status && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="الحالة">
                {dataShowTarget?.status}
              </Descriptions.Item>
            )}

            {dataShowTarget?.created_at && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="تاريخ الانشاء">
                {dataShowTarget?.created_at}
              </Descriptions.Item>
            )}

            {/* {dataShowTarget?.role && (
              <Descriptions.Item className='!text-[17px] !font-[600]' label='الصلاحيه'>
                {dataShowTarget?.role}
              </Descriptions.Item>
            )} */}
          </Descriptions>
        </div>

        <div className=" flex-1 max-w-[700px]">
          <Descriptions>
            {dataShowTarget?.image_url && (
              <Descriptions.Item>
                <img src={dataShowTarget?.image_url} alt="user image" className="w-full" />
              </Descriptions.Item>
            )}
          </Descriptions>
          <GoogleMaps
            type={'show'}
            watch={''}
            setValue={() => {}}
            letTarget={dataShowTarget?.lat}
            lngTarget={dataShowTarget?.long}
            name={''}
            errorMsg={''}
          />
        </div>
      </div>
      <h2 className="text-[20px] mt-[40px] ">تفاصيل الأطفال : </h2>
      {dataShowTarget?.children?.length
        ? dataShowTarget?.children?.map((el) => {
            return (
              <div className="flex justify-center  max-xl:items-center items-start max-xl:flex-col gap-[30px]  mt-[20px] ">
                <div className=" flex-1 w-full">
                  <Descriptions bordered className=" w-full" column={1}>
                    {el?.name && (
                      <Descriptions.Item className="!text-[17px] !font-[600]" label="الأسم">
                        {el?.name}
                      </Descriptions.Item>
                    )}
                    {el?.phone && (
                      <Descriptions.Item className="!text-[17px] !font-[600]" label="رقم الهاتف">
                        {el?.phone}
                      </Descriptions.Item>
                    )}
                    {el?.email && (
                      <Descriptions.Item
                        className="!text-[17px] !font-[600]"
                        label="البريد الالكتروني">
                        {el?.email}
                      </Descriptions.Item>
                    )}
                    {el?.nationality && (
                      <Descriptions.Item className="!text-[17px] !font-[600]" label="الجنسية">
                        {el?.nationality}
                      </Descriptions.Item>
                    )}
                    {el?.hobby && (
                      <Descriptions.Item className="!text-[17px] !font-[600]" label="الهواية">
                        {el?.hobby}
                      </Descriptions.Item>
                    )}
                    {el?.date_of_birth && (
                      <Descriptions.Item className="!text-[17px] !font-[600]" label="تاريخ الميلاد">
                        {el?.date_of_birth}
                      </Descriptions.Item>
                    )}
                    {el?.disease && (
                      <Descriptions.Item className="!text-[17px] !font-[600]" label="المرض">
                        {el?.disease}
                      </Descriptions.Item>
                    )}
                    {el?.gender && (
                      <Descriptions.Item className="!text-[17px] !font-[600]" label="النوع">
                        {el?.gender}
                      </Descriptions.Item>
                    )}
                    {el?.created_at && (
                      <Descriptions.Item className="!text-[17px] !font-[600]" label="تاريخ الانشاء">
                        {el?.created_at}
                      </Descriptions.Item>
                    )}
                    {el?.note && (
                      <Descriptions.Item className="!text-[17px] !font-[600]" label="الملاحظات">
                        {el?.note}
                      </Descriptions.Item>
                    )}
                    {el?.age && (
                      <Descriptions.Item className="!text-[17px] !font-[600]" label="العمر">
                        {el?.age}
                      </Descriptions.Item>
                    )}
                    {el?.phone_code && (
                      <Descriptions.Item className="!text-[17px] !font-[600]" label="رمز الهاتف">
                        {el?.phone_code}
                      </Descriptions.Item>
                    )}
                    {el?.address && (
                      <Descriptions.Item className="!text-[17px] !font-[600]" label="العنوان">
                        {el?.address}
                      </Descriptions.Item>
                    )}
                    {el?.role && (
                      <Descriptions.Item className="!text-[17px] !font-[600]" label="الصلاحيه">
                        {el?.role}
                      </Descriptions.Item>
                    )}
                  </Descriptions>
                </div>

                <div className=" flex-1 max-w-[700px]  ">
                  <Descriptions>
                    {el?.image_url && (
                      <Descriptions.Item>
                        <img
                          src={el?.image_url}
                          alt="user image"
                          className="w-full max-h-[360px] object-contain"
                        />
                      </Descriptions.Item>
                    )}
                  </Descriptions>
                </div>
              </div>
            );
          })
        : null}

      <h2 className="text-[20px] mt-[40px] ">تفاصيل السائق : </h2>

      <div className="flex justify-center  max-xl:items-center items-start max-xl:flex-col gap-[30px]  mt-[20px]">
        <div className=" flex-1 w-full">
          <Descriptions bordered className=" w-full" column={1}>
            {dataShowTarget?.driver?.name && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="الأسم">
                {dataShowTarget?.driver?.name}
              </Descriptions.Item>
            )}
            {dataShowTarget?.driver?.phone && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="رقم الهاتف">
                {dataShowTarget?.driver?.phone}
              </Descriptions.Item>
            )}
            {dataShowTarget?.driver?.email && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="البريد الالكتروني">
                {dataShowTarget?.driver?.email}
              </Descriptions.Item>
            )}
            {dataShowTarget?.driver?.nationality && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="الجنسية">
                {dataShowTarget?.driver?.nationality}
              </Descriptions.Item>
            )}
            {dataShowTarget?.driver?.hobby && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="الهواية">
                {dataShowTarget?.driver?.hobby}
              </Descriptions.Item>
            )}
            {dataShowTarget?.driver?.date_of_birth && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="تاريخ الميلاد">
                {dataShowTarget?.driver?.date_of_birth}
              </Descriptions.Item>
            )}
            {dataShowTarget?.driver?.disease && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="المرض">
                {dataShowTarget?.driver?.disease}
              </Descriptions.Item>
            )}
            {dataShowTarget?.driver?.gender && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="النوع">
                {dataShowTarget?.driver?.gender}
              </Descriptions.Item>
            )}
            {dataShowTarget?.driver?.created_at && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="تاريخ الانشاء">
                {dataShowTarget?.driver?.created_at}
              </Descriptions.Item>
            )}
            {dataShowTarget?.driver?.note && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="الملاحظات">
                {dataShowTarget?.driver?.note}
              </Descriptions.Item>
            )}
            {dataShowTarget?.driver?.age && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="العمر">
                {dataShowTarget?.driver?.age}
              </Descriptions.Item>
            )}
            {dataShowTarget?.driver?.phone_code && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="رمز الهاتف">
                {dataShowTarget?.driver?.phone_code}
              </Descriptions.Item>
            )}
            {dataShowTarget?.driver?.address && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="العنوان">
                {dataShowTarget?.driver?.address}
              </Descriptions.Item>
            )}
            {dataShowTarget?.driver?.role && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="الصلاحيه">
                {dataShowTarget?.driver?.role}
              </Descriptions.Item>
            )}
          </Descriptions>
        </div>

        <div className=" flex-1 max-w-[700px]">
          <Descriptions>
            {dataShowTarget?.driver?.image_url && (
              <Descriptions.Item>
                <img
                  src={dataShowTarget?.driver?.image_url}
                  alt="user image"
                  className="w-full max-h-[360px] object-contain"
                />
              </Descriptions.Item>
            )}
          </Descriptions>
        </div>
      </div>
      <h2 className="text-[20px] mt-[40px] ">تفاصيل الأبوين : </h2>

      <div className="flex justify-center  max-xl:items-center items-start max-xl:flex-col gap-[30px]  mt-[20px]">
        <div className=" flex-1 w-full">
          <Descriptions bordered className=" w-full" column={1}>
            {dataShowTarget?.parent?.user?.name && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="الأسم">
                {dataShowTarget?.parent?.user?.name}
              </Descriptions.Item>
            )}
            {dataShowTarget?.parent?.user?.phone && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="رقم الهاتف">
                {dataShowTarget?.parent?.user?.phone}
              </Descriptions.Item>
            )}
            {dataShowTarget?.parent?.user?.email && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="البريد الالكتروني">
                {dataShowTarget?.parent?.user?.email}
              </Descriptions.Item>
            )}
            {dataShowTarget?.parent?.user?.nationality && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="الجنسية">
                {dataShowTarget?.parent?.user?.nationality}
              </Descriptions.Item>
            )}
            {dataShowTarget?.parent?.hobby && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="الهواية">
                {dataShowTarget?.parent?.hobby}
              </Descriptions.Item>
            )}
            {dataShowTarget?.parent?.user?.date_of_birth && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="تاريخ الميلاد">
                {dataShowTarget?.parent?.user?.date_of_birth}
              </Descriptions.Item>
            )}
            {dataShowTarget?.parent?.user?.disease && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="المرض">
                {dataShowTarget?.parent?.user?.disease}
              </Descriptions.Item>
            )}
            {dataShowTarget?.parent?.user?.gender && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="النوع">
                {dataShowTarget?.parent?.user?.gender}
              </Descriptions.Item>
            )}
            {dataShowTarget?.parent?.user?.created_at && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="تاريخ الانشاء">
                {dataShowTarget?.parent?.user?.created_at}
              </Descriptions.Item>
            )}
            {dataShowTarget?.parent?.user?.note && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="الملاحظات">
                {dataShowTarget?.parent?.user?.note}
              </Descriptions.Item>
            )}
            {dataShowTarget?.parent?.user?.age && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="العمر">
                {dataShowTarget?.parent?.user?.age}
              </Descriptions.Item>
            )}
            {dataShowTarget?.parent?.user?.phone_code && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="رمز الهاتف">
                {dataShowTarget?.parent?.user?.phone_code}
              </Descriptions.Item>
            )}
            {dataShowTarget?.parent?.user?.address && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="العنوان">
                {dataShowTarget?.parent?.user?.address}
              </Descriptions.Item>
            )}
            {dataShowTarget?.parent?.user?.role && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="الصلاحيه">
                {dataShowTarget?.parent?.user?.role}
              </Descriptions.Item>
            )}
          </Descriptions>
        </div>

        <div className=" flex-1 max-w-[700px]">
          <Descriptions>
            {dataShowTarget?.parent?.user?.image_url && (
              <Descriptions.Item>
                <img
                  src={dataShowTarget?.parent?.user?.image_url}
                  alt="user image"
                  className="w-full max-h-[360px] object-contain"
                />
              </Descriptions.Item>
            )}
          </Descriptions>
        </div>
      </div>
      <h2 className="text-[20px] mt-[40px] ">تفاصيل المجموعات : </h2>

      <div className="flex justify-center  max-xl:items-center items-start max-xl:flex-col gap-[30px]  mt-[20px]">
        <div className=" flex-1 w-full">
          <Descriptions bordered className=" w-full" column={1}>
            {dataShowTarget?.setter?.user?.name && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="الأسم">
                {dataShowTarget?.setter?.user?.name}
              </Descriptions.Item>
            )}
            {dataShowTarget?.setter?.user?.phone && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="رقم الهاتف">
                {dataShowTarget?.setter?.user?.phone}
              </Descriptions.Item>
            )}
            {dataShowTarget?.setter?.user?.email && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="البريد الالكتروني">
                {dataShowTarget?.setter?.user?.email}
              </Descriptions.Item>
            )}
            {dataShowTarget?.setter?.user?.nationality && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="الجنسية">
                {dataShowTarget?.setter?.user?.nationality}
              </Descriptions.Item>
            )}
            {dataShowTarget?.setter?.hobby && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="الهواية">
                {dataShowTarget?.setter?.hobby}
              </Descriptions.Item>
            )}
            {dataShowTarget?.setter?.user?.date_of_birth && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="تاريخ الميلاد">
                {dataShowTarget?.setter?.user?.date_of_birth}
              </Descriptions.Item>
            )}
            {dataShowTarget?.setter?.user?.disease && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="المرض">
                {dataShowTarget?.setter?.user?.disease}
              </Descriptions.Item>
            )}
            {dataShowTarget?.setter?.user?.gender && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="النوع">
                {dataShowTarget?.setter?.user?.gender}
              </Descriptions.Item>
            )}
            {dataShowTarget?.setter?.user?.created_at && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="تاريخ الانشاء">
                {dataShowTarget?.setter?.user?.created_at}
              </Descriptions.Item>
            )}
            {dataShowTarget?.setter?.user?.note && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="الملاحظات">
                {dataShowTarget?.setter?.user?.note}
              </Descriptions.Item>
            )}
            {dataShowTarget?.setter?.user?.age && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="العمر">
                {dataShowTarget?.setter?.user?.age}
              </Descriptions.Item>
            )}
            {dataShowTarget?.setter?.user?.phone_code && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="رمز الهاتف">
                {dataShowTarget?.setter?.user?.phone_code}
              </Descriptions.Item>
            )}
            {dataShowTarget?.setter?.user?.address && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="العنوان">
                {dataShowTarget?.setter?.user?.address}
              </Descriptions.Item>
            )}
            {dataShowTarget?.setter?.user?.role && (
              <Descriptions.Item className="!text-[17px] !font-[600]" label="الصلاحيه">
                {dataShowTarget?.setter?.user?.role}
              </Descriptions.Item>
            )}
          </Descriptions>
        </div>

        <div className=" flex-1 max-w-[700px]">
          <Descriptions>
            {dataShowTarget?.setter?.user?.image_url && (
              <Descriptions.Item>
                <img
                  src={dataShowTarget?.setter?.user?.image_url}
                  alt="user image"
                  className="w-full max-h-[360px] object-contain"
                />
              </Descriptions.Item>
            )}
          </Descriptions>
        </div>
      </div>
    </div>
  );
};

export default ShowOrderPage;

import { Table } from 'antd';
import React, { useContext, useEffect } from 'react';
import getAllParents from '../../apis/parents-api/getAllParents';
import ParentsContext from '../../contexts/parents-context/ParentsProvider';
import UesrContext from '../../contexts/user-context/UserProvider';
import ParentsColumns from './ParentsColumns';
const ParenstsTable = () => {
  const { user } = useContext(UesrContext);
  const {
    fetchCount,
    setFetchCount,
    loadingParents,
    setLoadingParents,
    allParents,
    setAllParents,
    setParentModalOpened,
    setSelectedParent
  } = useContext(ParentsContext);

  useEffect(() => {
    const getParents = async () => {
      try {
        setLoadingParents(true);
        const getParentsRes = await getAllParents(user?.token);
        // console.log(getParentsRes.data.parents);

        if (getParentsRes.status === 200 && getParentsRes?.data?.parents) {
          setAllParents(getParentsRes?.data?.parents);
          setLoadingParents(false);
        }
      } catch (error) {
        setLoadingParents(false);
      }
    };
    getParents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCount]);
  // console.log(allParents);
  return (
    <Table
      pagination={{ pageSize: 5 }}
      className="Orders-table"
      dataSource={allParents}
      columns={ParentsColumns(
        user?.token,
        setFetchCount,
        setLoadingParents,
        setParentModalOpened,
        setSelectedParent
      )}
      loading={loadingParents}
      scroll={{ x: 1200 }}
    />
  );
};

export default ParenstsTable;

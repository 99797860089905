import UserActionTypes from './user.types';

const userDataEntryReducer = (currState, action) => {
    switch (action.type) {
        case UserActionTypes.SET_DATA_ENTRY_USER_TO_STATE:
            return {
                ...currState,
                loggedInDataEntry: true,
                userDataEntry: action.payload
            };
        case UserActionTypes.SET_DATA_ENTRY_USER:
            return {
                ...currState,
                loggedInDataEntry: true,
                userDataEntry: action.payload
            };
        case UserActionTypes.DATA_ENTRY_REMOVE_USER:
            return {
                ...currState,
                loggedInDataEntry: false,
                userDataEntry: null
            };

        default:
            return currState;
    }
};

export default userDataEntryReducer;

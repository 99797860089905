import axios from 'axios';

const getSitter = async (token, id) => {
  try {
    const getSitterRes = await axios.get(`/setters/show/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token
      }
    });
    console.log(getSitterRes);
    return getSitterRes;
  } catch (error) {
    return error?.response;
  }
};

export default getSitter;

import axios from 'axios';

const createCountary = async (values, token) => {
  const { name, code, nationality } = values;
  try {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('code', code);
    formData.append('nationality', nationality);

    const createCountryRes = axios.post('/country/create', formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token
      }
    });

    return createCountryRes;
  } catch (error) {
    return error.massage;
  }
};

export default createCountary;

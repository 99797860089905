import axios from 'axios';


const CreateCertificatesApi = async (url, values, token) => {
  // console.log(values)
  const { image, setter_id, name, } = values;
  const formData = new FormData();

  if (Array.isArray(image)) {
    formData.append("image", image[0]);
  }
  formData.append("certificate_name", name);
  formData.append("setter_id", setter_id);
  try {
    // console.log(image[0])

    const createSerivceRes = await axios.post(url, formData, {
      headers: {
        Accept: 'application/json',

        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      }
    });
    console.log(createSerivceRes)

    return createSerivceRes;
  } catch (error) {
    console.log(error?.response)
    return error?.response;

  }
};

export default CreateCertificatesApi;


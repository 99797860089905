import { Input, Form, Button } from 'antd';
import { useForm } from 'react-hook-form';
import { SendOutlined, EditOutlined } from '@ant-design/icons';
import { useContext, useEffect, useState } from 'react';
import UesrContext from '../../contexts/user-context/UserProvider';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import DatePickerBox from '../../common/DatePickerBox/DatePickerBox';
import updateUser from '../../apis/users-api/UpdateUser';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import AntdTextarea from '../../common/antd-form-components/AntdTextarea';
import getUser from '../../apis/users-api/getUser';
import { Store } from 'react-notifications-component';
import UploadQuestionFile from '../../common/file-input/UploadQuestionFile';
import createSitter from '../../apis/sitters-api/CreateSitterApi';
import SittersContext from '../../contexts/sitters-context/SittersProvider';
import getAllCountarys from '../../apis/countary-api/getAllcountaries';
import SelectSearchBox from '../../common/SelectSearchBox/SelectSearchBox';
import GoogleMaps from '../GoogleMaps/GoogleMaps';
const CreateSitter = () => {
  const { user } = useContext(UesrContext);
  const { loadingSitters, setLoadingSitters } = useContext(SittersContext);
  const [nationalityOptions, setNationalityOptions] = useState([]);
  const [phoneCodeOptions, setPhoneCodeOptions] = useState([]);
  const [allCountarys, setAllCountarys] = useState(null);
  const [tokenPhoneError, setTokenPhoneError] = useState([]);
  const [tokenEmailError, setTokenEmailError] = useState([]);
  let param = useParams();
  let history = useHistory();

  let schema = Yup.object().shape({
    name: Yup.string().required('من فضلك أدخل الأسم'),
    image: Yup.mixed().required('من فضلك أدخل المطلوب'),
    date_of_birth: Yup.mixed().required('اختارالتاريخ '),
    email: Yup.string().required('البريد الألكتروني').email('من فضلك ادخل بريد الكترونى صحيح'),
    gender: Yup.string().required('من فضلك أدخل المطلوب'),
    nationality: Yup.string().required('من فضلك أدخل المطلوب'),
    phone_code: Yup.string().required('من فضلك أدخل الكود'),
    phone: Yup.string().required('من فضلك أدخل رقم الهاتف'),
    role: Yup.string().required('من فضلك أدخل المطلوب'),
    map_loc: Yup.mixed().required(' من فضلك أدخل الوصف مطلوب'),

    address: Yup.string().required('من فضلك أدخل العنوان'),
    national_id: Yup.string()
      .required('من فضلك أدخل رقم الهوية')
      .test('len', 'رقم الهوية يجب ألا يقل عن 10 ارقام', (val) => val?.length >= 10),
    password: Yup.string()
      .required('يجب ادخال الرقم السري')
      .test('len', 'الرقم السري يجب ألا يقل عن 8 أرقام', (val) => val?.length >= 8)
  });

  useEffect(() => {
    const getCountarys = async () => {
      let nationalityList = [];
      let phoneCodeList = [];

      try {
        const getCountarysRes = await getAllCountarys(user?.token);

        if (getCountarysRes.status === 200 && getCountarysRes?.data) {
          setAllCountarys(getCountarysRes?.data?.countries);
          getCountarysRes?.data?.countries.map((country) => {
            nationalityList.push({ name: country?.nationality, id: country?.nationality });
            phoneCodeList.push({ name: country?.code, id: country?.code });
          });

          setNationalityOptions(nationalityList);
          setPhoneCodeOptions(phoneCodeList);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getCountarys();
  }, []);
  const onSubmit = async (data) => {
    try {
      setLoadingSitters(true);
      const createSitterRes = await createSitter(data, user?.token);

      if (createSitterRes?.status === 200) {
        setLoadingSitters(false);
        setTokenEmailError('');
        setTokenPhoneError('');
        Store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم اضافة بيانات المربية',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
        history.push('/sitters');
      } else {
        setLoadingSitters(false);
        Store.addNotification({
          title: 'حدث خطأ ',
          message: createSitterRes?.data?.message || 'تأكد من صحة البيانات المدخلة ',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      setLoadingSitters(false);
      console.log('');
      const errorMessage = error?.response?.data?.message;
      errorMessage &&
        errorMessage.map((er) => {
          if (er === 'The phone has already been taken.') {
            setTokenPhoneError('هذا الرقم مستخدم بالفعل ...');
          } else {
            setTokenPhoneError('');
          }
          if (er === 'The email has already been taken.') {
            setTokenEmailError('البريد الالكتروني مستخدم من قبل ...');
          } else {
            setTokenEmailError('');
          }
        });
      Store.addNotification({
        title: 'حدث خطأ ',
        message: error?.response?.data?.message || 'تأكد من صحة البيانات المدخلة ',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };

  const {
    control,
    setValue,
    handleSubmit,
    register,
    watch,
    unregister,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all'
  });

  const { form } = Form.useForm();

  return (
    <>
      <Form
        className="add-Selles-form flex justify-center items-center"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}>
        <div className="form-body add_form_body">
          <div className="form-field-wrapper">
            <AntdTextField
              name="name"
              type="text"
              placeholder="الاسم..."
              label="الأسم : "
              errorMsg={errors?.name?.message}
              validateStatus={errors?.name ? 'error' : ''}
              prefix={<EditOutlined />}
              control={control}
            />
          </div>

          <div className="form-field-wrapper">
            <AntdTextField
              name="phone"
              type="text"
              placeholder="الهاتف..."
              label="رقم الهاتف : "
              errorMsg={
                errors?.phone?.message || (
                  <span className="text-[#ff4d4f] mx-[13px]">{tokenPhoneError}</span>
                )
              }
              validateStatus={errors?.phone ? 'error' : ''}
              prefix={<EditOutlined />}
              control={control}
            />
          </div>

          <div className="form-field-wrapper">
            <AntdTextField
              name="email"
              type="text"
              placeholder="البريد الالكتروني ..."
              label=" البريد الالكتروني : "
              errorMsg={
                errors?.email?.message || (
                  <span className="text-[#ff4d4f] mx-[13px]">{tokenEmailError}</span>
                )
              }
              validateStatus={errors?.email ? 'error' : ''}
              prefix={<EditOutlined />}
              control={control}
            />
          </div>

          <div className="form-field-wrapper">
            <AntdTextField
              name="address"
              type="text"
              placeholder="العنوان ..."
              label=" العنوان : "
              errorMsg={errors?.address?.message}
              validateStatus={errors?.address ? 'error' : ''}
              prefix={<EditOutlined />}
              control={control}
            />
          </div>

          <div className="form-field-wrapper">
            <AntdTextField
              name="national_id"
              type="text"
              placeholder="رقم الهوية ..."
              label=" رقم الهوية : "
              errorMsg={errors?.national_id?.message}
              validateStatus={errors?.national_id ? 'error' : ''}
              prefix={<EditOutlined />}
              control={control}
            />
          </div>

          {/* <div className="form-field-wrapper">
          <AntdTextField
            name="phone_code"
            type="text"
            placeholder="كود الهاتف  ..."
            label=' كود الهاتف  : '
            errorMsg={errors?.phone_code?.message}
            validateStatus={errors?.phone_code ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div> */}
          <div className=" form-field-wrapper">
            <SelectSearchBox
              name="phone_code"
              errorMsg={errors?.phone_code?.message}
              validateStatus={errors?.phone_code ? 'error' : ''}
              defaultValue=""
              control={control}
              setValue=""
              dataSearch={phoneCodeOptions}
              placeholder="كود الهاتف  ..."
              label=" كود الهاتف  : "
              value={watch}
            />
          </div>

          <div className=" form-field-wrapper py-[10px]">
            <DatePickerBox
              name="date_of_birth"
              errorMsg={errors?.date_of_birth?.message}
              validateStatus={errors?.date_of_birth ? 'error' : ''}
              defaultValue=""
              control={control}
              setValue={setValue}
              watch={watch}
              label={
                <p className="select-option-label">
                  <EditOutlined />
                  تاريخ الميلاد
                </p>
              }
            />
          </div>

          <div className="form-field-wrapper">
            <AntdSelectOption
              name="gender"
              errorMsg={errors?.gender?.message}
              validateStatus={errors?.gender ? 'error' : ''}
              control={control}
              setValue={setValue}
              options={[
                { title: 'male', value: 'male' },
                { title: 'female', value: 'female' }
              ]}
              label={
                <p className="select-option-label">
                  <EditOutlined />
                  النوع
                </p>
              }
              formClassName="add-Selles-form"
            />
          </div>

          <div className="form-field-wrapper">
            <AntdSelectOption
              name="role"
              errorMsg={errors?.role?.message}
              validateStatus={errors?.role ? 'error' : ''}
              control={control}
              setValue={setValue}
              options={[
                { title: 'user', value: 'user' },
                { title: 'admin', value: 'admin' }
              ]}
              label={
                <p className="select-option-label">
                  <EditOutlined />
                  الصلاحيات
                </p>
              }
              formClassName="add-Selles-form"
            />
          </div>

          {/* <div className="form-field-wrapper">
            <AntdTextField
              name="role"
              type="text"
              placeholder="role..."
              label="Role  : "
              errorMsg={errors?.role?.message}
              validateStatus={errors?.role ? 'error' : ''}
              prefix={<EditOutlined />}
              control={control}
            />
          </div> */}

          {/* <div className="form-field-wrapper">
          <AntdTextField
            name="nationality"
            type="text"
            placeholder="الجنسية..."
            label='الجنسية : '
            errorMsg={errors?.nationality?.message}
            validateStatus={errors?.nationality ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div> */}

          <div className=" form-field-wrapper">
            <SelectSearchBox
              name="nationality"
              errorMsg={errors?.nationality?.message}
              validateStatus={errors?.nationality ? 'error' : ''}
              defaultValue=""
              control={control}
              setValue=""
              dataSearch={nationalityOptions}
              placeholder="الجنسية..."
              label="الجنسية : "
              value={watch}
            />
          </div>

          <div className="form-field-wrapper">
            <AntdTextField
              name="hour_price"
              type="text"
              placeholder="الأجر..."
              label="الأجر/ساعة : "
              errorMsg={errors?.hour_price?.message}
              validateStatus={errors?.hour_price ? 'error' : ''}
              prefix={<EditOutlined />}
              control={control}
            />
          </div>

          <div className="form-field-wrapper">
            <AntdTextField
              name="Professional_life"
              type="text"
              placeholder="طريقة العمل..."
              label="طريقة العمل	: "
              errorMsg={errors?.Professional_life?.message}
              validateStatus={errors?.Professional_life ? 'error' : ''}
              prefix={<EditOutlined />}
              control={control}
            />
          </div>

          <div className="form-field-wrapper">
            <AntdTextarea
              name="hint"
              type="text"
              placeholder="ملاحظات..."
              label="ملاحظات : "
              errorMsg={errors?.hint?.message}
              validateStatus={errors?.hint ? 'error' : ''}
              prefix={<EditOutlined />}
              control={control}
            />
          </div>

          <div className="form-field-wrapper">
            <AntdTextField
              name="password"
              type="password"
              placeholder="الرقم السري..."
              label="الرقم السري : "
              errorMsg={errors?.password?.message}
              validateStatus={errors?.password ? 'error' : ''}
              prefix={<EditOutlined />}
              control={control}
            />
          </div>
          <GoogleMaps
            type={'create'}
            setValue={setValue}
            name={'map_loc'}
            errorMsg={errors?.map_loc ? errors?.map_loc?.message : ''}
          />
          <div className="form-field-wrapper">
            <UploadQuestionFile
              // accept="image/png, image/jpg, image/jpeg, image/gif"
              maxFiles={1}
              name="image"
              setValue={setValue}
              watch={watch}
              imageTarget={Array.isArray(watch('image')) ? null : watch('image')}
              register={register}
              unregister={unregister}
              multiple={false}
              label={
                <>
                  <EditOutlined />
                  الصوره
                </>
              }
              errorMsg={errors?.image ? errors?.image?.message : ''}
            />
          </div>

          <Button
            className="submit-btn mx-auto mt-[30px]  !h-[45px] !w-[100px]   !flex justify-center items-center"
            htmlType="submit"
            type="primary"
            loading={loadingSitters}
            icon={<SendOutlined />}>
            اضافة
          </Button>
        </div>
      </Form>
    </>
  );
};

export default CreateSitter;

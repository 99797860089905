import axios from 'axios';

const getAllRates = async (token) => {
  try {
    const getRatesRes = await axios.post(
      '/rates/index',
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'applications/json',
          Authorization: 'Bearer ' + token
        }
      }
    );
    console.log(getRatesRes);
    return getRatesRes;
  } catch (error) {
    return error?.response;
  }
};

export default getAllRates;

// npm i -S @react-google-maps/api
import { GoogleMap, MarkerF, useLoadScript } from '@react-google-maps/api';
import { useEffect, useRef, useState } from 'react';
import GetCurrentLocation from './GetCurrentLocation';

function GoogleMaps({ type, watch, letTarget, lngTarget, errorMsg, name, setValue }) {
  const [choosenLocCoord, setChoosenLocCoord] = useState({
    lat: '30.78',
    lng: '31.26'
  });
  const [center, setCenter] = useState({
    lat: '30.78',
    lng: '31.26'
  });
  const [resultTo, setResultTo] = useState(null);
  let { getLocation, result } = GetCurrentLocation();

  const getToLoc = (e) => {
    if (type !== 'show') {
      console.log('getToLoc', e);
      const lat = e.latLng.lat();
      const long = e.latLng.lng();
      setChoosenLocCoord({ lat: lat, lng: long });
      getLocationToName(lat, long);
    }
  };

  // show map
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyB8LNfxjQ6S8WJDw_0_fQbhV4HfXOeulQ4'
  });

  // lat , long  to location name

  const getLocationToName = async (lat, lng) => {
    try {
      const response = await fetch(
        // `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyB8LNfxjQ6S8WJDw_0_fQbhV4HfXOeulQ4`
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_MAP_KEY}`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      // console.log(data)
      const formattedAddress = data.results[0]?.geometry?.location;
      setResultTo(formattedAddress);
      setValue(name, {
        lat: formattedAddress.lat,
        long: formattedAddress.lng
      });
      return formattedAddress;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const containerStyle = {
    width: '100%',
    height: ' 300px',
    margin: '10px auto'
  };
  // get current location
  const GetLocRef = useRef(true);
  useEffect(() => {
    if (type !== 'update' && GetLocRef.current) {
      getLocation();
      GetLocRef.current = false;
    }
  }, [type]);
  // get current marker location

  useEffect(() => {
    if (type == 'update') {
      console.log('update');
      setChoosenLocCoord({
        lat: +letTarget,
        lng: +lngTarget
      });
      setCenter({
        lat: +letTarget,
        lng: +lngTarget
      });
      setResultTo({
        lat: letTarget,
        lng: lngTarget
      });
    }
  }, [type, letTarget, lngTarget]);

  useEffect(() => {
    if (type == 'show') {
      setChoosenLocCoord({
        lat: +letTarget,
        lng: +lngTarget
      });
      setCenter({
        lat: +letTarget,
        lng: +lngTarget
      });
      setResultTo({
        lat: letTarget,
        lng: lngTarget
      });
    }
  }, [type, letTarget, lngTarget]);
  useEffect(() => {
    if (result) {
      setChoosenLocCoord({
        lat: result?.geometry?.location?.lat,
        lng: result?.geometry?.location?.lng
      });
      setValue(name, {
        lat: result?.geometry?.location?.lat,
        long: result?.geometry?.location?.lng
      });
    }
  }, [result]);
  // set  center location
  useEffect(() => {
    if (result) {
      setCenter({
        lat: result?.geometry?.location?.lat,
        lng: result?.geometry?.location?.lng
      });
    }
  }, [result]);
  //   console.log(resultTo);
  //   console.log(center);
  //   console.log(choosenLocCoord);

  return (
    <div className=" my-[20px]">
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={15}
          onClick={(e) => {
            getToLoc(e);
          }}>
          <MarkerF position={choosenLocCoord}></MarkerF>
        </GoogleMap>
      )}
      {errorMsg && <p className="error-p text-[#ff4d4f]">{errorMsg}</p>}

      {/* <div className="">Location Name : {resultTo}</div> */}

      {/* <GetCurrentLocation /> */}
    </div>
  );
}

export default GoogleMaps;

import axios from 'axios';

const getSittersUserID = async (token) => {
  try {
    const getSittersIdRes = await axios.get('/setters/all_setters_users', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token
      }
    });

    return getSittersIdRes;
  } catch (error) {
    return error?.response;
  }
};

export default getSittersUserID;

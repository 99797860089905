import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ParentsContext from '../../contexts/parents-context/ParentsProvider';
import ParenstsTable from './ParenstsTable';
import ParentsModel from './ParentsModel';

const ParentsPage = () => {
  let history = useHistory();

  const { ParentModalOpened, setParentModalOpened, setSelectedServId, setSelectedParent, selectedParent } =
    useContext(ParentsContext);



  return (
    <div>
      <Button
        onClick={() => {
          history.push("/parent/create")
        }}
        className="add-serv-btn"
        type="primary"
        icon={<PlusOutlined />}>
        <span className="btn-text">اضافة أب</span>

      </Button>
      <ParenstsTable />
      {/* {ParentModalOpened && (
        <Modal
          className="add-Order-modal"
          width="90%"
          style={{ maxWidth: '900px' }}
          title={'تفاصيل الاب'}
          open={ParentModalOpened}
          onOk={() => {
            setParentModalOpened(false);
            setSelectedParent(null);
          }}
          onCancel={() => {
            setParentModalOpened(false);
            setSelectedParent(null);
          }}
          footer={false}>
          <ParentsModel />
        </Modal>
      )} */}
    </div>
  );
};

export default ParentsPage;

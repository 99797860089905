import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  rateFetchCount: 0,
  setRateFetchCount: (count) => {},
  loadingRate: false,
  setLoadingRate: (load) => {},
  allRates: [],
  setAllRates: (Orders) => {},
  rateDetails: '',
  setRateDetails: (Orders) => {},
  targetRate: '',
  setTargetRate: (Orders) => {}
};

const RateContext = createContext(INITIAL_VALUES);

export const RateProvider = ({ children }) => {
  const [allRates, setAllRates] = useState(INITIAL_VALUES.allRates);
  const [loadingRate, setLoadingRate] = useState(INITIAL_VALUES.loadingRate);
  const [rateFetchCount, setRateFetchCount] = useState(INITIAL_VALUES.rateFetchCount);
  const [rateDetails, setRateDetails] = useState(INITIAL_VALUES.rateDetails);
  const [targetRate, setTargetRate] = useState(INITIAL_VALUES.targetRate);

  return (
    <RateContext.Provider
      value={{
        rateFetchCount,
        setRateFetchCount,
        loadingRate,
        setLoadingRate,
        allRates,
        setAllRates,
        rateDetails,
        setRateDetails,
        targetRate,
        setTargetRate
      }}>
      {children}
    </RateContext.Provider>
  );
};

export default RateContext;

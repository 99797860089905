import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => {},
  loadingParents: false,
  setLoadingParents: (load) => {},
  allParents: [],
  setAllParents: (services) => {},
  ParentModalOpened: false,
  setParentModalOpened: (v) => {},
  selectedParentId: '',
  setSelectedParentId: (id) => {},
  selectedParent: null,
  setSelectedParent: (servKey) => {},
  selectedFilter: '',
  setSelectedFilter: (v) => {}
};

const ParentsContext = createContext(INITIAL_VALUES);

export const ParentsProvider = ({ children }) => {
  const [selectedFilter, setSelectedFilter] = useState(INITIAL_VALUES.selectedFilter);
  const [allParents, setAllParents] = useState(INITIAL_VALUES.allParents);
  const [loadingParents, setLoadingParents] = useState(INITIAL_VALUES.loadingParents);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [ParentModalOpened, setParentModalOpened] = useState(INITIAL_VALUES.ParentModalOpened);
  const [selectedParentId, setSelectedParentId] = useState(INITIAL_VALUES.selectedParentId);
  const [selectedParent, setSelectedParent] = useState(INITIAL_VALUES.selectedParent);

  return (
    <ParentsContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        loadingParents,
        setLoadingParents,
        selectedFilter,
        setSelectedFilter,
        allParents,
        setAllParents,
        ParentModalOpened,
        setParentModalOpened,
        selectedParentId,
        setSelectedParentId,
        selectedParent,
        setSelectedParent
      }}>
      {children}
    </ParentsContext.Provider>
  );
};

export default ParentsContext;

import { DeleteOutlined, EditOutlined, InfoOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Tooltip } from 'antd';

import { Store } from 'react-notifications-component';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import deleteParentApi from '../../apis/parents-api/deleteParent';

const ParentsColumns = (
  token,
  setFetchCount,
  setLoadingParents,
  setParentModalOpened,
  setSelectedParent
) => {
  const handleDeleteRequest = async (record) => {
    try {
      setLoadingParents(true);
      const res = await deleteParentApi(record?.id, token);
      if (res.status === 200 && res.data?.status_code === 200) {
        setFetchCount((prevState) => prevState + 1);
        setLoadingParents(false);
        Store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم حذف الأب بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        setLoadingParents(false);
        Store.addNotification({
          title: 'حدث خطأ ',
          message: res?.data?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      setLoadingParents(false);
      Store.addNotification({
        title: 'حدث خطأ ',
        message: 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };
  let history = useHistory();

  const handleViewRequest = (key) => {
    setParentModalOpened(true);
    setSelectedParent(key);
  };

  return [
    {
      title: '#',
      dataIndex: 'id',
      width: 62,
      render: (_, record) => {
        // console.log(record?.user?.id)
        return <>{record?.id}</>;
      }
    },
    {
      title: 'الأسم',
      dataIndex: 'name',
      width: 192,
      render: (_, record) => {
        // console.log(record?.user?.id)
        return <>{record?.user?.name}</>;
      }
    },
    // {
    //   title: 'صورة الأب',
    //   dataIndex: 'image',
    //   width: 180,
    //   render: (_, record) => {
    //     return (
    //       <div className="service-img">
    //         <img src={record.image} alt={record.username} width="200" />
    //       </div>
    //     );
    //   }
    // },
    {
      title: 'صورة الأب',
      dataIndex: 'image',
      width: 180,
      render: (_, record) => {
        console.log(record);
        return (
          <div className="service-img flex justify-center items-center ">
            <img src={record?.user?.image_url} alt={record?.user?.name} width="200" />
          </div>
        );
      }
    },
    {
      title: ' رقم الهاتف',
      dataIndex: 'phone',
      width: 180,
      render: (_, record) => {
        // console.log(record?.user?.id)
        return <>{record?.user?.phone}</>;
      }
    },
    {
      title: '  البريد الألكتروني',
      dataIndex: 'email',
      width: 180,
      render: (_, record) => {
        // console.log(record?.user?.id)
        return <>{record?.user?.email}</>;
      }
    },
    {
      title: 'الهوية الوطنية',
      dataIndex: 'national_id',
      width: 180,
      render: (_, record) => {
        // console.log(record?.user?.id)
        return <>{record?.user?.national_id}</>;
      }
    },
    {
      title: 'الدوله',
      dataIndex: 'nationality',
      width: 180,
      render: (_, record) => {
        // console.log(record?.user?.id)
        return <>{record?.user?.nationality}</>;
      }
    },
    {
      title: 'العنوان',
      dataIndex: 'address',
      width: 180,
      render: (_, record) => {
        // console.log(record?.user?.id)
        return <>{record?.user?.address}</>;
      }
    },
    {
      title: 'النوع',
      dataIndex: 'gender',
      width: 82,
      render: (_, record) => {
        // console.log(record?.user?.gender)
        return <> {record?.user?.gender}</>;
      }
    },
    {
      title: 'تاريخ الميلاد',
      dataIndex: 'date_of_birth',
      width: 82,
      render: (_, record) => {
        // console.log(record?.user?.id)
        return <>{record?.user?.date_of_birth}</>;
      }
    },
    // {
    //   title: 'تاريخ الانشاء',
    //   dataIndex: 'created_at',
    //   width: 82
    // },
    // {
    //   title: 'تاريخ التعديل',
    //   dataIndex: 'updated_at',
    //   width: 82
    // },

    {
      title: 'تفاصيل الأب ',
      dataIndex: 'view',
      width: 92,
      render: (_, record) => {
        return (
          <>
            <Button
              size="large"
              type="dashed"
              shape="circle"
              icon={<InfoOutlined />}
              onClick={(key) => history.push(`/parent/show/${record?.id}`)}></Button>
          </>
        );
      }
    },
    {
      title: 'حذف الأب',
      dataIndex: 'delete',
      width: 92,
      render: (_, record) => {
        return (
          <Popconfirm
            title={
              <div style={{ maxWidth: '200px' }}>
                <p>هل انت متأكد من حذف الأب</p>
                {/* <p>حذف الخدمة سيؤدى الى حذف جميع المشاريع والاسئلة الخاصة بالخدمة</p> */}
              </div>
            }
            okText="نعم"
            cancelText="لا"
            onConfirm={(key) => {
              handleDeleteRequest(record);
            }}>
            <Tooltip title="حذف الأب؟">
              <Button
                className="delete-btn"
                size="large"
                type="dashed"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        );
      }
    },
    {
      title: 'تعديل الأب',
      dataIndex: 'edit',
      width: 92,
      render: (_, record) => {
        return (
          <Tooltip title="تعديل الأب">
            <Button
              className="edit-btn"
              onClick={(key) => history.push(`/parent/update/${record?.id}`)}
              size="large"
              type="dashed"
              shape="circle"
              icon={<EditOutlined />}
            />
          </Tooltip>
        );
      }
    }
  ];
};

export default ParentsColumns;

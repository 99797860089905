import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => {},
  loadingContactUs: false,
  setLoadingContactUs: (load) => {},
  allContactUs: [],
  setAllContactUs: (services) => {},
  ContactUsModalOpened: false,
  setContactUsModalOpened: (v) => {},
  selectedContactUsId: '',
  setSelectedContactUsId: (id) => {},
  selectedContactUs: null,
  setSelectedContactUs: (servKey) => {},
  selectedFilter: '',
  setSelectedFilter: (v) => {}
};

const ContactUsContext = createContext(INITIAL_VALUES);

export const ContactUsProvider = ({ children }) => {
  const [selectedFilter, setSelectedFilter] = useState(INITIAL_VALUES.selectedFilter);
  const [allContactUs, setAllContactUs] = useState(INITIAL_VALUES.allContactUs);
  const [loadingContactUs, setLoadingContactUs] = useState(INITIAL_VALUES.loadingContactUs);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [ContactUsModalOpened, setContactUsModalOpened] = useState(INITIAL_VALUES.ContactUsModalOpened);
  const [selectedContactUsId, setSelectedContactUsId] = useState(INITIAL_VALUES.selectedContactUsId);
  const [selectedContactUs, setSelectedContactUs] = useState(INITIAL_VALUES.selectedContactUs);

  return (
    <ContactUsContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        loadingContactUs,
        setLoadingContactUs,
        selectedFilter,
        setSelectedFilter,
        allContactUs,
        setAllContactUs,
        ContactUsModalOpened,
        setContactUsModalOpened,
        selectedContactUsId,
        setSelectedContactUsId,
        selectedContactUs,
        setSelectedContactUs
      }}>
      {children}
    </ContactUsContext.Provider>
  );
};

export default ContactUsContext;

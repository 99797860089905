import { Button, Popconfirm, Popover, Tooltip } from 'antd';
import { DeleteOutlined, ReadOutlined } from '@ant-design/icons';
import countWords from '../../utils/countWords';
import trimWords from '../../utils/trimWords';
import { Store } from 'react-notifications-component';
import deleteSupportApi from '../../apis/supports-api/deleteSupport';

// const PopOverAnswers = ({ content, title }) => (
//   <Popover title={title} content={content} style={{ maxWidth: '280px' }}>
//     <Button size="large" type="dashed" shape="circle" icon={<EyeTwoTone />} />
//   </Popover>
// );

const SupportsColumns = (
  token,
  setFetchCount,
  setLoadingSupports,
  setSupportModalOpened,
  setSelectedServId
) => {
  // const handleEditSupport = (key) => {
  //   setSupportModalOpened(true);
  //   setSelectedServId(key);

  //   // 
  // };

  const handleDeleteRequest = async (key) => {
    try {
      setLoadingSupports(true);
      const res = await deleteSupportApi(key, token);
      if (res.status === 200 && res.data.status === 1) {
        setFetchCount((prevState) => prevState + 1);
        setLoadingSupports(false);
        Store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم حذف بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        setLoadingSupports(false);
        Store.addNotification({
          title: 'حدث خطأ ',
          message: res?.data?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      
      setLoadingSupports(false);
      Store.addNotification({
        title: 'حدث خطأ ',
        message: 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };
  return [
    {
      title: '#',
      dataIndex: 'key',
      width: 62
    },
    {
      title: 'الملاحظات',
      dataIndex: 'note',
      width: 192,
      render: (text) => {
        const wordsCount = countWords(text);
        if (wordsCount > 3) {
          return (
            <Popover title="اقرأ المزيد" content={text}>
              <Button size="large" type="text">
                <p className="has-read-more">
                  <span>{trimWords(text, 3)}</span>
                  <ReadOutlined className="read-more-book" />
                </p>
              </Button>
            </Popover>
          );
        } else return text;
      }
    },
    {
      title: 'صورة المربيه',
      dataIndex: 'image',
      width: 180,
      render: (_, record) => {
        return (
          <div className="service-img">
            <img src={record.sitter.image} alt={record.sitter.username} width="200" />
          </div>
        );
      }
    },
    {
      title: 'اسم المربيه',
      dataIndex: 'username',
      width: 180,
      render: (_, record) => {
        return record.sitter.username;
      }
    },
    {
      title: ' رقم الهاتف',
      dataIndex: 'sitter',
      width: 180,
      render: (_, record) => {
        return record.sitter.phone;
      }
    },
    {
      title: '  البريد الألكتروني',
      dataIndex: 'email',
      width: 180,
      render: (_, record) => {
        return record.sitter.email;
      }
    },
    // {
    //   title: 'الدوله',
    //   dataIndex: 'country',
    //   width: 180,
    //   render: (_, record) => {
    //     return record.country.name;
    //   }
    // },
    {
      title: 'النوع',
      dataIndex: 'gender',
      width: 82,
      render: (_, record) => {
        if (record.sitter.gender === 1) {
          return 'ذكر';
        } else {
          return 'مؤنث';
        }
      }
    },
    // {
    //   title: 'التقييم',
    //   dataIndex: 'rate',
    //   width: 192
    // },
    // {
    //   title: 'تاريخ الميلاد',
    //   dataIndex: 'dateOfBirth',
    //   width: 82
    // },
    // {
    //   title: 'ملاحظات',
    //   dataIndex: 'aboutSupport',
    //   width: 82,
    //   render: (V) => {
    //     if (V) {
    //       return <PopOverAnswers content={V} title="ملاحظات" />;
    //     }
    //     return 'لا توجد ملاحظات';
    //   }
    // },

    // {
    //   title: 'تفاصيل المربيه',
    //   dataIndex: 'edit',
    //   width: 92,
    //   render: (_, record) => {
    //     if (
    //       record.files.length > 0 ||
    //       record.roomImages.length > 0 ||
    //       record.experienceCertificates.length > 0
    //     ) {
    //       return (
    //         <Tooltip title="تفاصيل المربيه ">
    //           <Button
    //             className="edit-btn"
    //             onClick={(key) => handleEditSupport(record)}
    //             size="large"
    //             type="dashed"
    //             shape="circle"
    //             icon={<InfoOutlined />}
    //           />
    //         </Tooltip>
    //       );
    //     }
    //     return 'لا توجد تفاصيل';
    //   }
    // },
    

    {
      title: 'حذف الشكوي',
      dataIndex: 'delete',
      width: 92,
      render: (_, record) => {
        return (
          <Popconfirm
            title={
              <div style={{ maxWidth: '200px' }}>
                <p>هل انت متأكد من حذف الشكوي</p>
                {/* <p>حذف الخدمة سيؤدى الى حذف جميع المشاريع والاسئلة الخاصة بالخدمة</p> */}
              </div>
            }
            okText="نعم"
            cancelText="لا"
            onConfirm={(key) => handleDeleteRequest(record.key)}>
            <Tooltip title="حذف الشكوي">
              <Button
                className="delete-btn"
                size="large"
                type="dashed"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        );
      }
    }
  ];
};

export default SupportsColumns;

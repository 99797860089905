import { useContext } from 'react';
import UesrContext from '../../contexts/user-context/UserProvider';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Descriptions } from 'antd';
import { useParams, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect, useState } from 'react';
import getUser from '../../apis/users-api/getUser';
import getSitter from '../../apis/sitters-api/getSitterApi';

const SitterDetails = () => {
  /*   const { showDetails, userDetails ,setShowDetails } = useContext(UesrContext);
    console.log(userDetails) */

  const { user } = useContext(UesrContext);
  let history = useHistory();
  let param = useParams();
  const [targetSitter, setTargetSitter] = useState(null);

  useEffect(() => {
    const getTargetSitter = async () => {
      try {
        const targetSitterRes = await getSitter(user?.token, param?.id);

        if (targetSitterRes?.status === 200) {
          setTargetSitter(targetSitterRes?.data?.setter_details);
        }
      } catch (error) {
        throw error;
      }
    };

    getTargetSitter();
  }, []);

  return (
    <>
      <div className="flex flex-row justify-between">
        <h2 className="text-[20px] ">بيانات المربية : </h2>
        <Button
          onClick={() => history.push('/sitters')}
          icon={<CloseOutlined />}
          size="small"></Button>
      </div>

      <div className="show_details">
        <div>
          <Descriptions bordered column={1}>
            {targetSitter?.name && (
              <Descriptions.Item label="الأسم">{targetSitter?.name}</Descriptions.Item>
            )}
            {targetSitter?.phone && (
              <Descriptions.Item label="رقم الهاتف">{targetSitter?.phone}</Descriptions.Item>
            )}
            {targetSitter?.email && (
              <Descriptions.Item label="البريد الالكتروني">{targetSitter?.email}</Descriptions.Item>
            )}
            {targetSitter?.address && (
              <Descriptions.Item label="العنوان">{targetSitter?.address}</Descriptions.Item>
            )}
            {targetSitter?.address && (
              <Descriptions.Item label="الحالة">
                {targetSitter?.receive_order === 1 ? 'مقبوله' : 'مرفوضه'}
              </Descriptions.Item>
            )}
            {targetSitter?.phone_code && (
              <Descriptions.Item label="كود الهاتف">{targetSitter?.phone_code}</Descriptions.Item>
            )}
            {targetSitter?.national_id && (
              <Descriptions.Item label="رقم الهوية">{targetSitter?.national_id}</Descriptions.Item>
            )}
            {targetSitter?.date_of_birth && (
              <Descriptions.Item label="تاريخ الميلاد">
                {targetSitter?.date_of_birth}
              </Descriptions.Item>
            )}
            {targetSitter?.gender && (
              <Descriptions.Item label="النوع">{targetSitter?.gender}</Descriptions.Item>
            )}
            {targetSitter?.role && (
              <Descriptions.Item label="Role">{targetSitter?.role}</Descriptions.Item>
            )}
            {targetSitter?.nationality && (
              <Descriptions.Item label="الجنسية">{targetSitter?.nationality}</Descriptions.Item>
            )}
            {targetSitter?.hour_price && (
              <Descriptions.Item label="الأجر/ساعة">{targetSitter?.hour_price}</Descriptions.Item>
            )}
            {targetSitter?.Professional_life && (
              <Descriptions.Item label="طريقة العمل">
                {targetSitter?.Professional_life}
              </Descriptions.Item>
            )}
            {targetSitter?.hint && (
              <Descriptions.Item label="ملاحظات">{targetSitter?.hint}</Descriptions.Item>
            )}
          </Descriptions>
        </div>

        <div>
          <Descriptions>
            {targetSitter?.image_url && (
              <Descriptions.Item>
                <img src={targetSitter?.image_url} alt="user image" className="w-full" />
              </Descriptions.Item>
            )}
          </Descriptions>
        </div>
      </div>
    </>
  );
};

export default SitterDetails;

import './createChildrenPage';

/* eslint-disable react-hooks/exhaustive-deps */
import { EditOutlined, SendOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
// import MyDropZone from '../../common/dorp-zone/MyDropZone';
// import MyDropZonePreview from '../../common/dorp-zone';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
// import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { Button, Form } from 'antd';
// import { EditorState, ContentState } from 'draft-js';
// import htmlToDraft from 'html-to-draftjs';
// import { Editor } from 'react-draft-wysiwyg';
// import { stateToHTML } from 'draft-js-export-html';
// import UploadSellesFile from './UploadSellesFile';
// import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import { useEffect, useState } from 'react';
import { Store } from 'react-notifications-component';
import createChildrenApi from '../../apis/children-api/createChildren';
import getParentsID from '../../apis/parents-api/getParentsID';
import DatePickerBox from '../../common/DatePickerBox/DatePickerBox';
import SelectSearchBox from '../../common/SelectSearchBox/SelectSearchBox';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import AntdTextarea from '../../common/antd-form-components/AntdTextarea';
import UploadQuestionFile from '../../common/file-input/UploadQuestionFile';
import UesrContext from '../../contexts/user-context/UserProvider';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
// import UploadQuestionFile from '../blog-page/UploadQuestionFile';
import getAllCountarys from '../../apis/countary-api/getAllcountaries';

const defaultValues = {
  note: '',
  image: null,
  date_of_birth: null,
  // email: "",
  gender: '',
  disease: '',
  nationality: '',
  hobby: '',
  parent: '',
  name: '',
  is_disease: ''
};

const CreateChildrenPage = () => {
  const { user } = useContext(UesrContext);
  const [dataParentId, setDataParentId] = useState([]);
  const [nationalityOptions, setNationalityOptions] = useState([]);
  // console.log(user);
  let history = useHistory();

  let schema = Yup.object().shape({
    note: Yup.string().optional(' من فضلك أدخل الوصف مطلوب'),
    image: Yup.mixed().required('من فضلك أدخل المطلوب'),
    date_of_birth: Yup.mixed().required('اختارالتاريخ '),
    // email: Yup.string().required('البريد الألكتروني').email('من فضلك ادخل بريد الكترونى صحيح'),
    gender: Yup.string().required('من فضلك أدخل المطلوب'),
    name: Yup.string().required('من فضلك أدخل المطلوب'),
    disease: Yup.string().optional('من فضلك أدخل المطلوب'),
    nationality: Yup.string().required('من فضلك أدخل المطلوب'),
    hobby: Yup.string().optional(' من فضلك أدخل الموضوع مطلوب'),
    hobby: Yup.string().optional('من فضلك أدخل المطلوب'),
    parent: Yup.string().required('من فضلك أدخل المطلوب'),
    is_disease: Yup.string().required('من فضلك أدخل المطلوب')
  });

  const {
    control,
    // getValues,
    setValue,
    // setError,
    // clearErrors,
    handleSubmit,
    register,
    watch,
    unregister,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });
  const [urls, setUrls] = useState([]);
  console.log();

  const onSubmit = async (data) => {
    // console.log(data)
    try {
      let res;
      res = await createChildrenApi('/childrens/create', { ...data }, user?.token);
      // console.log(res)

      // console.log('create Selles response: ', res);
      if (res?.status !== 200) {
        Store.addNotification({
          title: 'حدث خطء اثناء الأضافه',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (res?.status === 200 && res?.data?.status_code === 200) {
        reset({ ...defaultValues });
        Store.addNotification({
          title: 'تمت العملية بنجاح',
          message: 'تم الأضافه ',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
        history.push('/Children');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [form] = Form.useForm();

  useEffect(() => {
    const handleGetParents = async () => {
      try {
        const getParentsRes = await getParentsID(user?.token);
        // console.log(getParentsRes.data.parents);

        if (getParentsRes.status === 200 && getParentsRes?.data?.parents) {
          setDataParentId(getParentsRes?.data?.parents);
        }
      } catch (error) {}
    };

    const getCountarys = async () => {
      let nationalityList = [];
      // let phoneCodeList = [];

      try {
        const getCountarysRes = await getAllCountarys(user?.token);

        if (getCountarysRes.status === 200 && getCountarysRes?.data) {
          // setAllCountarys(getCountarysRes?.data?.countries);
          getCountarysRes?.data?.countries.map((country) => {
            nationalityList.push({ name: country?.nationality, id: country?.nationality });
            //   phoneCodeList.push({ name: country?.code, id: country?.code });
          });

          setNationalityOptions(nationalityList);
          //  setPhoneCodeOptions(phoneCodeList);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getCountarys();
    handleGetParents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Form
      className="add-Selles-form flex justify-center items-center"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}>
      <div className="form-body my-[40px] w-full shadow-[rgba(100,_100,_111,_0.2)_0px_7px_29px_0px] max-w-[800px] py-[40px] px-[30px]">
        <div className="form-field-wrapper">
          <AntdTextField
            name="name"
            type="text"
            placeholder="الاسم..."
            errorMsg={errors?.name?.message}
            validateStatus={errors?.name ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        {/* <div className="form-field-wrapper">
          <AntdTextField
            name="nationality"
            type="text"
            placeholder="الجنسية..."
            errorMsg={errors?.nationality?.message}
            validateStatus={errors?.nationality ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
         </div> */}

        <div className=" form-field-wrapper">
          <SelectSearchBox
            name="nationality"
            errorMsg={errors?.nationality?.message}
            validateStatus={errors?.nationality ? 'error' : ''}
            defaultValue=""
            control={control}
            setValue=""
            dataSearch={nationalityOptions}
            placeholder="الجنسية..."
            label={
              <p className="select-option-label">
                <EditOutlined />
                الجنسية
              </p>
            }
            value={watch}
          />
        </div>

        <div className="form-field-wrapper">
          <AntdTextField
            name="hobby"
            type="text"
            placeholder="الهواية..."
            errorMsg={errors?.hobby?.message}
            validateStatus={errors?.hobby ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <AntdSelectOption
          name="is_disease"
          errorMsg={errors?.is_disease?.message}
          validateStatus={errors?.is_disease ? 'error' : ''}
          control={control}
          setValue={setValue}
          options={[
            { title: 'نعم', value: '1' },
            { title: 'لا', value: '0' }
          ]}
          label={
            <p className="select-option-label">
              <EditOutlined />
              هل يوجد مرض
            </p>
          }
          formClassName="add-Selles-form"
        />

        {watch('is_disease') == '1' && (
          <div className="form-field-wrapper">
            <AntdTextField
              name="disease"
              type="text"
              placeholder="المرض..."
              errorMsg={errors?.disease?.message}
              validateStatus={errors?.disease ? 'error' : ''}
              prefix={<EditOutlined />}
              control={control}
            />
          </div>
        )}

        {/* <div className="form-field-wrapper">
          <AntdTextField
            name="email"
            type="text"
            placeholder="البريد الألكتروني..."
            errorMsg={errors?.email?.message}
            validateStatus={errors?.email ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div> */}
        <div className="form-field-wrapper">
          <AntdTextarea
            name="note"
            type="text"
            placeholder="الوصف..."
            errorMsg={errors?.note?.message}
            validateStatus={errors?.note ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        {/* {!selectedTechnicalSupport?.client?.id && (
          <AntdSelectOption
            name="client_id"
            errorMsg={errors?.client_id?.message}
            validateStatus={errors?.client_id ? 'error' : ''}
            control={control}
            setValue={setValue}
            disabled={selectedTechnicalSupport?.client?.id && true}
            options={
              customerSelectData?.length &&
              customerSelectData?.map((el) => ({
                title: el?.name,
                value: el?.ref_id
              }))
            }
            label={
              <p className="select-option-label">
                <EditOutlined />
                العميل
              </p>
            }
            formClassName="add-Selles-form"
          />
        )} */}
        <AntdSelectOption
          name="gender"
          errorMsg={errors?.gender?.message}
          validateStatus={errors?.gender ? 'error' : ''}
          control={control}
          setValue={setValue}
          options={[
            { title: 'male', value: 'male' },
            { title: 'female', value: 'female' }
          ]}
          label={
            <p className="select-option-label">
              <EditOutlined />
              النوع
            </p>
          }
          formClassName="add-Selles-form"
        />
        <div className=" form-field-wrapper py-[10px]">
          <SelectSearchBox
            name="parent"
            errorMsg={errors?.parent?.message}
            validateStatus={errors?.parent ? 'error' : ''}
            defaultValue=""
            placeholder="اختر الوالد..."
            control={control}
            setValue={setValue}
            dataSearch={dataParentId}
            value={watch}
            label={
              <p className="select-option-label">
                <EditOutlined />
                اختر الوالد
              </p>
            }
          />
        </div>
        <div className=" form-field-wrapper py-[10px]">
          <DatePickerBox
            name="date_of_birth"
            errorMsg={errors?.date_of_birth?.message}
            validateStatus={errors?.date_of_birth ? 'error' : ''}
            defaultValue=""
            control={control}
            setValue={setValue}
            watch={watch}
            label={
              <p className="select-option-label">
                <EditOutlined />
                تاريخ الميلاد
              </p>
            }
          />
        </div>

        <div className="form-field-wrapper">
          <UploadQuestionFile
            // accept="image/png, image/jpg, image/jpeg, image/gif"
            maxFiles={1}
            name="image"
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
            multiple={false}
            label={
              <>
                <EditOutlined />
                الصوره
              </>
            }
            urls={urls}
            errorMsg={errors?.image ? errors?.image?.message : ''}
          />
        </div>

        {/* <div className="form-field-wrapper">
          {!selectedServId && (
            <AntdTextField
              name="image"
              type="file"
              placeholder="موضوع..."
              errorMsg={errors?.image?.message}
              validateStatus={errors?.image ? 'error' : ''}
              prefix={<EditOutlined />}
              control={control}
            />
          )}
        </div> */}

        <Button
          className="submit-btn mx-auto mt-[30px]  !h-[45px] !w-[100px]   !flex justify-center items-center"
          htmlType="submit"
          type="primary"
          icon={<SendOutlined />}
          loading={isSubmitting}>
          اضافه
        </Button>
      </div>
    </Form>
  );
};

export default CreateChildrenPage;

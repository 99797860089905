import React, { useContext, useEffect, useState } from 'react';
import CountarysContext from '../../contexts/countary-context/CountaryProvider';
import UesrContext from '../../contexts/user-context/UserProvider';
import { Table } from 'antd';
import getAllCountarys from '../../apis/countary-api/getAllcountaries';
import './CountryPage.scss';
import CountarysColumns from './countaryColumns';

const CountaryTable = () => {
  const { user } = useContext(UesrContext);
  const {
    fetchCount,
    setFetchCount,
    loadingCountarys,
    setLoadingCountarys,
    allCountarys,
    setAllCountarys,
    setCountaryModalOpened,
    setSelectedServId,
    setShowCityModalOpened
  } = useContext(CountarysContext);

  const [CountaryDataSource, setCountaryDataSource] = useState([]);

  useEffect(() => {
    const getCountarys = async () => {
      try {
        setLoadingCountarys(true);
        const getCountarysRes = await getAllCountarys(user?.token);

        if (getCountarysRes.status === 200 && getCountarysRes?.data) {
          setAllCountarys(getCountarysRes?.data?.countries);
          console.log(getCountarysRes?.data?.countries);
          setLoadingCountarys(false);
        }
      } catch (error) {
        setLoadingCountarys(false);
      }
    };
    getCountarys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCount]);
  useEffect(() => {
    let isMounted = true;

    if (allCountarys) {
      const mappedCountary = allCountarys.map((ques) => ({
        ...ques,
        key: ques.id
      }));

      if (isMounted) {
        setCountaryDataSource(mappedCountary);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [allCountarys, allCountarys.length, fetchCount]);
  return (
    <Table
      pagination={{ pageSize: 5 }}
      className="Orders-table"
      dataSource={CountaryDataSource}
      columns={CountarysColumns(
        user,
        setFetchCount,
        setLoadingCountarys,
        setCountaryModalOpened,
        setShowCityModalOpened,
        setSelectedServId
      )}
      loading={loadingCountarys}
      scroll={{ x: 1200 }}
    />
  );
};

export default CountaryTable;

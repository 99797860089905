import axios from 'axios';

export const signinApi = async (values, langIsoCode) => {
  const { name, password, phone } = values;
  console.log(phone);
  try {
    const formData = new FormData();
    // formData.append('name', name);
    formData.append('password', password);
    formData.append('phone', phone);
    console.log(password);
    console.log(phone);
    const signinRes = await axios.post('/auth/login', formData, {
      headers: {
        lang: langIsoCode,
        Accept: 'application/json',
        'Content-Type': 'applications/json'
      }
    });
    console.log(signinRes);

    return signinRes;
  } catch (error) {
    console.log(error);
    return error?.response;
  }
};

import React from 'react';

import signinImg from '../../assets/imgs/signin/signin.png';
import LoginFormDataEntry from './LoginFormDataEntry';
import './LoginPageDataEntry.scss';

const LoginPageDataEntry = () => {
  return (
    <div className="login-page">
      <div className="custom-container">
        <div className="page-wrapper">
          <div className="form-top-level-wrapper">
            <LoginFormDataEntry />
          </div>
          <div className="img-wrap">
            <img src={signinImg} alt="signin" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPageDataEntry;

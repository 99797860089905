import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => {},
  loadingCountarys: false,
  setLoadingCountarys: (load) => {},
  allCountarys: [],
  setAllCountarys: (Countarys) => {},
  CountaryModalOpened: false,
  setCountaryModalOpened: (v) => {},
  ShowCityModalOpened: false,
  setShowCityModalOpened: (v) => {},
  selectedServId: '',
  setSelectedServId: (id) => {},
  selectedCountary: null,
  setSelectedCountary: (servKey) => {}
};
//setShowCityModalOpened
const CountarysContext = createContext(INITIAL_VALUES);

export const CountarysProvider = ({ children }) => {
  const [allCountarys, setAllCountarys] = useState(INITIAL_VALUES.allCountarys);
  const [loadingCountarys, setLoadingCountarys] = useState(INITIAL_VALUES.loadingCountarys);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [CountaryModalOpened, setCountaryModalOpened] = useState(
    INITIAL_VALUES.CountaryModalOpened
  );
  const [ShowCityModalOpened, setShowCityModalOpened] = useState(
    INITIAL_VALUES.CountaryModalOpened
  );
  const [selectedServId, setSelectedServId] = useState(INITIAL_VALUES.selectedServId);
  const [selectedCountary, setSelectedCountary] = useState(INITIAL_VALUES.selectedCountary);

  return (
    <CountarysContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        loadingCountarys,
        setLoadingCountarys,
        allCountarys,
        setAllCountarys,
        CountaryModalOpened,
        setCountaryModalOpened,
        ShowCityModalOpened,
        setShowCityModalOpened,
        selectedServId,
        setSelectedServId,
        selectedCountary,
        setSelectedCountary
      }}>
      {children}
    </CountarysContext.Provider>
  );
};

export default CountarysContext;

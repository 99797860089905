import axios from 'axios';

const getAllRooms = async (token) => {
  try {
    const getRoomsRes = await axios.post(
      '/rooms/index',
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'applications/json',
          Authorization: 'Bearer ' + token
        }
      }
    );

    return getRoomsRes;
  } catch (error) {
    return error?.response;
  }
};

export default getAllRooms;

import { Button, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, LinkOutlined } from '@ant-design/icons';

import { Store } from 'react-notifications-component';
import deleteCityApi from '../../apis/city-api/deleteCityApi';

const CitysColumns = (
  token,
  setFetchCount,
  setLoadingCitys,
  setCityModalOpened,
  setSelectedCityId,
  setShowAreaModalOpened,
  setSelectedCountary
) => {
  const handleEditCity = (record) => {
    setCityModalOpened(true);
    setSelectedCityId(record.key);
    setSelectedCountary(record.country_id)
    
    // 
  };
  const handleShowCityCountary = (key) => {
    
    
    setShowAreaModalOpened(true);
    setSelectedCityId(key);
    // 
  };
  const handleDeleteRequest = async (key) => {
    try {
      setLoadingCitys(true);
      const res = await deleteCityApi(key, token);
      if (res.status === 200 && res.data.status === 1) {
        setFetchCount((prevState) => prevState + 1);
        setLoadingCitys(false);
        Store.addNotification({
          title: 'العملية تمت بنجاح',
          message: res?.data?.message,
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        setLoadingCitys(false);
        Store.addNotification({
          title: 'حدث خطأ ',
          message: res?.data?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      
      setLoadingCitys(false);
      Store.addNotification({
        title: 'حدث خطأ ',
        message: 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };
  return [
    {
      title: '#',
      dataIndex: 'key',
      width: 62
    },

    {
      title: 'الاسم العربي',
      dataIndex: 'name_ar',
      width: 192
    },
    {
      title: 'الاسم الانجليزي',
      dataIndex: 'name_en',
      width: 192
    },
    {
      title: ' مناطق المدينة',
      dataIndex: 'city',
      width: 92,
      render: (_, record) => {
        return (
          // <RouterLink
          //   className="projects-link"
          //   to={{
          //     pathname: routerLinks.CityPage,
          //     state: { CountryId: record.key }
          //   }}>
          <Tooltip title="مناطق الدوله">
            <Button
              className="projects-btn"
              size="large"
              type="dashed"
              shape="circle"
              onClick={(key) => handleShowCityCountary(record.id)}
              icon={<LinkOutlined />}
            />
          </Tooltip>
          // </RouterLink>
        );
      }
    },
    {
      title: 'تعديل المدينه',
      dataIndex: 'edit',
      width: 92,
      render: (_, record) => {
        return (
          <Tooltip title="تعديل المدينه">
            <Button
              className="edit-btn"
              onClick={(key) => handleEditCity(record)}
              size="large"
              type="dashed"
              shape="circle"
              icon={<EditOutlined />}
            />
          </Tooltip>
        );
      }
    },
    {
      title: 'حذف المدينه',
      dataIndex: 'delete',
      width: 92,
      render: (_, record) => {
        return (
          <Popconfirm
            title={
              <div style={{ maxWidth: '200px' }}>
                <p>هل انت متأكد من حذف المدينه</p>
                {/* <p>حذف المدينه سيؤدى الى حذف جميع المشاريع والاسئلة الخاصة بالمدينه</p> */}
              </div>
            }
            okText="نعم"
            cancelText="لا"
            onConfirm={(key) => handleDeleteRequest(record.key)}>
            <Tooltip title="حذف المدينه">
              <Button
                className="delete-btn"
                size="large"
                type="dashed"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        );
      }
    }
  ];
};

export default CitysColumns;

import { Table } from 'antd';
import React, { useContext, useEffect } from 'react';
import UesrContext from '../../contexts/user-context/UserProvider';
import TransactionsColumns from './TransactionsColumns.js';
import TransactionsContext from '../../contexts/transactions-context/TransactionsProvider';
import getAllTransactionsApi from '../../apis/transactions-api/getAllTransactionsApi';
const TransactionsTable = () => {
  const { user } = useContext(UesrContext);
  const {
    fetchCount,
    setFetchCount,
    loadingTransactions,
    setLoadingTransactions,
    allTransactions, setAllTransactions,
    setParentModalOpened,
    setSelectedParent,

  } = useContext(TransactionsContext);

  useEffect(() => {
    const getTransactions = async () => {
      try {
        setLoadingTransactions(true);
        const getTransactionsRes = await getAllTransactionsApi(user?.token);
        if (getTransactionsRes.status === 200 && getTransactionsRes?.data?.transactions) {
          setAllTransactions(getTransactionsRes?.data?.transactions);
          // console.log(getTransactionsRes?.data?.transactions)
          // console.table(getTransactionsRes?.data?.driver?.data)
          // console.log(getTransactionsRes?.data?.driver?.data)
          setLoadingTransactions(false);
        }
      } catch (error) {
        setLoadingTransactions(false);
      }
    };
    getTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCount]);
  // console.log(allTransactions);
  return (
    <Table
      pagination={{ pageSize: 5 }}
      className="Orders-table"
      dataSource={allTransactions}
      columns={TransactionsColumns(
        user?.token,
        setFetchCount,
        setLoadingTransactions,
        setParentModalOpened,
        setSelectedParent
      )}
      loading={loadingTransactions}
      scroll={{ x: 1200 }}
    />
  );
};

export default TransactionsTable;

import axios from 'axios';

const getCountry = async (id, token) => {
  try {
    const getCountryRes = await axios.get(`/country/show/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token
      }
    });
    return getCountryRes;
  } catch (error) {
    return error.massage;
  }
};

export default getCountry;

import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => { },
  loadingDriver: false,
  setLoadingDriver: (load) => { },
  allDrivers: [],
  setAllDrivers: (services) => { },
  DriverModalOpened: false,
  setDriverModalOpened: (v) => { },
  selectedDriverId: '',
  setSelectedDriverId: (id) => { },
  selectedDriver: null,
  setSelectedDriver: (servKey) => { },
  selectedFilter: '',
  setSelectedFilter: (v) => { }
};

const DriversContext = createContext(INITIAL_VALUES);

export const DriversProvider = ({ children }) => {
  const [selectedFilter, setSelectedFilter] = useState(INITIAL_VALUES.selectedFilter);
  const [allDrivers, setAllDrivers] = useState(INITIAL_VALUES.allDrivers);
  const [loadingDriver, setLoadingDriver] = useState(INITIAL_VALUES.loadingDriver);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [DriverModalOpened, setDriverModalOpened] = useState(INITIAL_VALUES.DriverModalOpened);
  const [selectedDriverId, setSelectedDriverId] = useState(INITIAL_VALUES.selectedDriverId);
  const [selectedDriver, setSelectedDriver] = useState(INITIAL_VALUES.selectedDriver);

  return (
    <DriversContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        loadingDriver,
        setLoadingDriver,
        selectedFilter,
        setSelectedFilter,
        allDrivers,
        setAllDrivers,
        DriverModalOpened,
        setDriverModalOpened,
        selectedDriverId,
        setSelectedDriverId,
        selectedDriver,
        setSelectedDriver
      }}>
      {children}
    </DriversContext.Provider>
  );
};

export default DriversContext;

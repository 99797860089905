import React, { useState, createContext } from 'react';

const INITIAL_VALUES={
    fetchCount: 0,
    setFetchCount: (count) => {},
    loadingRooms: false,
    setLoadingRooms: (load) => {},
    allRooms: [],
    setAllRooms: (Orders) => {},
    roomDetails:'',
    setRoomDetails:(Orders) => {},
    targetRoom:'',
    setTargetRoom:(Orders) => {}
}


const RoomContext = createContext(INITIAL_VALUES)

export const RoomProvider = ({children})=>{

    const [allRooms, setAllRooms] = useState(INITIAL_VALUES.allRooms);
    const [loadingRooms, setLoadingRooms] = useState(INITIAL_VALUES.loadingRooms);
    const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
    const [roomDetails, setRoomDetails] = useState(INITIAL_VALUES.roomDetails);
    const [targetRoom, setTargetRoom] = useState(INITIAL_VALUES.targetRoom);

    return(
        <RoomContext.Provider 
          value={{ 
            fetchCount,
            setFetchCount,
            loadingRooms,
            setLoadingRooms,
            allRooms,
            setAllRooms,
            roomDetails,
            setRoomDetails,
            targetRoom,
            setTargetRoom}}>

            {children}

        </RoomContext.Provider >
    )
}

export default RoomContext
import axios from 'axios';


const CreateTransactionsApi = async (url, values, token) => {
  // console.log(values)
  const { type, user_id, amount, } = values;
  console.log(user_id)
  const formData = new FormData();

  formData.append("type", type);
  formData.append("user_id", user_id);
  formData.append("amount", amount);
  try {
    // console.log(image[0])

    const createSerivceRes = await axios.post(url, formData, {
      headers: {
        Accept: 'application/json',

        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      }
    });
    console.log(createSerivceRes)

    return createSerivceRes;
  } catch (error) {
    console.log(error?.response)
    return error?.response;

  }
};

export default CreateTransactionsApi;


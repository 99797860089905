import axios from 'axios';

const getRate = async (token, id) => {
  try {
    const getRateRes = await axios.get(`/rates/show/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token
      }
    });
    console.log(getRateRes);
    return getRateRes;
  } catch (error) {
    return error?.response;
  }
};

export default getRate;

import axios from 'axios';

const getSittersID = async (token) => {
  try {
    const getSittersRes = await axios.get('/setters/all_setters', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token
      }
    });

    return getSittersRes;
  } catch (error) {
    return error?.response;
  }
};

export default getSittersID;

import { Table } from 'antd';
import React, { useContext, useEffect } from 'react';
import getAllOrdersApi from '../../apis/orders-api/getAllOrderApi';
import OrdersContext from '../../contexts/orders-context/OrdersProvider';
import UesrContext from '../../contexts/user-context/UserProvider';
import OrdersColumns from './OrderColumns';
const OrderTable = () => {
  const { user } = useContext(UesrContext);
  const {
    fetchCount,
    setFetchCount,
    loadingOrders,
    setLoadingOrders,
    allOrders,
    setAllOrders,
    setParentModalOpened,
    setSelectedParent
  } = useContext(OrdersContext);

  useEffect(() => {
    const getOrders = async () => {
      try {
        setLoadingOrders(true);
        const getOrdersRes = await getAllOrdersApi(user?.token);
        if (getOrdersRes.status === 200 && getOrdersRes?.data?.orders) {
          setAllOrders(getOrdersRes?.data?.orders);
          // console.table(getOrdersRes?.data?.driver?.data)
          // console.log(getOrdersRes?.data?.driver?.data)
          setLoadingOrders(false);
        }
      } catch (error) {
        setLoadingOrders(false);
      }
    };
    getOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCount]);
  // console.log(allOrders);
  return (
    <Table
      pagination={{ pageSize: 5 }}
      className="Orders-table"
      dataSource={allOrders}
      columns={OrdersColumns(
        user?.token,
        setFetchCount,
        setLoadingOrders,
        setParentModalOpened,
        setSelectedParent
      )}
      loading={loadingOrders}
      scroll={{ x: 1200 }}
    />
  );
};

export default OrderTable;

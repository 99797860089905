import { Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import getAllSitters from '../../apis/sitters-api/getAllSitters';
import SittersContext from '../../contexts/sitters-context/SittersProvider';
import UesrContext from '../../contexts/user-context/UserProvider';
import SittersColumns from './SittersColumns.js';
import axios from 'axios';

const SittersTable = ({ filterValue }) => {
  const { user } = useContext(UesrContext);
  const {
    fetchCount,
    setFetchCount,
    loadingSitters,
    setLoadingSitters,
    allSitters,
    setAllSitters,
    setSitterModalOpened,
    setSelectedServId
  } = useContext(SittersContext);

  /*  const getData = async (data) => {
    try {
      setLoadingSitters(true);
      const getSittersRes = await axios.get(`/Sitter/${filterValue}?page=` + data.page, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'applications/json',
          Authorization: 'Bearer ' + user?.token
        }
      });
      setAllSitters(getSittersRes.data);
      setLoadingSitters(false);
    } catch (error) {}
  }; */

  const filterSitters = () => {
    if (allSitters.length) {
      if (filterValue === 'all') {
        return allSitters;
      } else if (filterValue === 'accepted') {
        return allSitters.filter((item) => item.receive_order == 1);
      } else if (filterValue === 'rejected') {
        return allSitters.filter((item) => item.receive_order == 0);
      }
    }
  };

  useEffect(() => {
    const getSitters = async () => {
      try {
        setLoadingSitters(true);
        const getSittersRes = await getAllSitters(user?.token);

        if (getSittersRes?.status === 200) {
          setAllSitters(getSittersRes?.data?.setters);
          console.log(getSittersRes?.data?.setters);
          setLoadingSitters(false);
        }
      } catch (error) {
        setLoadingSitters(false);
      }
    };

    getSitters();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCount]);
  return (
    <div>
      <Table
        pagination={{ pageSize: 5 }}
        className="Orders-table"
        dataSource={filterSitters()}
        columns={SittersColumns(
          user?.token,
          setFetchCount,
          setLoadingSitters,
          setSitterModalOpened,
          setSelectedServId
        )}
        loading={loadingSitters}
        scroll={{ x: 1200 }}
      />

      {/* <div className="pagination-container">
        <Pagination changePage={getData} data={allSitters} />
      </div> */}
    </div>
  );
};

export default SittersTable;

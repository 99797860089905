import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => {},
  loadingSupports: false,
  setLoadingSupports: (load) => {},
  allSupports: [],
  setAllSupports: (Supports) => {},
  SupportModalOpened: false,
  setSupportModalOpened: (v) => {},
  selectedServId: '',
  setSelectedServId: (id) => {},
  selectedSupport: null,
  setSelectedSupport: (servKey) => {}
};

const SupportsContext = createContext(INITIAL_VALUES);

export const SupportsProvider = ({ children }) => {
  const [allSupports, setAllSupports] = useState(INITIAL_VALUES.allSupports);
  const [loadingSupports, setLoadingSupports] = useState(INITIAL_VALUES.loadingSupports);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [SupportModalOpened, setSupportModalOpened] = useState(INITIAL_VALUES.SupportModalOpened);
  const [selectedServId, setSelectedServId] = useState(INITIAL_VALUES.selectedServId);
  const [selectedSupport, setSelectedSupport] = useState(INITIAL_VALUES.selectedSupport);

  return (
    <SupportsContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        loadingSupports,
        setLoadingSupports,
        allSupports,
        setAllSupports,
        SupportModalOpened,
        setSupportModalOpened,
        selectedServId,
        setSelectedServId,
        selectedSupport,
        setSelectedSupport
      }}>
      {children}
    </SupportsContext.Provider>
  );
};

export default SupportsContext;

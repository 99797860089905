import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout as AntdLayout, Breadcrumb } from 'antd';
import {  useHistory, useLocation } from 'react-router-dom';
// import ReactNotification from 'react-notifications-component';
import { Store } from 'react-notifications-component';

import routerLinks from './routerLinks';
import './Layout.scss';
import DataEntryUesrContext from '../../contexts/data-entry-user-context/DataEntryUserProvider';
import dataEntrySignout from '../../apis/auth/dataEntrySignout';
import {
  LogoutOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

const DataEntryLayout = ({ children }) => {
  const { userDataEntry, removeCurrentUser } = useContext(DataEntryUesrContext);

  const { i18n } = useTranslation();
  const { Header, Content } = AntdLayout;

  const [loadingSignout, setLoadingSignout] = useState(false);
  const history = useHistory();
  const handleSignout = async () => {
    try {
      setLoadingSignout(true);
      const res = await dataEntrySignout(userDataEntry?.token);
      if (res?.status === 200 && res?.data?.status === 1) {
        setLoadingSignout(false);
        removeCurrentUser();
        history.push(routerLinks.DataEntryLoginPage);
        Store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم تسجيل الخروج بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        setLoadingSignout(false);
        Store.addNotification({
          title: 'حدث خطأ اثناء تسجيل الخروج',
          message: 'من فضلك حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      setLoadingSignout(false);
      Store.addNotification({
        title: 'حدث خطأ اثناء تسجيل الخروج',
        message: 'من فضلك حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
      
    }
  };

  return (
    <div className={`app-layout app-${i18n.dir()}`}>
      <AntdLayout style={{ minHeight: '100vh' }}>
        
        <AntdLayout className="site-layout app-site-layout">
          
          <Header className="site-layout-background" style={{ padding: 0,justifyContent:'flex-end' }}>
            

            <div className="avatar-wrapper">
              {loadingSignout ?<><LoadingOutlined /> جاري تسجيل الخروج</> :  <a onClick={handleSignout}>تسجيل الخروج <LogoutOutlined /></a> }
              
            </div>
          </Header>
          <Content style={{backgroundColor:'#fff',margin: ' 16px'}}>
            <Breadcrumb style={{ margin: '16px 0' }}></Breadcrumb>    
              {children}
            
          </Content>
         
        </AntdLayout>
      </AntdLayout>
    </div>
  );
};

export default DataEntryLayout;

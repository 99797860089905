import {
  CarOutlined,
  DownOutlined,
  LoadingOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SolutionOutlined,
  TransactionOutlined,
  UserOutlined
} from '@ant-design/icons';
import { RiParentLine } from 'react-icons/ri';
//import { FaChildren } from 'react-icons/fa6';
import { Layout as AntdLayout, Breadcrumb, Button, Dropdown, Menu } from 'antd';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFlag, BsTicketPerforated } from 'react-icons/bs';
import { CgComment } from 'react-icons/cg';
import { FaRegBuilding } from 'react-icons/fa';
import { FaHandsHoldingChild } from 'react-icons/fa6';
import { LiaCertificateSolid } from 'react-icons/lia';
import { MdOutlineChildFriendly, MdOutlineMeetingRoom } from 'react-icons/md';
import { RiAdminLine } from 'react-icons/ri';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
// import ReactNotification from 'react-notifications-component';
import { Store } from 'react-notifications-component';

import Avatar from 'antd/lib/avatar/avatar';
import slugify from 'slugify';
import signoutApi from '../../apis/auth/signout';
import Logo from '../../common/logo/Logo';
import UesrContext from '../../contexts/user-context/UserProvider';
import './Layout.scss';
import routerLinks from './routerLinks';

const AppLayout = ({ children }) => {
  const { user, removeCurrentUser } = useContext(UesrContext);
  console.log(user);

  const { pathname } = useLocation();
  const { i18n } = useTranslation();
  // const { Header, Content, Footer, Sider } = AntdLayout;
  const { Header, Content, Sider } = AntdLayout;

  const [collapsed, setCollapsed] = useState(false);
  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };
  const toggleCollapsed = () => {
    setCollapsed((prevState) => !prevState);
  };

  const mainLinks = [
    {
      id: 1,
      path: routerLinks.homePage,
      name: 'الرئيســية',
      icon: <SolutionOutlined />
    },
    {
      id: 9,
      path: routerLinks.CountaryPage,
      name: 'الدوله',
      icon: <BsFlag className="!text-[22px]" />
    },
    // {
    //   id: 4,
    //   path: routerLinks.CityPage,
    //   name: 'المدن',
    //   icon: <SolutionOutlined />
    // },
    // {
    //   id: 11,
    //   path: routerLinks.AreaPage,
    //   name: 'المناطق',
    //   icon: <SolutionOutlined />
    // },
    {
      id: 13,
      path: routerLinks.usersPage,
      name: 'المستخدمين',
      icon: <UserOutlined className="!text-[22px]" />
    },
    {
      id: 14,
      path: routerLinks.adminPage,
      name: 'المسؤلين',
      icon: <RiAdminLine className="!text-[22px]" />
    },
    // {
    //   id: 2,
    //   path: routerLinks.categoryPage,
    //   name: 'الاقســــام',
    //   icon: <PieChartOutlined />
    // },
    // {
    //   id: 3,
    //   path: routerLinks.servicesPage,
    //   name: 'الخدمــات',
    //   icon: <AppstoreAddOutlined />
    // },
    // {
    //   id: 4,
    //   path: routerLinks.projectsPage,
    //   name: 'المشــاريع',
    //   icon: <CodeOutlined />
    // },
    {
      id: 3,
      path: routerLinks.sittersPage,
      name: 'المربيات',
      icon: <FaHandsHoldingChild className="!text-[21px]" />
    },
    {
      id: 15,
      path: routerLinks.roomsPage,
      name: 'الغرف',
      icon: <MdOutlineMeetingRoom className="!text-[22px]" />
    },
    {
      id: 19,
      path: routerLinks.falilityPage,
      name: 'المنشآت',
      icon: <FaRegBuilding className="!text-[22px]" />
    },
    {
      id: 21,
      path: routerLinks.ratesPage,
      name: 'التقييمات',
      icon: <CgComment className="!text-[22px]" />
    },
    {
      id: 5,
      path: routerLinks.parentPage,
      name: 'الأبـــــاء',
      icon: <RiParentLine className="!text-[22px]" />
    },
    {
      id: 12,
      path: routerLinks.ChildrenPage,
      name: 'الابناء',
      icon: <MdOutlineChildFriendly className="!text-[22px]" />
    },
    {
      id: 16,
      path: routerLinks.driverPage,
      name: 'السائقين',
      icon: <CarOutlined />
    },
    {
      id: 17,
      path: routerLinks.certificatePage,
      name: 'الشهادات',
      icon: <LiaCertificateSolid className="!text-[22px]" />
    },
    {
      id: 20,
      path: routerLinks.transactionsPage,
      name: 'المعاملات',
      icon: <TransactionOutlined className="!text-[22px]" />
    },
    // {
    //   id: 6,
    //   path: routerLinks.answersPage,
    //   name: 'طلبات المشــاريع',
    //   icon: <SolutionOutlined />
    // },
    // {
    //   id: 7,
    //   path: routerLinks.SettingsPage,
    //   name: 'الأعدادات',
    //   icon: <CopyOutlined />
    // },
    // {
    //   id: 8,
    //   path: routerLinks.ContactUsPage,
    //   name: 'تواصل معنا',
    //   icon: <CustomerServiceOutlined />
    // },
    // {
    //   id: 2,
    //   path: routerLinks.supportsPage,
    //   name: 'الشكاوي والدعم',
    //   icon: <CustomerServiceOutlined />
    // },
    {
      id: 6,
      path: routerLinks.ordersPage,
      name: 'الطلبات',
      icon: <SolutionOutlined />
    },
    {
      id: 33,
      path: routerLinks.ticketsPage,
      name: 'التذاكر',
      icon: <BsTicketPerforated />
    }
  ];

  const renderMainLinks = () => {
    return mainLinks.map((link) => (
      <Menu.Item
        key={link.id}
        icon={link.icon}
        className={slugify(pathname) === slugify(link.path) ? 'ant-menu-item-selected' : ''}>
        <RouterLink to={link.path}>{link.name}</RouterLink>
      </Menu.Item>
    ));
  };

  const [loadingSignout, setLoadingSignout] = useState(false);
  const history = useHistory();
  const handleSignout = async () => {
    try {
      setLoadingSignout(true);
      const res = await signoutApi(user?.token);
      console.log(res);
      if (res.status !== 200) {
        setLoadingSignout(false);
        removeCurrentUser();
        history.push(routerLinks.homePage);
        Store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم تسجيل الخروج بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        setLoadingSignout(false);
        Store.addNotification({
          title: 'حدث خطأ اثناء تسجيل الخروج',
          message: 'من فضلك حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      setLoadingSignout(false);
      Store.addNotification({
        title: 'حدث خطأ اثناء تسجيل الخروج',
        message: 'من فضلك حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };

  return (
    <div className={`app-layout app-${i18n.dir()}`}>
      <AntdLayout style={{ minHeight: '100vh' }}>
        <Sider
          trigger={null}
          theme="light"
          width="252"
          collapsible
          collapsed={collapsed}
          onCollapse={onCollapse}
          breakpoint="lg"
          collapsedWidth="0"
          className=" pb-[50px]"
          onBreakpoint={(broken) => {
            //
          }}>
          {/* <div className="logo" /> */}
          <Logo className="logo-link" />
          <Menu className="app-aside-menu" theme="light" mode="inline" selectable={false}>
            {renderMainLinks()}
          </Menu>
        </Sider>
        <AntdLayout className="site-layout app-site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }}>
            {/* {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger-layout-btn',
              onClick: toggleCollapsed
            })} */}
            {collapsed ? (
              <MenuUnfoldOutlined className="trigger-layout-btn" onClick={toggleCollapsed} />
            ) : (
              <MenuFoldOutlined className="trigger-layout-btn" onClick={toggleCollapsed} />
            )}

            <div className="avatar-wrapper">
              <Dropdown
                trigger={['click']}
                disabled={loadingSignout}
                overlay={
                  <Menu>
                    <Menu.Item key="1" icon={<UserOutlined />}>
                      <RouterLink to={routerLinks.ProfilePage}>الملــف الشخصى</RouterLink>
                    </Menu.Item>
                    <Menu.Item key="2" icon={<LogoutOutlined />} onClick={handleSignout}>
                      تسجيل الخروج
                    </Menu.Item>
                  </Menu>
                }>
                <Button className="profile-menu-btn" type="text">
                  {loadingSignout ? <LoadingOutlined /> : <DownOutlined />}
                  <span className="user-name">{user?.name}</span>
                  <Avatar size={38} icon={<UserOutlined />} src={user?.image_url} />
                </Button>
              </Dropdown>
            </div>
          </Header>
          <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{ margin: '16px 0' }}></Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
              {children}
            </div>
          </Content>
          {/* <Footer style={{ textAlign: 'center' }}>Layout footer</Footer> */}
        </AntdLayout>
      </AntdLayout>
      {/* <ReactNotification className={i18n.dir()} /> */}
    </div>
  );
};

export default AppLayout;

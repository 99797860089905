import { Tooltip, Button } from 'antd';
import { InfoOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const RatesColumns = () => {
  let history = useHistory();

  return [
    {
      title: '#',
      dataIndex: 'id',
      width: 30,
      render: (_, record) => {
        return <div>{record?.id}</div>;
      }
    },
    {
      title: 'أسم المربية',
      dataIndex: 'sitter_name',
      width: 100,
      render: (_, record) => {
        return <div>{record?.user?.name}</div>;
      }
    },
    {
      title: 'التقييم',
      dataIndex: 'review',
      width: 100,
      render: (_, record) => {
        return <div>{record?.review}</div>;
      }
    },
    {
      title: 'عدد النجوم',
      dataIndex: 'num_of_stars',
      width: 100,
      render: (_, record) => {
        return <div>{record?.num_of_stars}</div>;
      }
    },

    {
      title: 'قيم بواسطة',
      dataIndex: 'name',
      width: 120,
      render: (_, record) => {
        return <div>{record?.rate_from?.name}</div>;
      }
    },
    {
      title: 'البريد الألكتروني',
      dataIndex: 'email',
      width: 120,
      render: (_, record) => {
        return <div>{record?.rate_from?.email}</div>;
      }
    },
    {
      title: 'تاريخ التقييم',
      dataIndex: 'created_at',
      width: 120,
      render: (_, record) => {
        return <div>{record?.created_at.slice(0, 10)}</div>;
      }
    },
    {
      title: 'تفاصيل التقييم',
      dataIndex: 'show',
      width: 30,
      render: (_, record) => {
        return (
          <Tooltip title="تفاصيل التقييم ">
            <Button
              className="edit-btn"
              onClick={() => history.push(`/rates/show/${record.id}`)}
              size="large"
              type="dashed"
              shape="circle"
              icon={<InfoOutlined />}
            />
          </Tooltip>
        );
      }
    }
  ];
};

export default RatesColumns;

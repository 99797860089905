import axios from 'axios';

const getAllTicketsThreadsApi = async (id, token) => {
  console.log(id);
  try {
    const getParentsRes = await axios.get(`tickets/ticket_threads/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token
      }
    });
    // console.log(getParentsRes);

    return getParentsRes;
  } catch (error) {
    console.log(error);
  }
};

export default getAllTicketsThreadsApi;

import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import OrderTable from './TicketsTable';

const TicketsPage = () => {
  let history = useHistory();

  // const { DriverModalOpened, setDriverModalOpened, setSelectedServId, setSelectedDriver, selectedDriver } =
  //   useContext(OrderContext);

  return (
    <>
      <div>
        <Button
          onClick={() => {
            history.push('/ticket/create');
          }}
          className="add-serv-btn"
          type="primary"
          icon={<PlusOutlined />}>
          <span className="btn-text">اضافة طلب</span>
        </Button>
        <OrderTable />
      </div>
    </>
  );
};

export default TicketsPage;

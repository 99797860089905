import { Popconfirm, Tooltip, Button } from 'antd';
import { DeleteOutlined, EditOutlined, InfoOutlined } from '@ant-design/icons';
import deleteRoom from '../../apis/rooms-api/deleteRoomApi';
import { Store } from 'react-notifications-component';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const RoomsColumns = (
  user,
  fetchCount,
  setFetchCount,
  loadingRooms,
  setLoadingRooms,
  allRooms,
  setAllRooms
) => {
  let history = useHistory();

  const handleDeleteRequest = async (id) => {
    try {
      setLoadingRooms(true);
      const deleteRes = await deleteRoom(id, user?.token);
      if (deleteRes?.status === 200) {
        setFetchCount((prevState) => prevState + 1);
        setLoadingRooms(false);
        Store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم حذف المستخدم بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        setLoadingRooms(false);
        Store.addNotification({
          title: 'حدث خطأ ',
          message: deleteRes?.data?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
      console.log(deleteRes);
    } catch (error) {
      setLoadingRooms(false);
      Store.addNotification({
        title: 'حدث خطأ ',
        message: 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };

  return [
    {
      title: '#',
      dataIndex: 'id',
      width: 62,
      render: (_, record) => {
        return <div>{record?.id}</div>;
      }
    },
    {
      title: 'أسم الغرفة',
      dataIndex: 'name',
      width: 120,
      render: (_, record) => {
        return <div>{record?.name}</div>;
      }
    },
    {
      title: 'المربيه',
      dataIndex: 'setter_id',
      width: 120,
      render: (_, record) => {
        return <div>{record?.setter?.user?.name}</div>;
      }
    },
    {
      title: 'المنشأه',
      dataIndex: 'facility_id',
      width: 120,
      render: (_, record) => {
        return <div>{record?.facility?.name}</div>;
      }
    },
    {
      title: 'تاريخ انشاء الغرفه',
      dataIndex: 'created_at',
      width: 120,
      render: (_, record) => {
        return <div>{record?.created_at.slice(0, 10)}</div>;
      }
    },
    {
      title: 'تفاصيل الغرفة',
      dataIndex: 'show',
      width: 30,
      render: (_, record) => {
        return (
          <Tooltip title="تفاصيل المربيه ">
            <Button
              className="edit-btn"
              onClick={() => history.push(`/rooms/show/${record.id}`)}
              size="large"
              type="dashed"
              shape="circle"
              icon={<InfoOutlined />}
            />
          </Tooltip>
        );
      }
    },
    {
      title: 'تعديل ',
      dataIndex: 'edit',
      width: 30,
      render: (_, record) => {
        return (
          <Tooltip title="تعديل الغرفة">
            <Button
              className="edit-btn"
              onClick={() => history.push(`/rooms/update/${record?.id}`)}
              size="large"
              type="dashed"
              shape="circle"
              icon={<EditOutlined />}
            />
          </Tooltip>
        );
      }
    },
    {
      title: 'حذف',
      dataIndex: 'delete',
      width: 30,
      render: (_, record) => {
        return (
          <Popconfirm
            title={
              <div style={{ maxWidth: '200px' }}>
                <p>هل انت متأكد من حذف الغرفة</p>
              </div>
            }
            okText="نعم"
            cancelText="لا"
            onConfirm={(id) => handleDeleteRequest(record.id)}>
            <Tooltip title="حذف الغرفة؟">
              <Button
                className="delete-btn"
                size="large"
                type="dashed"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        );
      }
    }
  ];
};

export default RoomsColumns;

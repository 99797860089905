import axios from 'axios';

const GetAllUserID = async (token) => {
  try {
    const getParentsRes = await axios.get('users/all_users', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token
      }
    });
    // console.log(getParentsRes);
    return getParentsRes;
  } catch (error) {
    console.log(error);
  }
};

export default GetAllUserID;

// =====================
// const getLocationToName = async (lat, lng) => {
//   await axios
//     .get(
//       "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
//       lat +
//       "," +
//       lng +
//       "&key=AIzaSyB8LNfxjQ6S8WJDw_0_fQbhV4HfXOeulQ4"
//     )
//     .then((Response) => {
//       setResultTo(Response.data.results[0].formatted_address);
//     });
// };

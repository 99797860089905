import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => {},
  loadingAdmin: false,
  setLoadingAdmin: (load) => {},
  allAdmin: [],
  setAllAdmin: (Admin) => {},
  adminDetails: null,
  setAdminDetails: (admin) => {},
  showAdminDetails: false,
  setShowAdminDetails: () => {}
};

const AdminContext = createContext(INITIAL_VALUES);

export const AdminProvider = ({ children }) => {
  const [allAdmin, setAllAdmin] = useState(INITIAL_VALUES.allAdmin);
  const [loadingAdmin, setLoadingAdmin] = useState(INITIAL_VALUES.loadingAdmin);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [showAdminDetails, setShowAdminDetails] = useState(INITIAL_VALUES.showAdminDetails);
  const [adminDetails, setAdminDetails] = useState(INITIAL_VALUES.adminDetails);

  return (
    <AdminContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        loadingAdmin,
        setLoadingAdmin,
        allAdmin,
        setAllAdmin,
        adminDetails,
        setAdminDetails,
        showAdminDetails,
        setShowAdminDetails
      }}>
      {children}
    </AdminContext.Provider>
  );
};

export default AdminContext;

import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => { },
  loadingTransactions: false,
  setLoadingTransactions: (load) => { },
  allTransactions: [],
  setAllTransactions: (services) => { },
  TransactionsModalOpened: false,
  setTransactionsModalOpened: (v) => { },
  selectedTransactionsId: '',
  setSelectedTransactionsId: (id) => { },
  selectedTransactions: null,
  setSelectedTransactions: (servKey) => { },
  selectedFilter: '',
  setSelectedFilter: (v) => { }
};

const TransactionsContext = createContext(INITIAL_VALUES);

export const TransactionsProvider = ({ children }) => {
  const [selectedFilter, setSelectedFilter] = useState(INITIAL_VALUES.selectedFilter);
  const [allTransactions, setAllTransactions] = useState(INITIAL_VALUES.allTransactions);
  const [loadingTransactions, setLoadingTransactions] = useState(INITIAL_VALUES.loadingTransactions);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [TransactionsModalOpened, setTransactionsModalOpened] = useState(INITIAL_VALUES.TransactionsModalOpened);
  const [selectedTransactionsId, setSelectedTransactionsId] = useState(INITIAL_VALUES.selectedTransactionsId);
  const [selectedTransactions, setSelectedTransactions] = useState(INITIAL_VALUES.selectedTransactions);

  return (
    <TransactionsContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        loadingTransactions,
        setLoadingTransactions,
        selectedFilter,
        setSelectedFilter,
        allTransactions,
        setAllTransactions,
        TransactionsModalOpened,
        setTransactionsModalOpened,
        selectedTransactionsId,
        setSelectedTransactionsId,
        selectedTransactions,
        setSelectedTransactions
      }}>
      {children}
    </TransactionsContext.Provider>
  );
};

export default TransactionsContext;

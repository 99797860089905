import axios from 'axios';

const editSettingsApi = async (values, token) => {
  try {
    const {
      about_ar,
      about_en,
      address_ar,
      address_en,
      policy_ar,
      policy_en,
      phone,
      email,
      whatsapp,

      android,
      apple,
      facebook,
      instagram,
      twitter
    } = values;
    const pickedValues = {
      about_ar,
      about_en,
      address_ar,
      address_en,
      policy_ar,
      policy_en,
      phone,
      email,
      whatsapp,

      android,
      apple,
      facebook,
      instagram,
      twitter
    };
    const formData = new FormData();
    for (let key in pickedValues) {
      formData.append(key, pickedValues[key]);
    }

    const response = await axios.post('/Setting/updateSetting', formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    if (response.status === 200 && response.data.status === 1 && response.data.data) {
      return response.data;
    }
  } catch (e) {
    
  }
};

export default editSettingsApi;

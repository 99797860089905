
import { EditOutlined, SendOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button, Form } from 'antd';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { useEffect, useState } from 'react';
import { Store } from "react-notifications-component";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CreateTransactionsApi from '../../apis/transactions-api/createTransactionsApi';
import SelectSearchBox from "../../common/SelectSearchBox/SelectSearchBox";
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import UesrContext from '../../contexts/user-context/UserProvider';
import getAllTransactionsId from '../../apis/transactions-api/getAllUserID';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';

const defaultValues = {
  user_id: "",
  type: "",
  amount: null,
};

const CreateTransactions = () => {
  const { user } = useContext(UesrContext);
  const [dataTransactionsId, setDataTransactionsId] = useState([]);
  // console.log(user);
  let history = useHistory();

  let schema = Yup.object().shape({
    type: Yup.string().required('من فضلك أدخل المطلوب'),
    amount: Yup.number().required('من فضلك أدخل المطلوب'),
    user_id: Yup.string().required('من فضلك أدخل المطلوب'),

  });



  const {
    control,
    // getValues,
    setValue,
    // setError,
    // clearErrors,
    handleSubmit,
    register,
    watch,
    unregister,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });
  const [urls, setUrls] = useState([]);
  console.log()
  // console.log(watch())

  const onSubmit = async (data) => {
    // console.log(data)
    try {
      let res;
      res = await CreateTransactionsApi('/transactions/create', { ...data }, user?.token,);
      // console.log(res)

      // console.log('create Selles response: ', res);
      if (res?.status !== 200) {
        Store.addNotification({
          title: 'حدث خطء اثناء الأضافه',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (res?.status === 200 && res?.data?.status_code === 200) {
        reset({ ...defaultValues });
        Store.addNotification({
          title: 'تمت العملية بنجاح',
          message: 'تم الأضافه ',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
        history.push("/transactions")
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [form] = Form.useForm();

  useEffect(() => {
    const handleGetTransactions = async () => {
      try {
        const getTransactionsRes = await getAllTransactionsId(user?.token);
        // console.log(getTransactionsRes.data.user_ids);

        if (getTransactionsRes.status === 200 && getTransactionsRes?.data?.users) {
          setDataTransactionsId(getTransactionsRes?.data?.users);

        }
      } catch (error) {

      }
    };
    handleGetTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Form
      className="add-Selles-form flex justify-center items-center"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}>
      <div
        className="form-body my-[40px] w-full shadow-[rgba(100,_100,_111,_0.2)_0px_7px_29px_0px] max-w-[800px] py-[40px] px-[30px]">
        <div className="form-field-wrapper">
          <AntdTextField
            name="amount"
            type="text"
            placeholder="الكمية..."
            errorMsg={errors?.amount?.message}
            validateStatus={errors?.amount ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        {/* <div className="form-field-wrapper">
          <AntdTextField
            name="type"
            type="text"
            placeholder="النوع..."
            errorMsg={errors?.type?.message}
            validateStatus={errors?.type ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div> */}
        <AntdSelectOption
          name="type"
          errorMsg={errors?.type?.message}
          validateStatus={errors?.type ? 'error' : ''}
          control={control}
          setValue={setValue}
          options={[
            { title: 'credit ', value: 'credit ' },
            { title: 'debit', value: 'debit' },
          ]}
          label={
            <p className="select-option-label">
              <EditOutlined />
              النوع
            </p>
          }
          formClassName="add-Selles-form"
        />




        <div className=" form-field-wrapper py-[10px]">
          <SelectSearchBox
            name="user_id"
            errorMsg={errors?.user_id?.message}
            validateStatus={errors?.user_id ? 'error' : ''}
            defaultValue=""
            placeholder="اختر المستخدم..."

            control={control}
            setValue={setValue}
            dataSearch={dataTransactionsId}
            value={watch}
            label={
              <p className="select-option-label">
                <EditOutlined />
                اختر المستخدم
              </p>
            }

          />
        </div>






        {/* <div className="form-field-wrapper">
          {!selectedServId && (
            <AntdTextField
              name="image"
              type="file"
              placeholder="موضوع..."
              errorMsg={errors?.image?.message}
              validateStatus={errors?.image ? 'error' : ''}
              prefix={<EditOutlined />}
              control={control}
            />
          )}
        </div> */}

        <Button
          className="submit-btn mx-auto mt-[30px]  !h-[45px] !w-[100px]   !flex justify-center items-center"
          htmlType="submit"
          type="primary"
          icon={<SendOutlined />}
          loading={isSubmitting}>
          اضافه
        </Button>
      </div>

    </Form >
  );
};

export default CreateTransactions;

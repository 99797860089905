import { Table } from 'antd';
import React, { useContext, useEffect } from 'react';
import UesrContext from '../../contexts/user-context/UserProvider';
import TicketsColumns from './ticketsColumns';
import TicketsContext from '../../contexts/tickets-context/TicketsProvider';
import getAllTicketsApi from '../../apis/tickets-api/getAllTicketsApi';
const TicketsTable = () => {
  const { user } = useContext(UesrContext);
  const {
    fetchCount,
    setFetchCount,
    loadingTickets,
    setLoadingTickets,
    allTickets,
    setAllTickets,
    setParentModalOpened,
    setSelectedParent
  } = useContext(TicketsContext);

  useEffect(() => {
    const getTickets = async () => {
      try {
        setLoadingTickets(true);
        const getTicketsRes = await getAllTicketsApi(user?.token);
        // console.log(getTicketsRes);
        if (getTicketsRes.status === 200 && getTicketsRes?.data?.tickets) {
          setAllTickets(getTicketsRes?.data?.tickets);
          // console.table(getTicketsRes?.data?.driver?.data)
          // console.log(getTicketsRes?.data?.driver?.data)
          setLoadingTickets(false);
        }
      } catch (error) {
        setLoadingTickets(false);
      }
    };
    getTickets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCount]);
  // console.log(allTickets);
  return (
    <Table
      pagination={{ pageSize: 5 }}
      className="Tickets-table"
      dataSource={allTickets}
      columns={TicketsColumns(
        user?.token,
        setFetchCount,
        setLoadingTickets,
        setParentModalOpened,
        setSelectedParent
      )}
      loading={loadingTickets}
      scroll={{ x: 1200 }}
    />
  );
};

export default TicketsTable;

import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  facilityFetchCount: 0,
  setFacilityFetchCount: (count) => {},
  loadingFacility: false,
  setLoadingFacility: (load) => {},
  allFacility: [],
  setAllFacility: (Orders) => {},
  facilityDetails: '',
  setFacilityDetails: (Orders) => {},
  targetFacility: '',
  setTargetFacility: (Orders) => {}
};

const FacilityContext = createContext(INITIAL_VALUES);

export const FacilityProvider = ({ children }) => {
  const [allFacility, setAllFacility] = useState(INITIAL_VALUES.allFacility);
  const [loadingFacility, setLoadingFacility] = useState(INITIAL_VALUES.loadingFacility);
  const [facilityFetchCount, setFacilityFetchCount] = useState(INITIAL_VALUES.facilityFetchCount);
  const [facilityDetails, setFacilityDetails] = useState(INITIAL_VALUES.facilityDetails);
  const [targetFacility, setTargetFacility] = useState(INITIAL_VALUES.targetFacility);

  return (
    <FacilityContext.Provider
      value={{
        facilityFetchCount,
        setFacilityFetchCount,
        loadingFacility,
        setLoadingFacility,
        allFacility,
        setAllFacility,
        facilityDetails,
        setFacilityDetails,
        targetFacility,
        setTargetFacility
      }}>
      {children}
    </FacilityContext.Provider>
  );
};

export default FacilityContext;

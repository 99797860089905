import axios from 'axios';

const deleteChildrenApi = async (ChildrenId, token) => {
  try {
    const response = await axios.delete(`childrens/delete/${ChildrenId}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    console.log(response);

    if (response.status === 200 && response.data?.status_code === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
};

export default deleteChildrenApi;

import axios from 'axios';

const deleteSitterApi = async (SitterId, token) => {
  try {
    const response = await axios.delete(`/setters/delete/${SitterId}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    if (response.status === 200) {
      console.log(response);
      return response;
    }
  } catch (e) {
    return e?.response;
  }
};

export default deleteSitterApi;

import axios from 'axios';

const CreateParentApi = async (url, values, token) => {
  // console.log(values)
  const {
    phone,
    email,
    image,
    map_loc,
    date_of_birth,
    gender,
    national_id,
    nationality,
    address,
    name,
    phone_code,
    password
  } = values;
  console.log(map_loc);
  const formData = new FormData();

  if (Array.isArray(image)) {
    formData.append('image', image[0]);
  }
  formData.append('name', name);
  formData.append('email', email);
  formData.append('address', address);
  formData.append('phone', phone);
  formData.append('date_of_birth', date_of_birth);
  formData.append('gender', gender);
  formData.append('nationality', nationality);
  formData.append('phone_code', phone_code);
  formData.append('national_id', national_id);
  formData.append('long', map_loc?.long);
  formData.append('lat', map_loc?.lat);
  formData.append('password', password);

  try {
    // console.log(image[0])

    const createSerivceRes = await axios.post(url, formData, {
      headers: {
        Accept: 'application/json',

        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      }
    });
    console.log(createSerivceRes);

    return createSerivceRes;
  } catch (error) {
    // console.log(error?.response)
    return error?.response;
  }
};

export default CreateParentApi;

import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => {},
  loadingCitys: false,
  setLoadingCitys: (load) => {},
  allCitys: [],
  setAllCitys: (Citys) => {},
  CityModalOpened: false,
  setCityModalOpened: (v) => {},
  ShowAreaModalOpened: false,
  setShowAreaModalOpened: (v) => {},
  selectedCityId: '',
  setSelectedCityId: (id) => {},
  selectedCity: null,
  setSelectedCity: (CityKey) => {}
};

const CitysContext = createContext(INITIAL_VALUES);

export const CitysProvider = ({ children }) => {
  const [allCitys, setAllCitys] = useState(INITIAL_VALUES.allCitys);
  const [loadingCitys, setLoadingCitys] = useState(INITIAL_VALUES.loadingCitys);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [CityModalOpened, setCityModalOpened] = useState(INITIAL_VALUES.CityModalOpened);
  const [ShowAreaModalOpened, setShowAreaModalOpened] = useState(
    INITIAL_VALUES.ShowAreaModalOpened
  );
  const [selectedCityId, setSelectedCityId] = useState(INITIAL_VALUES.selectedCityId);
  const [selectedCity, setSelectedCity] = useState(INITIAL_VALUES.selectedCity);

  return (
    <CitysContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        loadingCitys,
        setLoadingCitys,
        allCitys,
        setAllCitys,
        CityModalOpened,
        setCityModalOpened,
        ShowAreaModalOpened,
        setShowAreaModalOpened,
        selectedCityId,
        setSelectedCityId,
        selectedCity,
        setSelectedCity
      }}>
      {children}
    </CitysContext.Provider>
  );
};

export default CitysContext;

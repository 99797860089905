import { useState } from "react";

const GetCurrentLocation = () => {
  const [result, setResult] = useState(null);


  const getLocationName = async (lat, lng) => {
    try {
      const response = await fetch(
        // `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyB8LNfxjQ6S8WJDw_0_fQbhV4HfXOeulQ4`
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_MAP_KEY}`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      console.log("data.results[0]", data.results[0])
      const formattedAddress = data.results[0];
      setResult(formattedAddress);
      // return formattedAddress;
    } catch (error) {
      // console.error(error);
      setResult(null)
      // throw error;
    }
  };
  const successCallBack = (position) => {
    getLocationName(position.coords.latitude, position.coords.longitude);
  };

  const errorCallBack = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        setResult("You denied the request for you location.");
        break;
      case error.POSITION_UNAVAILABLE:
        setResult("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        setResult("The request to get your location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        setResult("An unknown error occurred.");
        break;

      default:
        setResult("Error");
    }
  };
  const getLocation = () => {
    navigator.geolocation.getCurrentPosition(successCallBack, errorCallBack);
  };

  return {
    getLocation, result

  };
  // return (
  //   <>
  //     {/* <button
  //       style={{
  //         border: "none",
  //         background: "rgb(62 58 112)",
  //         color: "#fff",
  //         padding: "10px 20px",
  //         margin: "20px 0 10px 0",
  //         cursor: "pointer",
  //       }}
  //       onClick={getLocation}
  //     >
  //       Get Current Location
  //     </button>

  //     <div> current : {result}</div> */}


  //   </>
  // );
};

export default GetCurrentLocation;

import React, { useContext } from 'react';
import CountarysContext from '../../contexts/countary-context/CountaryProvider';
import CountaryTable from './CountaryTable';
import { Modal } from 'antd';
import CountarysModel from './CountarysModel';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import './CountryPage.scss';
import CityPage from '../city-page/CityPage';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const CountaryPage = () => {
  const {
    CountaryModalOpened,
    setCountaryModalOpened,
    ShowCityModalOpened,
    setShowCityModalOpened,
    setSelectedServId,
    setSelectedCountary
  } = useContext(CountarysContext);
  const history = useHistory();
  return (
    <div className="Countarys-page">
      <div className="new-filter-wrap">
        <Button
          onClick={() => history.push('/countary/create')}
          className="add-serv-btn"
          type="primary"
          icon={<PlusOutlined />}>
          <span className="btn-text">اضافة بلد</span>
        </Button>

        {/* <CountarysFilter /> */}
      </div>
      <CountaryTable />
      {/* {CountaryModalOpened && (
        <Modal
          className="add-Countary-modal"
          width="90%"
          style={{ maxWidth: '900px' }}
          title={setSelectedServId ? 'اضـافة بلد جديدة' : 'تعديل البلد '}
          visible={CountaryModalOpened}
          onOk={() => {
            setCountaryModalOpened(false);
            setSelectedServId('');
            setSelectedCountary(null);
          }}
          onCancel={() => {
            setCountaryModalOpened(false);
            setSelectedServId('');
            setSelectedCountary(null);
          }}
          footer={false}>
          <CountarysModel />
        </Modal>
      )}
      {ShowCityModalOpened && (
        <Modal
          className="add-Countary-modal"
          width="90%"
          style={{ maxWidth: '900px' }}
          title={'المدن'}
          visible={ShowCityModalOpened}
          onOk={() => {
            setShowCityModalOpened(false);
            setSelectedServId('');
            setSelectedCountary(null);
          }}
          onCancel={() => {
            setShowCityModalOpened(false);
            setSelectedServId('');
            setSelectedCountary(null);
          }}
          footer={false}>
          <CityPage />
        </Modal>
      )} */}
    </div>
  );
};

export default CountaryPage;

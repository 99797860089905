import axios from 'axios';

const deleteCountaryApi = async (CountaryId, token) => {
  try {
    const response = await axios.delete(`/country/delete/${CountaryId}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    return e.massage;
  }
};

export default deleteCountaryApi;

import React, { useContext, useEffect } from 'react';

import { useForm } from 'react-hook-form';

import { Button, Form } from 'antd';

import { EditOutlined, SendOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AntdTextField from '../../common/antd-form-components/AntdTextField';

import { Store } from 'react-notifications-component';
import editSettingsApi from '../../apis/settings-api/editSettingsApi';
import getSettingsApi from '../../apis/settings-api/getSettingsApi';
import SettingsContext from '../../contexts/settings-context/SettingsProvider';
import UesrContext from '../../contexts/user-context/UserProvider';

const schema = yup.object().shape({
  about_ar: yup.string().required('عنا بالعربيه مطلوب'),
  about_en: yup.string().required('عنا بالانجليزيه مطلوب'),
  address_ar: yup.string().required('العنوان بالعربيه مطلوب'),
  address_en: yup.string().required('العنوان بالانجليزيه مطلوب'),
  policy_ar: yup.string().required('ساسية الخصوصيه بالعربيه مطلوب'),
  policy_en: yup.string().required('ساسيه الخصوصيه بالانجليزيه مطلوب'),
  phone: yup
    .string()
    .required('من فضلك ادخل رقم الهاتف')
    .matches(/^[0-9]+$/, 'لا يسمح الا بالارقــام'),
  email: yup.string().required('البريد الألكتروني').email('من فضلك ادخل بريد الكترونى صحيح'),
  whatsapp: yup.string().required('رقم الواتساب مطلوب'),

  android: yup.string().required('الرابط علي الاندرويد مطلوب'),
  apple: yup.string().required('الرابط علي ايفون مطلوب'),
  facebook: yup.string().required('لينك الفيسبوك مطلوب'),
  instagram: yup.string().required('لينك الانستجرام مطلوب'),
  twitter: yup.string().required('لينك تويتر مطلوب')
});

const defaultValues = {
  about_ar: '',
  about_en: '',
  address_ar: '',
  address_en: '',
  policy_ar: '',
  policy_en: '',
  phone: '',
  email: '',
  whatsapp: '',

  android: '',
  apple: '',
  facebook: '',
  instagram: '',
  twitter: ''
};

const SettingsForm = () => {
  const { user } = useContext(UesrContext);

  const {
    control,
    setValue,
    handleSubmit,

    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });

  const [form] = Form.useForm();

  const { fetchCount, setLoadingSettingsData, allSettingsData, setAllSettingsData } =
    useContext(SettingsContext);

  useEffect(() => {
    const getServices = async () => {
      try {
        setLoadingSettingsData(true);
        const getSettingsRes = await getSettingsApi(user?.token);

        if (getSettingsRes.status === 200 && getSettingsRes?.data?.data) {
          setAllSettingsData(getSettingsRes.data.data);
          setLoadingSettingsData(false);

        }
      } catch (error) {
        setLoadingSettingsData(false);
        //
      }
    };
    getServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCount]);

  useEffect(() => {
    //
    if (allSettingsData) {

      setValue('about_ar', allSettingsData.about_ar);
      setValue('phone', allSettingsData.phone);
      setValue('email', allSettingsData.email);
      setValue('about_en', allSettingsData.about_en);
      setValue('address_ar', allSettingsData.address_ar);
      setValue('address_en', allSettingsData.address_en);
      setValue('policy_ar', allSettingsData.policy_ar);
      setValue('policy_en', allSettingsData.policy_en);
      setValue('whatsapp', allSettingsData.whatsapp);
      setValue('android', allSettingsData.android);
      setValue('apple', allSettingsData.apple);
      setValue('facebook', allSettingsData.facebook);
      setValue('instagram', allSettingsData.instagram);
      setValue('twitter', allSettingsData.twitter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSettingsData]);

  const onSubmit = async (data) => {
    try {
      const fetchedData = await editSettingsApi(data, user?.token);
      if (!fetchedData) {
        Store.addNotification({
          title: 'حدث خطأ ',
          message: fetchedData?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        setAllSettingsData(fetchedData.data);
        Store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم تعديل البيانات بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      Store.addNotification({
        title: 'حدث خطأ ',
        message: 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
      //
    }
  };
  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
        className="add-project-form">
        <div className="form-field-wrapper">
          <AntdTextField
            name="about_ar"
            type="text"
            placeholder="عنا بالعربيه..."
            errorMsg={errors?.about_ar?.message}
            validateStatus={errors?.about_ar ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>

        <div className="form-field-wrapper">
          <AntdTextField
            name="about_en"
            type="text"
            placeholder="عنا بالانجليزيه..."
            errorMsg={errors?.about_en?.message}
            validateStatus={errors?.about_en ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="address_ar"
            type="text"
            placeholder="العنوان بالعربيه..."
            errorMsg={errors?.address_ar?.message}
            validateStatus={errors?.address_ar ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="address_en"
            type="text"
            placeholder="العنوان بالانجليزيه..."
            errorMsg={errors?.address_en?.message}
            validateStatus={errors?.address_en ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="policy_ar"
            type="text"
            placeholder="سياسه الخصوصيه بالعربيه..."
            errorMsg={errors?.policy_ar?.message}
            validateStatus={errors?.policy_ar ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="policy_en"
            type="text"
            placeholder="سياسه الخصوصيه بالانجليزيه..."
            errorMsg={errors?.policy_en?.message}
            validateStatus={errors?.policy_en ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="email"
            type="text"
            placeholder="البريد الألكتروني..."
            errorMsg={errors?.email?.message}
            validateStatus={errors?.email ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="phone"
            type="text"
            placeholder="رقم الهاتف..."
            errorMsg={errors?.phone?.message}
            validateStatus={errors?.phone ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>

        <div className="form-field-wrapper">
          <AntdTextField
            name="whatsapp"
            type="text"
            placeholder="رقم الواتساب..."
            errorMsg={errors?.whatsapp?.message}
            validateStatus={errors?.whatsapp ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>

        <div className="form-field-wrapper">
          <AntdTextField
            name="android"
            type="text"
            placeholder="الرابط علي الاندرويد..."
            errorMsg={errors?.android?.message}
            validateStatus={errors?.android ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>

        <div className="form-field-wrapper">
          <AntdTextField
            name="apple"
            type="text"
            placeholder="الرابط علي ايفون..."
            errorMsg={errors?.apple?.message}
            validateStatus={errors?.apple ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="facebook"
            type="text"
            placeholder="رابط فيسبوك..."
            errorMsg={errors?.facebook?.message}
            validateStatus={errors?.facebook ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="twitter"
            type="text"
            placeholder="رابط تويتر..."
            errorMsg={errors?.twitter?.message}
            validateStatus={errors?.twitter ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>

        <div className="form-field-wrapper">
          <AntdTextField
            name="instagram"
            type="text"
            placeholder="رابط الانستجرام..."
            errorMsg={errors?.instagram?.message}
            validateStatus={errors?.instagram ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>

        <div>
          <Button
            className="submit-btn"
            htmlType="submit"
            type="primary"
            icon={<SendOutlined />}
            loading={isSubmitting}>
            تعديل
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default SettingsForm;

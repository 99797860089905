import { Table } from 'antd';
import React, { useContext, useEffect } from 'react';
import getAllChildren from '../../apis/children-api/getAllChildren';

import ChildrenContext from '../../contexts/children-context/ChildrenProvider';

import UesrContext from '../../contexts/user-context/UserProvider';
import ChildrenColumns from './ChildrenColumns';
const ChildrenTable = () => {
  const { user } = useContext(UesrContext);
  const {
    fetchCount,
    setFetchCount,
    loadingChildren,
    setLoadingChildren,
    allChildren,
    setAllChildren,
    setOrderModalOpened,
    setSelectedServId
  } = useContext(ChildrenContext);

  useEffect(() => {
    const getChildrens = async () => {
      try {
        setLoadingChildren(true);
        const getChildrenRes = await getAllChildren(user?.token);
        console.log(getChildrenRes.data.childrens);

        if (getChildrenRes.status === 200 && getChildrenRes?.data?.childrens) {
          setAllChildren(getChildrenRes?.data?.childrens);
          setLoadingChildren(false);
        }
      } catch (error) {
        setLoadingChildren(false);
      }
    };
    getChildrens();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCount]);
  return (
    <Table
      pagination={{ pageSize: 5 }}
      className="Orders-table"
      dataSource={allChildren}
      columns={ChildrenColumns(
        user?.token,
        setFetchCount,
        setLoadingChildren,
        setOrderModalOpened,
        setSelectedServId
      )}
      loading={loadingChildren}
      scroll={{ x: 1200 }}
    />
  );
};

export default ChildrenTable;

import UserActionTypes from './user.types';
import Cookies from 'js-cookie';



export const setDataEntryUserToStateAction = (user) => {
  sessionStorage.setItem('currentDataEntryUser', JSON.stringify(user));

  return {
    type: UserActionTypes.SET_DATA_ENTRY_USER_TO_STATE,
    payload: user
  };
};

export const setDataEntryUser = (user) => {
  // localStorage.setItem('currentUser', JSON.stringify(user));
  Cookies.set('currentDataEntryUser', JSON.stringify(user), {
    sameSite: 'strict'
    // expires: 90
    // expires: 5 / (24 * 60 * 60) // 5 secondas
  });

  return {
    type: UserActionTypes.SET_DATA_ENTRY_USER,
    payload: user
  };
};

export const dataEntryremoveUser = () => {
  sessionStorage.removeItem('currentDataEntryUser');
  Cookies.remove('currentDataEntryUser');
  return {
    type: UserActionTypes.DATA_ENTRY_REMOVE_USER
  };
};
import axios from 'axios';

const signoutApi = async (token) => {
  try {
    const signoutRes = await axios.post('auth/logout', null, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });

    return signoutRes;
  } catch (error) {
    return error?.response;
  }
};

export default signoutApi;

import { Button, Modal, Popconfirm, Popover, Tooltip } from 'antd';
import { DeleteOutlined, EyeTwoTone, InfoOutlined } from '@ant-design/icons';
import { Store } from 'react-notifications-component';
import deleteContact from '../../apis/contact-api/deleteContact';

import { useContext, useState } from 'react';
import ContactUsContext from '../../contexts/contactUs-context/ContactUsProvider';



const PopOverAnswers = ({ content, title }) => (
  <Popover title={title} content={content}>
    <Button size="large" type="dashed" shape="circle" icon={<EyeTwoTone />} />
  </Popover>
);

const deleteProjectCall = async (data, count, setCount, token) => {
  try {
    const res = await deleteContact(data, token);

    if (res.status === 1) {
      setCount(count + 1);
      Store.addNotification({
        title: 'العملية تمت بنجاح',
        message: 'تم حذف الرساله بنجاح',
        type: 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    } else {
      Store.addNotification({
        title: 'حدث خطأ ',
        message: res?.data?.message || 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  } catch (error) {

    Store.addNotification({
      title: 'حدث خطأ ',
      message: 'حاول فى وقت لاحق',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 2000,
        showIcon: true,
        onScreen: true
      }
    });
  }
};
const ContactUsColumns = (projectCount, setProjectCount, token) => {


  
  const { setContactUsModalOpened ,setSelectedContactUs} = useContext(ContactUsContext);

  const handleViewMessage = (key) => {
    setContactUsModalOpened(true);
    setSelectedContactUs(key);
  };
  return [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key'
    },
    {
      title: 'الأسم ',
      dataIndex: 'name',
      key: 'name'
    },
    // {
    //   title: 'رقم الهاتف',
    //   dataIndex: 'phone',
    //   key: 'phone'
    // },
    {
      title: 'البريد الالكتروني',
      dataIndex: 'email',
      key: 'email'
    },
    // {
    //   title: 'الموقع',
    //   dataIndex: 'website',
    //   key: 'website'
    // },
    // {
    //   title: 'تفاصيل الرساله',
    //   dataIndex: 'message',
    //   width: 82,
    //   render: (v) => {
    //     return <PopOverAnswers content={v} title="تفاصيل الرساله" />;
    //   }
    // },
    {
      title: 'تفاصيل الرساله',
      dataIndex: 'message',
      width: 82,
      render: (_, record) => {


        return (
          <>
            <Tooltip title="تفاصيل الرسالة ">
              <Button
                className="edit-btn"
                onClick={(key) => handleViewMessage(record)}
                size="large"
                type="dashed"
                shape="circle"
                icon={<InfoOutlined />}
              />
            </Tooltip>
            
          </>

        );

      }
    },
    {
      title: 'حذف الرساله',
      key: 'action',
      render: (_, record) => (
        <div className="opation-btn">
          <div className="delete">
            <Popconfirm
              title="هل أنت متأكد من حذف ذلك الرساله"
              onConfirm={(key) => deleteProjectCall(record.key, projectCount, setProjectCount, token)}
              okText="نعم"
              cancelText="لا">
              <Tooltip title="حذف الرساله">
                <Button
                  className="delete-btn"
                  size="large"
                  type="dashed"
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
              {/* <button>حذف</button> */}
            </Popconfirm>
          </div>
        </div>
      )
    }
  ];
}

export default ContactUsColumns;

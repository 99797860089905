import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => {},
  loadingTickets: false,
  setLoadingTickets: (load) => {},
  allTickets: [],
  setAllTickets: (Tickets) => {},
  allTicketsThreads: [],
  setTicketsThreads: (Tickets) => {},
  TicketsModalOpened: false,
  setTicketsModalOpened: (v) => {},
  TicketsLocationModalOpened: false,
  setTicketsLocationModalOpened: (v) => {},
  selectedServId: '',
  setSelectedServId: (id) => {},
  selectedTickets: null,
  setSelectedTickets: (servKey) => {}
};

const TicketsContext = createContext(INITIAL_VALUES);

export const TicketsProvider = ({ children }) => {
  const [allTicketsThreads, setTicketsThreads] = useState(INITIAL_VALUES.allTickets);
  const [allTickets, setAllTickets] = useState(INITIAL_VALUES.allTickets);
  const [loadingTickets, setLoadingTickets] = useState(INITIAL_VALUES.loadingTickets);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [TicketsModalOpened, setTicketsModalOpened] = useState(INITIAL_VALUES.TicketsModalOpened);
  const [TicketsLocationModalOpened, setTicketsLocationModalOpened] = useState(
    INITIAL_VALUES.TicketsLocationModalOpened
  );
  const [selectedServId, setSelectedServId] = useState(INITIAL_VALUES.selectedServId);
  const [selectedTickets, setSelectedTickets] = useState(INITIAL_VALUES.selectedTickets);

  return (
    <TicketsContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        loadingTickets,
        setLoadingTickets,
        allTickets,
        setAllTickets,
        allTicketsThreads,
        setTicketsThreads,
        TicketsModalOpened,
        setTicketsModalOpened,
        selectedServId,
        setSelectedServId,
        selectedTickets,
        setSelectedTickets,
        setTicketsLocationModalOpened,
        TicketsLocationModalOpened
      }}>
      {children}
    </TicketsContext.Provider>
  );
};

export default TicketsContext;

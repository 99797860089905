import axios from 'axios';

const deleteSitterApi = async (SitterId, token) => {
  try {
    const response = await axios.post(`Support/delete?id=${SitterId}`, null, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    if (response.status === 200 && response.data.status === 1) {
      return response;
    }
  } catch (e) {
    
  }
};

export default deleteSitterApi;

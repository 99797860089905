import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { EditOutlined, SendOutlined } from '@ant-design/icons';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { Button, Form, Input, Select } from 'antd';
import { Store } from 'react-notifications-component';
import UesrContext from '../../contexts/user-context/UserProvider';
import AreasContext from '../../contexts/area-context/AreaProvider';
import './AreaPage.scss';
import createArea from '../../apis/area-api/createArea';
import CitysContext from '../../contexts/City-context/CityProvider';
import axios from 'axios';

const defaultValues = {
  name_ar: '',
  name_en: ''
};

const AreasModel = ({ allCountries }) => {
  const { user } = useContext(UesrContext);
  const { selectedCityId } = useContext(CitysContext);

  const [allCities, setAllCities] = useState();

  const getAllCitys = async (country_id, token) => {
    try {
      const getCitysRes = await axios.get('https://api.haleemh.sa/api/Location/cities?country_id=' + country_id, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'applications/json',
          Authorization: 'Bearer ' + token
        }
      });
      setAllCities(getCitysRes.data.data)
      return getCitysRes;
    } catch (error) {

    }
  }

  const {
    setFetchCount,
    setAreaModalOpened,
    allAreas,
    setSelectedAreaId,
    selectedAreaId,
    selectedArea,
    setSelectedArea
  } = useContext(AreasContext);

  let schema;
  if (selectedArea && selectedAreaId) {
    schema = Yup.object().shape({
      name_ar: Yup.string().required('من فضلك ادخل منطقه باللغة العربية'),
      name_en: Yup.string().required('من فضلك ادخل منطقه باللغة الانجليزية')
    });
  } else {
    schema = Yup.object().shape({
      name_ar: Yup.string().required('من فضلك ادخل منطقه باللغة العربية'),
      name_en: Yup.string().required('من فضلك ادخل منطقه باللغة الانجليزية')
    });
  }

  useEffect(() => {
    if (selectedAreaId) {
      const foundArea = allAreas.find((Area) => Area.id === selectedAreaId);
      if (foundArea) setSelectedArea(foundArea);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAreaId]);

  const {
    control,

    setValue,

    handleSubmit,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });

  // 
  // 

  const onSubmit = async (data) => {
    delete data["country_id"];
    try {
      let res;
      if (selectedArea) {
        res = await createArea(
          `/Area/update/${selectedArea.id}`,
          data,
          user?.token
        );
      } else {

        res = await createArea('/Area/create', data, user?.token);
      }
      // 
      if (res?.status === 200 && res?.data?.status === 0) {
        Store.addNotification({
          title: 'حدث خطأ اثناء انشاء منطقه',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (res?.status === 200 && res?.data?.status === 1) {
        reset({ ...defaultValues });

        setAreaModalOpened(false);
        setSelectedAreaId('');
        setSelectedArea(null);
        setFetchCount((prevState) => prevState + 1);
        Store.addNotification({
          title: 'تمت العملية بنجاح',
          message: 'تمت العملية بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {

    }
  };

  const [form] = Form.useForm();

  // handle initial values
  useEffect(() => {
    
    if (selectedArea && selectedAreaId) {
      
      getAllCitys(selectedArea.country_id, user.token)


      
      const { name_ar, name_en, country_id, city_id } = selectedArea;
      form.setFieldsValue({
        'name_ar': name_ar,
        'name_en': name_en,
        'country_id': country_id,
        'city_id':city_id
      })
      setValue('name_ar', name_ar);
      setValue('name_en', name_en);

      // convertPathToFile(image).then((file) => {
      //   
      //   setValue('Area_imgs', [file]);
      // });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedArea, selectedAreaId]);

  const onCountryChange = (value) => {
    getAllCitys(value, user.token)


  };
  const onSearch = (value) => {

  };

  return (
    <Form className="add-Area-form" form={form} layout="vertical" onFinish={onSubmit}>
      <div className="form-body">
        
        <Form.Item
          name="name_ar"

          control={control}
          rules={[{ required: true, message: 'من فضلك ادخل  اسم المنطقة باللغة العربية' }]}>
          <Input prefix={<EditOutlined />} placeholder={'  اســم المنطقة باللغة العربية...'} />
        </Form.Item>
        <Form.Item
          name="name_en"
          rules={[{ required: true, message: 'من فضلك ادخل  اسم المنطقة باللغة الانجليزية' }]}>
          <Input prefix={<EditOutlined />} value="sd" placeholder={'  اســم المنطقة باللغة الانجليزية...'} />
        </Form.Item>

        <Form.Item name="country_id" rules={[{ required: true, message: 'من فضلك ادخل  الدولة ' }]}>
          <Select
            showSearch
            placeholder="اختر الدولة..."
            optionFilterProp="children"
            onChange={onCountryChange}
            onSearch={onSearch}


          >
            {allCountries && allCountries.map(item => (
              <Select.Option value={item.id} key={item.id}>{item.name_ar}</Select.Option>
            ))}

          </Select>
        </Form.Item>
        <Form.Item name="city_id" rules={[{ required: true, message: 'من فضلك ادخل  المدينة ' }]}>
          <Select
            showSearch
            placeholder="اختر المدينة..."
            optionFilterProp="children"


          >
            {allCities && allCities.map(item => (

              <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          icon={<SendOutlined />}
          loading={isSubmitting}>
            {selectedAreaId ? "تعديل المنطقة": "اضافة منطقة" }
         
        </Button>
      </div>

      {/* <div dir="ltr">
          <h1>Values</h1>
          <pre>{JSON.stringify(getValues(), null, 2)}</pre>
        </div> */}
    </Form>
  );
};

export default AreasModel;

import { useContext } from 'react';
import UesrContext from '../../contexts/user-context/UserProvider';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Descriptions, Spin } from 'antd';
import { useParams, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect, useState } from 'react';
import getFacility from '../../apis/facility_api/getFacilityApi';
import FacilityContext from '../../contexts/facility_context/FacilityProvider';

const FacilityDetails = () => {
  /*   const { showDetails, userDetails ,setShowDetails } = useContext(UesrContext);
    console.log(userDetails) */

  const { user } = useContext(UesrContext);
  let history = useHistory();
  let param = useParams();
  const { targetFacility, setTargetFacility, loadingFacility, setLoadingFacility } =
    useContext(FacilityContext);

  useEffect(() => {
    const getTargetFacility = async () => {
      setLoadingFacility(true);
      try {
        const targetFacilityRes = await getFacility(user?.token, param?.id);

        if (targetFacilityRes?.status === 200) {
          setLoadingFacility(false);
          setTargetFacility(targetFacilityRes?.data?.facility_details);
          console.log(targetFacilityRes?.data?.facility_details);
        }
      } catch (error) {
        throw error;
      }
    };

    getTargetFacility();
  }, []);

  return (
    <>
      {loadingFacility ? (
        <div className="mx-auto mt-[100px] w-[100px]">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className="flex flex-row justify-between">
            <h2 className="text-[20px] ">بيانات المنشأة : </h2>
            <Button
              onClick={() => history.push('/facilities')}
              icon={<CloseOutlined />}
              size="small"></Button>
          </div>

          <div className="show_details">
            <div>
              <Descriptions bordered column={1} loading={loadingFacility}>
                {targetFacility?.name && (
                  <Descriptions.Item label="أسم المنشأة">{targetFacility?.name}</Descriptions.Item>
                )}
                {targetFacility?.space && (
                  <Descriptions.Item label="المساحة">{targetFacility?.space}</Descriptions.Item>
                )}
                {targetFacility?.num_of_rooms && (
                  <Descriptions.Item label="عدد الغرف">
                    {targetFacility?.num_of_rooms}
                  </Descriptions.Item>
                )}
                {targetFacility?.created_at && (
                  <Descriptions.Item label="تاريخ الأنشاء">
                    {targetFacility?.created_at.slice(0, 10)}
                  </Descriptions.Item>
                )}
                {targetFacility?.updated_at && (
                  <Descriptions.Item label="تاريخ التحديث">
                    {targetFacility?.updated_at.slice(0, 10)}
                  </Descriptions.Item>
                )}
                {targetFacility?.status && (
                  <Descriptions.Item label="حالة المنشأة">
                    {targetFacility?.status}
                  </Descriptions.Item>
                )}
                {targetFacility?.setter?.user?.name && (
                  <Descriptions.Item label="المربية">
                    {targetFacility?.setter?.user?.name}
                  </Descriptions.Item>
                )}
              </Descriptions>
            </div>

            <div>
              <span className="text-[18px] mb-[15px] block text-[#2e2d2d] border-b-[1px] pb-[5px]">
                عقد الايجار :{' '}
              </span>
              <Descriptions>
                {targetFacility?.rent_contract_url && (
                  <Descriptions.Item>
                    <img
                      src={targetFacility?.rent_contract_url}
                      alt="rent_contract_image"
                      className="w-full block"
                    />
                  </Descriptions.Item>
                )}
              </Descriptions>
              <span className="text-[18px] mb-[15px] block text-[#2e2d2d] border-b-[1px] pb-[5px]">
                {' '}
                الرقم الضريبي:{' '}
              </span>
              <Descriptions>
                {targetFacility?.tax_id_url && (
                  <Descriptions.Item>
                    <img src={targetFacility?.tax_id_url} alt="tax_image" className="w-full" />
                  </Descriptions.Item>
                )}
              </Descriptions>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default FacilityDetails;

import axios from 'axios';

const createTicketsApi = async (url, values, token) => {
  // console.log(values)
  console.log(values);
  const { description, setter_id, status, problem, user_id } = values;
  const formData = new FormData();

  formData.append('description', description);
  formData.append('status', status);
  formData.append('problem', problem);
  formData.append('user_id', user_id);
  formData.append('setter_id', setter_id);

  try {
    // console.log(image[0])

    const createSerivceRes = await axios.post(url, formData, {
      headers: {
        Accept: 'application/json',

        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      }
    });
    // console.log(createSerivceRes);

    return createSerivceRes;
  } catch (error) {
    // console.log(error?.response);
    return error?.response;
  }
};

export default createTicketsApi;

import React, { Suspense, useContext, useEffect, useState } from 'react'
import AddsitterForm from './AddsitterForm'
import './AddSitter.scss'
import { Layout as AntdLayout, Menu, Dropdown, Button, Avatar, Modal } from 'antd';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Store } from 'react-notifications-component';
import UesrContext from '../../contexts/user-context/UserProvider';
import signoutApi from '../../apis/auth/signout';
import routerLinks from '../../components/app/routerLinks';


const AddSitter = () => {
  const { user, removeCurrentUser } = useContext(UesrContext);

  const { pathname } = useLocation();
  const { i18n } = useTranslation();
  // const { Header, Content, Footer, Sider } = AntdLayout;
  const { Header, Content, Sider } = AntdLayout;
  const [loadingSignout, setLoadingSignout] = useState(false);
  const history = useHistory();
  const handleSignout = async () => {
    try {
      setLoadingSignout(true);
      const res = await signoutApi(user?.token);
      if (res?.status === 200 && res?.data?.status === 1) {
        setLoadingSignout(false);
        removeCurrentUser();
        history.push(routerLinks.homePage);
        Store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم تسجيل الخروج بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        setLoadingSignout(false);
        Store.addNotification({
          title: 'حدث خطأ اثناء تسجيل الخروج',
          message: 'من فضلك حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      setLoadingSignout(false);
      Store.addNotification({
        title: 'حدث خطأ اثناء تسجيل الخروج',
        message: 'من فضلك حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });

    }
  };




  return (
    <div>

      <div className='container'>
     

        <AddsitterForm />
     
      </div>



    </div>
  )
}

export default AddSitter
import axios from 'axios';

const getAllTransactionsApi = async (token) => {
  try {
    const getParentsRes = await axios.post('transactions/index', null, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token
      }
    });
    // console.log(getParentsRes);

    return getParentsRes;
  } catch (error) {
    console.log(error);
  }
};

export default getAllTransactionsApi;




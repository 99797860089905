import axios from 'axios';

const createTicketsThreadsApi = async (url, values, token) => {
  // console.log(values)
  console.log(values);
  const { ticket_id, message } = values;
  const formData = new FormData();

  formData.append('ticket_id', ticket_id);
  formData.append('message', message);

  try {
    // console.log(image[0])

    const createSerivceRes = await axios.post(url, formData, {
      headers: {
        Accept: 'application/json',

        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      }
    });
    // console.log(createSerivceRes);

    return createSerivceRes;
  } catch (error) {
    // console.log(error?.response);
    return error?.response;
  }
};

export default createTicketsThreadsApi;

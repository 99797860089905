import axios from 'axios';

const DeleteTicketThreadApi = async (id, token) => {
  // console.log(token)
  try {
    const response = await axios.delete(`tickets/destroy_thread/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    //      console.log(response)

    if (response.status === 200 && response.data?.status_code === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
};

export default DeleteTicketThreadApi;

import axios from 'axios';

const deleteFacility = (id, token) => {
  try {
    const deletefacilityRes = axios.delete(`/facility/delete/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token
      }
    });
    console.log(deletefacilityRes);
    return deletefacilityRes;
  } catch (error) {
    return error?.response;
  }
};

export default deleteFacility;

import { Input, Form, Button } from 'antd';
import { useForm } from 'react-hook-form';
import { SendOutlined, EditOutlined } from '@ant-design/icons';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import { Store } from 'react-notifications-component';
import { useContext, useEffect } from 'react';
import UesrContext from '../../contexts/user-context/UserProvider';
import getAllSitters from '../../apis/sitters-api/getAllSitters';
import SittersContext from '../../contexts/sitters-context/SittersProvider';
import RoomContext from '../../contexts/room-context/roomsProvider';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import getRoom from '../../apis/rooms-api/getRoomApi';
import SelectSearchBox from '../../common/SelectSearchBox/SelectSearchBox';
import getSittersID from '../../apis/sitters-api/getSittersId';
import getFacilitiessID from '../../apis/facility_api/getFacilitiesID';
import createRoom from '../../apis/rooms-api/createRoomApi';

const UpdateRoom = () => {
  let schema = Yup.object().shape({
    name: Yup.string().required('من فضلك أدخل الأسم'),
    setter_id: Yup.string().required('من فضلك أختار أسم المربية'),
    facility_id: Yup.string().required('من فضلك أختار أسم المنشأه')
  });

  const { user } = useContext(UesrContext);
  const { loadingRooms, setLoadingRooms, targetRoom, setTargetRoom } = useContext(RoomContext);
  const [sittersOptions, setSittersOptions] = useState([]);
  const [facilitiesOptions, setFacilitiesOptions] = useState([]);

  const param = useParams();

  const defaultValues = {
    name: '',
    setter_id: '',
    facility_id: ''
  };

  const {
    control,
    setValue,
    handleSubmit,
    register,
    watch,
    unregister,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });
  let history = useHistory();

  const { form } = Form.useForm();
  const onSubmit = async (data) => {
    // console.log('object', data);
    try {
      setLoadingRooms(true);
      const updateRoomRes = await createRoom(`/rooms/update/${param?.id}`, data, user?.token);

      if (updateRoomRes?.status === 200) {
        setLoadingRooms(false);

        Store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم تعديل بيانات الغرفة',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
        history.push('/rooms');
      } else {
        setLoadingRooms(false);
        Store.addNotification({
          title: 'حدث خطأ ',
          message: updateRoomRes?.data?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      setLoadingRooms(false);
      Store.addNotification({
        title: 'حدث خطأ ',
        message: error?.response?.data?.errors || 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };

  useEffect(() => {
    const getTRoom = async () => {
      try {
        const targetRoomRes = await getRoom(user?.token, param?.id);

        if (targetRoomRes?.status === 200) {
          setTargetRoom(targetRoomRes?.data?.room_details);
          console.log(targetRoomRes?.data?.room_details);
        }
      } catch (error) {
        throw error;
      }
    };

    const getSitters = async () => {
      try {
        const getSittersRes = await getSittersID(user?.token);
        if (getSittersRes?.status === 200 && getSittersRes?.data) {
          setSittersOptions(getSittersRes?.data?.setters);
        }
      } catch (error) {}
    };

    const getFacilities = async () => {
      try {
        const getfacilitiesRes = await getFacilitiessID(user?.token);
        if (getfacilitiesRes?.status === 200 && getfacilitiesRes?.data) {
          setFacilitiesOptions(getfacilitiesRes?.data?.facilities);
        }
      } catch (error) {}
    };

    getTRoom();
    getSitters();
    getFacilities();
  }, []);

  useEffect(() => {
    if (targetRoom !== null) {
      setValue('name', targetRoom?.name);
      setValue('setter_id', String(targetRoom?.setter_id));
      setValue('facility_id', String(targetRoom?.facility?.id));
    }
  }, [targetRoom]);

  return (
    <>
      <Form
        className="add-Selles-form flex justify-center items-center"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}>
        <div className="form-body add_form_body">
          <div className="form-field-wrapper">
            <AntdTextField
              name="name"
              type="text"
              placeholder="الاسم..."
              label="أسم الغرفة : "
              errorMsg={errors?.name?.message}
              validateStatus={errors?.name ? 'error' : ''}
              prefix={<EditOutlined />}
              control={control}
            />
          </div>
          <div className=" form-field-wrapper ">
            <SelectSearchBox
              name="setter_id"
              errorMsg={errors?.setter_id?.message}
              validateStatus={errors?.setter_id ? 'error' : ''}
              defaultValue=""
              control={control}
              setValue={setValue}
              dataSearch={sittersOptions}
              placeholder="أختر المربية"
              value={watch}
              label=""
            />
          </div>

          <div className=" form-field-wrapper ">
            <SelectSearchBox
              name="facility_id"
              errorMsg={errors?.facility_id?.message}
              validateStatus={errors?.facility_id ? 'error' : ''}
              defaultValue=""
              control={control}
              setValue={setValue}
              dataSearch={facilitiesOptions}
              placeholder="أختر المنشأة"
              value={watch}
              label=""
            />
          </div>

          <Button
            className="submit-btn mx-auto mt-[30px]  !h-[45px] !w-[100px]   !flex justify-center items-center"
            htmlType="submit"
            type="primary"
            icon={<SendOutlined />}
            loading={loadingRooms}>
            تعديل
          </Button>
        </div>
      </Form>
    </>
  );
};

export default UpdateRoom;

import { Popconfirm, Tooltip, Button } from 'antd';
import { DeleteOutlined, EditOutlined, InfoOutlined } from '@ant-design/icons';
import deleteUser from '../../apis/users-api/deleteUser';
import { Store } from 'react-notifications-component';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import deleteFacility from '../../apis/facility_api/deleteFacility';
const FacilityColumns = (
  user,
  facilityFetchCount,
  setFacilityFetchCount,
  loadingFacility,
  setLoadingFacility,
  allFacility,
  setAllFacility,
  facilityDetails,
  setFacilityDetails,
  targetFacility,
  setTargetFacility
) => {


  let history = useHistory();

  const handleDeleteRequest = async (id) => {
    try {
      setLoadingFacility(true);
      const deleteRes = await deleteFacility(id, user?.token);
      if (deleteRes?.status === 200) {
        setFacilityFetchCount((prevState) => prevState + 1);
        setLoadingFacility(false);
        Store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم حذف المنشأة بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        setLoadingFacility(false);
        Store.addNotification({
          title: 'حدث خطأ ',
          message: deleteRes?.data?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
      console.log(deleteRes);
    } catch (error) {
      setLoadingFacility(false);
      Store.addNotification({
        title: 'حدث خطأ ',
        message: 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };

  return [
    {
      title: '#',
      dataIndex: 'id',
      width: 62,
      render: (_, record) => {
        return <div>{record.id}</div>;
      }
    },
    {
      title: 'أسم المنشأه',
      dataIndex: 'name',
      width: 120,
      render: (_, record) => {
        return <div>{record.name}</div>;
      }
    },
    {
      title: 'المساحة',
      dataIndex: 'space',
      width: 80,
      render: (_, record) => {
        return <div>{record.space}</div>;
      }
    },
    {
      title: ' عدد الغرف',
      dataIndex: 'num_of_rooms',
      width: 110,
      render: (_, record) => {
        return <div>{record.num_of_rooms}</div>;
      }
    },
    {
      title: 'تاريخ الانشاء',
      dataIndex: 'created_at',
      width: 110,
      render: (_, record) => {
        return <div>{record?.created_at.slice(0,10)}</div>;
      }
    },
    {
      title: 'حالة المنشأة',
      dataIndex: 'status',
      width: 110,
      render: (_, record) => {
        return <div>{record?.status}</div>;
      }
    },
    {
      title: 'المربية',
      dataIndex: 'setter_id',
      width: 110,
      render: (_, record) => {
        return <div>{record?.setter?.user?.name}</div>;
      }
    },
    {
      title: 'التفاصيل ',
      dataIndex: 'details',
      width: 30,
      render: (_, record) => {
        return (
          <Tooltip title="التفاصيل ">
            <Button
              className="edit-btn PaymentLog-btn"
              onClick={() => history.push(`/facilities/show/${record?.id}`)}
              size="large"
              type="dashed"
              shape="circle"
              icon={<InfoOutlined />}
            />
          </Tooltip>
        );
      }
    },
    {
      title: 'تعديل ',
      dataIndex: 'edit',
      width: 30,
      render: (_, record) => {
        return (
          <Tooltip title="تعديل المنشأه">
            <Button
              className="edit-btn"
              onClick={() => history.push(`/facilities/update/${record?.id}`)}
              size="large"
              type="dashed"
              shape="circle"
              icon={<EditOutlined />}
            />
          </Tooltip>
        );
      }
    },
    {
      title: 'حذف',
      dataIndex: 'delete',
      width: 30,
      render: (_, record) => {
        return (
          <Popconfirm
            title={
              <div style={{ maxWidth: '200px' }}>
                <p>هل انت متأكد من حذف المنشأة</p>
              </div>
            }
            okText="نعم"
            cancelText="لا"
             onConfirm={(id) => handleDeleteRequest(record.id)}
          >
            <Tooltip title="حذف المنشأة؟">
              <Button
                className="delete-btn"
                size="large"
                type="dashed"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        );
      }
    }
  ];
};

export default FacilityColumns;

import React, { useContext, useEffect, useState } from 'react';
import AreasContext from '../../contexts/area-context/AreaProvider';
import UesrContext from '../../contexts/user-context/UserProvider';
import { Table } from 'antd';
import './AreaPage.scss';
import getAllAreas from '../../apis/area-api/getAllArea';
import CitysContext from '../../contexts/City-context/CityProvider';
import AreasColumns from './areaColumns';
import CountarysContext from '../../contexts/countary-context/CountaryProvider';

const AreaTable = () => {
  const { user } = useContext(UesrContext);
  const {  selectedCountary ,setSelectedCountary } = useContext(CountarysContext);
  const { selectedCityId , setSelectedCityId } = useContext(CitysContext);
  

  const {
    fetchCount,
    setFetchCount,
    loadingAreas,
    setLoadingAreas,
    allAreas,
    setAllAreas,
    setAreaModalOpened,
    setSelectedAreaId
  } = useContext(AreasContext);

  const [AreaDataSource, setAreaDataSource] = useState([]);

  useEffect(() => {
   
    const getAreas = async () => {
      try {
        setLoadingAreas(true);
        
        const getAreasRes = await getAllAreas(selectedCityId, user?.token);
        
        if (getAreasRes.status === 200 && getAreasRes?.data?.data) {
          
          setAllAreas(getAreasRes.data.data);
          setAreaDataSource(getAreasRes.data.data)
          setLoadingAreas(false);
        }
      } catch (error) {
        setLoadingAreas(false);
        
      }
    };
    
    getAreas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCount, selectedCityId]);
  useEffect(() => {
    let isMounted = true;

    

    if (allAreas) {
      const mappedArea = allAreas.map((ques) => ({
        ...ques,
        key: ques.id
      }));

      if (isMounted) {
        setAreaDataSource(mappedArea);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [allAreas, allAreas?.length, fetchCount, selectedCityId]);
  
  return (
    <Table
      pagination={{ pageSize: 5 }}
      className="Orders-table"
      dataSource={AreaDataSource}
      columns={AreasColumns(
        user?.token,
        setFetchCount,
        setLoadingAreas,
        setAreaModalOpened,
        setSelectedAreaId,
        setSelectedCountary,
        setSelectedCityId
      )}
      loading={loadingAreas}
      scroll={{ x: 1200 }}
    />
  );
};

export default AreaTable;

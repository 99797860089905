import RatesTable from './RatesTable';
import { Select } from 'antd';
import { useEffect, useState, useContext } from 'react';
import UesrContext from '../../contexts/user-context/UserProvider';
import getAllSitters from '../../apis/sitters-api/getAllSitters';
import SelectSearchBox from '../../common/SelectSearchBox/SelectSearchBox';
import { Form } from 'antd';
import getSittersID from '../../apis/sitters-api/getSittersId';
import getSittersUserID from '../../apis/sitters-api/getSittersUserId';
import { useForm } from 'react-hook-form';
import { EditOutlined } from '@ant-design/icons';
const RatesPage = () => {
  const { user } = useContext(UesrContext);
  const [filterValue, setFilterValue] = useState('all');
  const [sittersOptions, setSittersOptions] = useState([]);

  useEffect(() => {
    const getSitters = async () => {
      try {
        const getSittersRes = await getSittersUserID(user?.token);
        if (getSittersRes?.status === 200 && getSittersRes?.data) {
          setSittersOptions(getSittersRes?.data?.setters);
          console.log(getSittersRes?.data?.setters);
        }
      } catch (error) {}
    };
    getSitters();
  }, []);
  const {
    control,
    // getValues,
    setValue,
    // setError,
    // clearErrors,
    handleSubmit,
    register,
    watch,
    unregister,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    //resolver: yupResolver(schema),
    mode: 'all'
  });
  const [form] = Form.useForm();
  return (
    <>
      {/* <div className="w-[200px] mb-[40px]">
        <Select defaultValue="all" onChange={onFilterChange} options={sittersOptions} />
      </div> */}
      <Form
        className="add-Selles-form flex justify-start items-center"
        form={form}
        layout="vertical"
        // onFinish={handleSubmit(onSubmit)}
      >
        <div className=" form-field-wrapper py-[10px] w-[230px]">
          <SelectSearchBox
            name="sitter"
            errorMsg={errors?.sitter?.message}
            validateStatus={errors?.sitter ? 'error' : ''}
            defaultValue=""
            control={control}
            setValue={setValue}
            dataSearch={[{ name: 'الكل', id: 'all' }, ...sittersOptions]}
            onSelect={(value) => setFilterValue(value)}
            placeholder="أختر المربية"
            value={watch}
            label=""
          />
        </div>
      </Form>

      <RatesTable filterValue={filterValue} />
    </>
  );
};

export default RatesPage;

import { Button } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import ChildrenContext from '../../contexts/children-context/ChildrenProvider';
import UesrContext from '../../contexts/user-context/UserProvider';
// import ParentsContext from '../../contexts/parents-context/ParentsProvider';
import { PlusOutlined } from '@ant-design/icons';
import getAllChildren from '../../apis/children-api/getAllChildren';
import ChildrenTable from './ChildrenTable';
import './chilrenPage.scss';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ChildrenPage = () => {
  const {
    ChildrenModalOpened,
    setChildrenModalOpened,
    setSelectedChildrenId,
    setSelectedChildren,
    selectedChildren,
    fetchCount,
    allChildren,
    setAllChildren,
    loadingChildren,
    setLoadingChildren,
    ViewChildrenModalOpened,
    setViewChildrenModalOpened
  } = useContext(ChildrenContext);
  const { user } = useContext(UesrContext);
  // const {
  //   ParentModalOpened,
  //   setParentModalOpened,
  //   setSelectedServId,
  //   setSelectedParent,
  //   selectedParent
  // } = useContext(ParentsContext);

  const [loadingParents, setLoadingParents] = useState();
  const [allParents, setAllParents] = useState();

  // useEffect(() => {
  //   const getChildrens = async () => {
  //     try {
  //       setLoadingChildren(true);
  //       const getChildrenRes = await getAllChildren(user?.token);
  //       console.log(getChildrenRes.data.childrens);

  //       if (getChildrenRes.status === 200 && getChildrenRes?.data?.childrens) {
  //         setAllChildren(getChildrenRes?.data?.childrens?.data);
  //         setLoadingChildren(false);
  //       }
  //     } catch (error) {
  //       setLoadingChildren(false);
  //     }
  //   };
  //   getChildrens();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [fetchCount]);
  let history = useHistory();
  return (
    <div>
      <Button
        onClick={() => {
          history.push("/Children/create")
        }}
        className="add-serv-btn"
        type="primary"
        icon={<PlusOutlined />}>
        <span className="btn-text">اضافة طفل</span>
      </Button>
      <ChildrenTable />
      {/* {ChildrenModalOpened && (
        <Modal
          className="add-City-modal"
          width="90%"
          style={{ maxWidth: '900px' }}
          title={selectedChildren ? 'تعديل  طفل' : 'اضافة الطفل '}
          open={ChildrenModalOpened}
          onOk={() => {
            setChildrenModalOpened(false);
            setSelectedChildrenId('');
            setSelectedChildren(null);
          }}
          onCancel={() => {
            setChildrenModalOpened(false);
            setSelectedChildrenId('');
            setSelectedChildren(null);
          }}
          footer={false}>
          <ChilrenModel allParents={allParents} />
        </Modal>
      )}

      {ViewChildrenModalOpened && (
        <Modal
          className="add-Order-modal"
          width="90%"
          style={{ maxWidth: '900px' }}
          title={'تفاصيل الطفل'}
          open={ViewChildrenModalOpened}
          onOk={() => {
            setViewChildrenModalOpened(false);
            setSelectedParent(null);
          }}
          onCancel={() => {
            setViewChildrenModalOpened(false);
            setSelectedParent(null);
          }}
          footer={false}>
          <ChildrenDetailsModel />
        </Modal>
      )}
      {ParentModalOpened && (
        <Modal
          className="add-Order-modal"
          width="90%"
          style={{ maxWidth: '900px' }}
          title={'تفاصيل الاب'}
          open={ParentModalOpened}
          onOk={() => {
            setParentModalOpened(false);
            setSelectedParent(null);
          }}
          onCancel={() => {
            setParentModalOpened(false);
            setSelectedParent(null);
          }}
          footer={false}>
          <ParentsModel />
        </Modal>
      )} */}
    </div>
  );
};

export default ChildrenPage;

import { Table } from 'antd';
import React, { useContext, useEffect } from 'react';
import getAllCertificatesApi from '../../apis/certificates-api/getAllCertificatesApi';
import CertificatesContext from '../../contexts/certificates-context/CertificatesProvider';
import UesrContext from '../../contexts/user-context/UserProvider';
import CertificatesColumns from './CertificatesColumns.js';
const CertificatesTable = () => {
  const { user } = useContext(UesrContext);
  const {
    fetchCount,
    setFetchCount,
    loadingCertificates,
    setLoadingCertificates,
    allCertificates, setAllCertificates,
    setParentModalOpened,
    setSelectedParent,

  } = useContext(CertificatesContext);

  useEffect(() => {
    const getCertificates = async () => {
      try {
        setLoadingCertificates(true);
        const getCertificatesRes = await getAllCertificatesApi(user?.token);
        if (getCertificatesRes.status === 200 && getCertificatesRes?.data?.certificates) {
          setAllCertificates(getCertificatesRes?.data?.certificates);
          console.log(getCertificatesRes?.data?.certificates)
          // console.table(getCertificatesRes?.data?.driver?.data)
          // console.log(getCertificatesRes?.data?.driver?.data)
          setLoadingCertificates(false);
        }
      } catch (error) {
        setLoadingCertificates(false);
      }
    };
    getCertificates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCount]);
  // console.log(allCertificates);
  return (
    <Table
      pagination={{ pageSize: 5 }}
      className="Orders-table"
      dataSource={allCertificates}
      columns={CertificatesColumns(
        user?.token,
        setFetchCount,
        setLoadingCertificates,
        setParentModalOpened,
        setSelectedParent
      )}
      loading={loadingCertificates}
      scroll={{ x: 1200 }}
    />
  );
};

export default CertificatesTable;

import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => { },
  loadingOrders: false,
  setLoadingOrders: (load) => { },
  allOrders: [],
  setAllOrders: (Orders) => { },
  OrdersModalOpened: false,
  setOrdersModalOpened: (v) => { },
  OrdersLocationModalOpened: false,
  setOrdersLocationModalOpened: (v) => { },
  selectedServId: '',
  setSelectedServId: (id) => { },
  selectedOrders: null,
  setSelectedOrders: (servKey) => { }
};

const OrdersContext = createContext(INITIAL_VALUES);

export const OrdersProvider = ({ children }) => {
  const [allOrders, setAllOrders] = useState(INITIAL_VALUES.allOrders);
  const [loadingOrders, setLoadingOrders] = useState(INITIAL_VALUES.loadingOrders);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [OrdersModalOpened, setOrdersModalOpened] = useState(INITIAL_VALUES.OrdersModalOpened);
  const [OrdersLocationModalOpened, setOrdersLocationModalOpened] = useState(INITIAL_VALUES.OrdersLocationModalOpened);
  const [selectedServId, setSelectedServId] = useState(INITIAL_VALUES.selectedServId);
  const [selectedOrders, setSelectedOrders] = useState(INITIAL_VALUES.selectedOrders);

  return (
    <OrdersContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        loadingOrders,
        setLoadingOrders,
        allOrders,
        setAllOrders,
        OrdersModalOpened,
        setOrdersModalOpened,
        selectedServId,
        setSelectedServId,
        selectedOrders,
        setSelectedOrders,
        setOrdersLocationModalOpened,
        OrdersLocationModalOpened
      }}>
      {children}
    </OrdersContext.Provider>
  );
};

export default OrdersContext;

import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { EditOutlined, SendOutlined } from '@ant-design/icons';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { Button, Form, Input, Select } from 'antd';
import { Store } from 'react-notifications-component';
import UesrContext from '../../contexts/user-context/UserProvider';
import CitysContext from '../../contexts/City-context/CityProvider';
import './CityPage.scss';
import createCity from '../../apis/city-api/createCity';
import CountarysContext from '../../contexts/countary-context/CountaryProvider';
import getAllCountarys from '../../apis/countary-api/getAllcountaries';
import axios from 'axios';

const defaultValues = {
  name_ar: '',
  name_en: ''
};

const CitysModel = ({ allCountries }) => {
  const { user } = useContext(UesrContext);
  const { selectedServId, selectedCountary ,allCountarys} = useContext(CountarysContext);

  
  
  
  

  const {
    setFetchCount,
    setCityModalOpened,
    allCitys,
    setSelectedCityId,
    selectedCityId,
    selectedCity,
    setSelectedCity
  } = useContext(CitysContext);



  let schema;
  if (selectedCity && selectedCityId) {
    schema = Yup.object().shape({
      name_ar: Yup.string().required('من فضلك ادخل مدينه باللغة العربية'),
      name_en: Yup.string().required('من فضلك ادخل مدينه باللغة الانجليزية'),
    });

  } else {
    schema = Yup.object().shape({
      name_ar: Yup.string().required('من فضلك ادخل مدينه باللغة العربية'),
      name_en: Yup.string().required('من فضلك ادخل مدينه باللغة الانجليزية'),
    });
  }

  useEffect(() => {
    if (selectedCityId) {
      const foundCity = allCitys.find((City) => City.id === selectedCityId);
      if (foundCity) setSelectedCity(foundCity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCityId]);

  const {
    control,

    setValue,

    handleSubmit,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });


  // 
  // 



  const onSubmit = async (data) => {
    try {
      let res;
      if (selectedCity) {
        res = await createCity(
          `/City/update/${selectedCity.id}`,
          data,
          user?.token
        );
      } else {
        
        res = await createCity('/City/create', data, user?.token);
      }
      // 
      if (res?.status === 200 && res?.data?.status === 0) {
        Store.addNotification({
          title: 'حدث خطأ اثناء انشاء مدينه',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (res?.status === 200 && res?.data?.status === 1) {
        reset({ ...defaultValues });

        setCityModalOpened(false);
        setSelectedCityId('');
        setSelectedCity(null);
        setFetchCount((prevState) => prevState + 1);
        Store.addNotification({
          title: 'تمت العملية بنجاح',
          message: 'تمت العملية بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {

    }
  };

  const [form] = Form.useForm();

  // handle initial values
  useEffect(() => {


    if (selectedCity && selectedCityId) {

      const { name_ar, name_en } = selectedCity;
      form.setFieldsValue({
        'name_ar': name_ar,
        'name_en': name_en,
        'country_id': selectedCountary,
      })
      setValue('name_ar', name_ar);
      setValue('name_en', name_en);

      // convertPathToFile(image).then((file) => {
      //   
      //   setValue('City_imgs', [file]);
      // });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCity, selectedCityId]);
  const onCountryChange = (value) => {


  };
  const onSearch = (value) => {

  };
  return (
    <Form className="add-City-form" form={form} layout="vertical" onFinish={onSubmit}>
      <div className="form-body">
        {/* <AntdTextField
          name="name_ar"
          type="text"
          label="اســم مدينه باللغة العربية"
          placeholder="اســم مدينه باللغة العربية..."
          errorMsg={errors?.name_ar?.message}
          validateStatus={errors?.name_ar ? 'error' : ''}
          prefix={<EditOutlined />}
          control={control}
          formClassName="add-City-form"
        />
        <AntdTextField
          name="name_en"
          type="text"
          label="اســم مدينه باللغة الانجليزية"
          placeholder="اســم مدينه باللغة الانجليزية..."
          errorMsg={errors?.name_en?.message}
          validateStatus={errors?.name_en ? 'error' : ''}
          prefix={<EditOutlined />}
          control={control}
        /> */}
        <Form.Item
          name="name_ar"

          control={control}
          rules={[{ required: true, message: 'من فضلك ادخل  اسم الدينة باللغة العربية' }]}>
          <Input prefix={<EditOutlined />} placeholder={'  اســم مدينه باللغة العربية...'} />
        </Form.Item>
        <Form.Item
          name="name_en"
          rules={[{ required: true, message: 'من فضلك ادخل  اسم الدينة باللغة الانجليزية' }]}>
          <Input prefix={<EditOutlined />} value="sd" placeholder={'  اســم مدينه باللغة الانجليزية...'} />
        </Form.Item>

        <Form.Item name="country_id" rules={[{ required: true, message: 'من فضلك ادخل  الدولة ' }]}>
          <Select
            showSearch
            placeholder="اختر الدولة..."
            optionFilterProp="children"
            onChange={onCountryChange}
            onSearch={onSearch}


          >
            {allCountries && allCountries.map(item => (
              <Select.Option value={item.id} key={item.id}>{item.name_ar}</Select.Option>
            ))}

          </Select>
        </Form.Item>

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          icon={<SendOutlined />}
          loading={isSubmitting}>
            {selectedCity?"تعديل المدينة":"اضافة مدينه"}
          
        </Button>
      </div>

      {/* <div dir="ltr">
          <h1>Values</h1>
          <pre>{JSON.stringify(getValues(), null, 2)}</pre>
        </div> */}
    </Form>
  );
};

export default CitysModel;

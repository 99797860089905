import axios from 'axios';

const getAllFacilities = async (token) => {
  try {
    const getFacilitiesRes = await axios.post(
      '/facility/index',
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'applications/json',
          Authorization: 'Bearer ' + token
        }
      }
    );

    console.log(getFacilitiesRes);
    return getFacilitiesRes;
  } catch (error) {
    return error?.response;
  }
};

export default getAllFacilities;

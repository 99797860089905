import axios from 'axios';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export const signinApi = async (values, langIsoCode) => {
  const { name, password } = values;
  
  try {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('password', password);
    const signinRes = await axios.post('/Auth_general/loginEmployee', formData, {
      headers: {
  
        lang: langIsoCode,
      }
    });
    
    return signinRes;
  } catch (error) {
    
  }
};

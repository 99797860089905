import { Modal } from 'antd';
import React, { useContext } from 'react';
import SupportsContext from '../../contexts/supports-context/SupportsProvider';
import SupportsModel from './SupportsModel';
import SupportsTable from './SupportsTable';
import './SupportsPage.scss';
const SupportsPage = () => {
  const { SupportModalOpened, setSupportModalOpened, setSelectedServId, setSelectedSupport } =
    useContext(SupportsContext);
  return (
    <div>
      <SupportsTable />
      {SupportModalOpened && (
        <Modal
          className="add-Support-modal"
          width="90%"
          style={{ maxWidth: '900px' }}
          title={setSelectedServId ? 'تفاصيل المربيه' : 'اضـافة خدمة جديدة'}
          open={SupportModalOpened}
          onOk={() => {
            setSupportModalOpened(false);
            setSelectedServId('');
            setSelectedSupport(null);
          }}
          onCancel={() => {
            setSupportModalOpened(false);
            setSelectedServId('');
            setSelectedSupport(null);
          }}
          footer={false}>
          <SupportsModel />
        </Modal>
      )}
    </div>
  );
};

export default SupportsPage;
